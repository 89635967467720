import { NULL_OBJECT_ID } from '../utils/constants';

import { NullObject } from './null-object';
import { PropertyCodeCategory } from './property-code-category';
import { Rule } from './rule';

/** Naupa 2 code edit. */
export type Naupa2CodeEdit = Pick<Naupa2Code, 'categoryId' | 'id' | 'code'>;

/** Naupa 2 code (code of national standard for reporting unclaimed property). */
export class Naupa2Code {
	/** Code id. */
	public readonly id: number;

	/** Code value. */
	public readonly code: string;

	/** Rules' list for this naupa code with property types. */
	public readonly rules: Rule[];

	/** Category id. */
	public readonly categoryId: number;

	/** Category. */
	public readonly category?: PropertyCodeCategory;

	/** Is restricted. */
	public readonly isRestricted: boolean;

	public constructor(data: Naupa2CodeConstructorData) {
		this.id = data.id;
		this.code = data.code;
		this.rules = data.rules;
		this.categoryId = data.categoryId;
		this.category = data.category;
		this.isRestricted = data.isRestricted;
	}
}

/** Nullable NAUPA 2 code. */
export class NullNaupa2Code extends Naupa2Code implements NullObject {

	/** @inheritdoc */
	public readonly isNull = true;

	public constructor(data?: Partial<Naupa2CodeConstructorData>) {
		super({
			categoryId: data?.categoryId ?? NULL_OBJECT_ID,
			code: data?.code ?? '',
			id: data?.id ?? NULL_OBJECT_ID,
			isRestricted: data?.isRestricted ?? false,
			rules: data?.rules ?? [],
			category: data?.category,
		});
	}
}

/** Constructor data. */
export type Naupa2CodeConstructorData = Naupa2Code;
