import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { Naupa2Code } from '../models/naupa-2-code';
import { NaupaCodeWithDescription } from '../models/naupa-code-with-description';
import { Naupa2CodesFilters, Naupa2CodesWithDescriptionFilters } from '../models/naupa2-code-filters';
import { PagedList } from '../models/paged-list';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { Naupa2CodeDto, Naupa2CodeEditDto } from './mappers/dto/naupa-2-code-dto';
import { NaupaCodeWithDescriptionDto } from './mappers/dto/naupa-code-with-description-dto';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { Naupa2CodeMapper } from './mappers/naupa-2-code.mapper';
import { NaupaCodeWithDescriptionMapper } from './mappers/naupa-code-with-description.mapper';
import { Naupa2CodesFiltersMapper } from './mappers/naupa2-codes-filters.mapper';
import { Naupa2CodesWithDescriptionFiltersMapper } from './mappers/naupa2-codes-with-description-filters.mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';

/** Service for PropertyCode API access. */
@Injectable({ providedIn: 'root' })
export class PropertyCodeService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly listMapper = inject(PagedListMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly naupa2CodeMapper = inject(Naupa2CodeMapper);

	private readonly naupaCodeWithDescriptionMapper = inject(NaupaCodeWithDescriptionMapper);

	private readonly naupa2CodeFilterMapper = inject(Naupa2CodesFiltersMapper);

	private readonly naupa2CodeWithDescFilterMapper = inject(Naupa2CodesWithDescriptionFiltersMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly naupaCodesUrl = new URL('property-codes/naupa2-codes/', this.config.apiUrl).toString();

	private readonly naupaCodesWithDescriptionUrl = new URL('property-codes/naupa2-codes-to-types-rules/', this.config.apiUrl).toString();

	/**
	 * Get Naupa2Codes paged list.
	 *
	 * @param options Paged and sort options.
	 */
	public getNaupa2CodesPagedList(options: FetchListOptions<Naupa2CodesFilters>): Observable<PagedList<Naupa2Code>> {
		const params = this.paramsMapper.toDto(options, this.naupa2CodeFilterMapper);

		return this.http.get<PagedListDto<Naupa2CodeDto>>(this.naupaCodesUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(response, this.naupa2CodeMapper, options.pagination)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.naupa2CodeMapper),
		);
	}

	/**
	 * Get Naupa2Codes with description.
	 * @param options Paged and sort options.
	 */
	public getNaupaCodesWithDescription(
		options: FetchListOptions<Naupa2CodesWithDescriptionFilters>,
	): Observable<PagedList<NaupaCodeWithDescription>> {
		const params = this.paramsMapper.toDto(options, this.naupa2CodeWithDescFilterMapper);

		return this.http.get<PagedListDto<NaupaCodeWithDescriptionDto>>(
			this.naupaCodesWithDescriptionUrl, { params },
		).pipe(
			map(response => this.listMapper.fromDto(
				response,
				this.naupaCodeWithDescriptionMapper,
				options.pagination,
			)),
		);
	}

	/**
	 * Delete property code.
	 * @param id Property code id.
	 */
	public deleteNaupa2Code(id: number): Observable<void> {
		const url = new URL(`${id}/`, this.naupaCodesUrl).toString();
		return this.http.delete(url).pipe(
			map(() => undefined),
		);
	}

	/** Get export table link. */
	public getExportTableLink(): Observable<string> {
		const url = new URL('export-table/', this.naupaCodesWithDescriptionUrl).toString();

		return this.http.get<{url: string;}>(url).pipe(
			map(dto => dto.url),
		);
	}

	/**
	 * Save Naupa2Code.
	 * @param value Naupa2Code to save.
	 */
	public saveNaupa2Code(value: Naupa2Code): Observable<Naupa2Code> {
		const editDto = this.naupa2CodeMapper.toDto(value);

		const request$ = editDto.id ?
			this.updateNaupa2Code(editDto) :
			this.createNaupa2Code(editDto);

		return request$.pipe(
			map(dto => this.naupa2CodeMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.naupa2CodeMapper),
		);
	}

	private createNaupa2Code(dto: Naupa2CodeEditDto): Observable<Naupa2CodeDto> {
		return this.http.post<Naupa2CodeDto>(this.naupaCodesUrl, dto);
	}

	private updateNaupa2Code(dto: Naupa2CodeEditDto): Observable<Naupa2CodeDto> {
		const url = new URL(`${dto.id}/`, this.naupaCodesUrl).toString();
		return this.http.put<Naupa2CodeDto>(url, dto);
	}
}
