<mat-divider />

@let matrix = matrix$ | async;
@let isAdmin = isAdmin$ | async;

<header class="header">
	<div class="title">
		<h1>Due Diligence</h1>
	</div>
	@if (isAdmin) {
		<div class="buttons">
			<button
				mat-flat-button
				class="custom-icon-button"
				type="button"
				title="Add Law"
				(click)="openLawDialog(matrix)"
			>
				<span class="button-content">
					<mat-icon svgIcon="add" />
					<span>Add Law</span>
				</span>
			</button>
			<scriptaw-export-menu-buttons (selectExportFileFormat)="exportResults($event)" />
		</div>
	}
</header>
<mat-divider />

<form
	class="filters-form"
	[formGroup]="filtersForm"
>
	<scriptac-label
		labelText="Jurisdiction"
		class="filter"
	>
		<scriptaw-states-autocomplete
			formControlName="states"
			[needAllOption]="true"
		/>
	</scriptac-label>
	<scriptac-label
		labelText="Holder Type"
		class="filter"
	>
		<scriptaw-holder-type-autocomplete formControlName="holderTypes" />
	</scriptac-label>
	@if ((showExportButton$ | async) && !isAdmin) {
		<scriptaw-export-menu-buttons
			class="export-button"
			(selectExportFileFormat)="exportResults($event)"
		/>
	}
</form>
<div class="page-content">
	<scriptaw-horizontal-matrix
		[matrixData]="matrixLaws$ | async"
		[matrixId]="matrix?.id || null"
		[showStateColumn]="true"
		[showFixedColumn]="true"
		[dateFormat]="dateFormat"
		[getElementValuesList]="getValuesListFromLaw"
		[columns]="columns$ | async"
		[loading]="tableManager.listLoading$ | async"
		(columnsUpdate)="saveUpdatedColumns($event)"
	>
		<ng-template
			#stateCellTemplate
			let-element
		>
			{{ element.state.name }}
		</ng-template>

		<ng-template
			#fixedCellTemplate
			let-element
		>
			@if (isAdmin) {
				<div class="admin-buttons">
					<button
						mat-flat-button
						class="custom-icon-button"
						type="button"
						title="Add Revision"
						[disabled]="tableManager.listLoading$ | async"
						(click)="openRevisionDialog(element, matrix)"
					>
						<span class="button-content">
							<mat-icon svgIcon="add" />
							<span>Add Revision</span>
						</span>
					</button>
					<a
						mat-flat-button
						title="View History"
						class="custom-icon-button"
						[routerLink]="routePaths.matrixRevision(element.matrixId, element.id)"
					>
						<span class="button-content">
							<mat-icon svgIcon="history" />
							<span>View History</span>
						</span>
					</a>
					<button
						mat-flat-button
						title="Delete Law"
						type="button"
						class="custom-icon-button"
						(click)="deleteLaw(element)"
					>
						<span class="button-content delete">
							<mat-icon svgIcon="trash" />
							<span>Delete Law</span>
						</span>
					</button>
				</div>
			} @else {
				@if (shouldDisplayViewHistory(element)) {
					<a
						mat-flat-button
						title="View History"
						class="custom-icon-button"
						[routerLink]="routePaths.resultDetails(element.id)"
					>
						<span class="button-content">
							<mat-icon svgIcon="history" />
							<span>View History</span>
						</span>
					</a>
				}
			}
		</ng-template>
	</scriptaw-horizontal-matrix>

	@if (tableManager.pagePagination$ | async; as pagination) {
		<mat-paginator
			color="primary"
			[pageSize]="pagination.pageSize"
			[pageIndex]="pagination.page"
			[length]="pagination.totalCount"
			[hidePageSize]="true"
			(page)="paginationChanged($event)"
		/>
	}
</div>

@if ((matrixLaws$ | async) === null) {
	<scriptac-page-loader />
}
