/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { S3FileUploadConfigType } from '../enums/s3-file-upload-config-type';

import { S3DirectUploadInfoDto } from './mappers/dto/s3-direct-upload-info-dto';
import { AppConfigService } from './app-config.service';
import { S3DirectBodyDto } from './mappers/dto/s3-direct-body-dto';

type UploadFileResult = {

	/** URL of the uploaded file. */
	readonly url: string;

	/**
	 * File key.
	 * @example
	 * ```text
	 * destination_folder/9a3e9db1-4e57-4722-93fb-47b701ddcaea/image_name.png
	 * ```
	 */
	readonly key: string;
};

/**
 * File storage service for working with s3.
 */
@Injectable({
	providedIn: 'root',
})
export class FileStorageService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly s3directUrl = new URL('s3/get-params/', this.config.apiUrl).toString();

	/**
	 * Upload file to s3.
	 * @param configType Type of configuration from which the file will be uploaded..
	 * @param file File to upload.
	 * @param filename File name.
	 */
	public uploadFile(configType: S3FileUploadConfigType, file: Blob, filename: string): Observable<UploadFileResult> {
		const body: S3DirectBodyDto = {
			config: configType,
			content_length: file.size,
			filename,
			content_type: file.type,
		};

		const formData = new FormData();

		return this.http.post<S3DirectUploadInfoDto>(this.s3directUrl, body).pipe(
			switchMap(redirectInfo => {
				// Set all the configurations from redirectInfo to formData object except form_action (it is url).
				const s3body = Object.entries(redirectInfo.params).reduce((acc: FormData, [key, value]) => {
					acc.append(key, value);
					return acc;
				}, formData);

				// !!! Important to add a file as the last field of form data. https://stackoverflow.com/a/15235866
				formData.append('file', file);

				// Set response type 'text' because s3 returns XML.
				// Using Object type to avoid errors connected with responseType
				const options: Object = { observe: 'body', responseType: 'text' };

				return this.http.post<string>(redirectInfo.url, s3body, options);
			}),
			map(resultXml => {
				const parser = new DOMParser();
				const xmlDocument = parser.parseFromString(resultXml, 'application/xml').documentElement;
				const key = xmlDocument.querySelector('Key')?.textContent ?? '';

				const url = URL.createObjectURL(file);

				return { key, url };
			}),
		);
	}

}
