import { Injectable } from '@angular/core';

import { PriorityRule, PriorityRuleEdit } from '../../models/priority-rule';

import { IMapperFromDto, IMapperToDto } from './mappers';
import { PriorityRuleDto, PriorityRuleEditDto } from './dto/priority-rule-dto';

/** Priority Rule Mapper. */
@Injectable({ providedIn: 'root' })
export class PriorityRuleMapper implements IMapperFromDto<PriorityRuleDto, PriorityRule>,
  IMapperToDto<PriorityRuleEditDto, PriorityRuleEdit> {

	/** @inheritdoc */
	public fromDto(dto: PriorityRuleDto): PriorityRule {
		return new PriorityRule({
			id: dto.id,
			rule: dto.rule,
			firstPriority: dto.first_priority,
			secondPriority: dto.second_priority,
			source: dto.source,
			applicability: dto.applicability,
		});
	}

	/** @inheritdoc */
	public toDto(data: PriorityRuleEdit): PriorityRuleEditDto {
		return {
			rule: data.rule,
			first_priority: data.firstPriority,
			second_priority: data.secondPriority,
			source: data.source,
			applicability: data.applicability,
		};
	}
}
