@if (exportJob$ | async; as job) {
	@switch (job.status) {
		@case (exportJobStatus.Pending) {
			<p>Your file is preparing. Progress:</p>
			<span>{{ job.progress.current }} / {{ job.progress.total }}</span>
		}
		@case (exportJobStatus.Error) {
			<p>Export error! Please, close this window and try again later.</p>
			<button
				mat-flat-button
				type="button"
				(click)="onClose()"
			>
				Close
			</button>
		}
		@case (exportJobStatus.Finished) {
			<p>Your file is ready. Click button below to download:</p>
			<a
				mat-flat-button
				color="accent"
				download="Export"
				[href]="job.fileLink"
			>
				Download
			</a>
			<button
				mat-flat-button
				type="button"
				(click)="onClose()"
			>
				Close
			</button>
		}
	}
} @else {
	<mat-spinner
		color="accent"
		class="loader"
		[diameter]="70"
	/>
}
