import { AccessTierLevel } from '../enums/access-tier-level';
import { MatrixFieldType } from '../enums/matrix-field-type';
import { NULL_OBJECT_ID } from '../utils/constants';

import { MatrixFieldChoice, MatrixFieldChoiceEdit } from './matrix-field-choice';
import { NullObject } from './null-object';

/** Matrix field dto for post/put request. */
export type MatrixFieldEdit = {

	/** Field id. */
	readonly id?: number;

	/** Field name. */
	readonly name: string;

	/** Field type. */
	readonly fieldType: MatrixFieldType;

	/** Access tier to field. */
	readonly tier: AccessTierLevel;

	/** List of choices. */
	readonly choices: MatrixFieldChoiceEdit[];

	/** Is field should be removed. */
	readonly toDelete: boolean;
};

/** Matrix field. */
export class MatrixField {
	/** Field ID. */
	public readonly id: number;

	/** Field name. */
	public readonly name: string;

	/** Type of field. */
	public readonly fieldType: MatrixFieldType;

	/** Access tier level. */
	public readonly tier: AccessTierLevel;

	/** List of choices. */
	public readonly choices: MatrixFieldChoice[];

	public constructor(data: MatrixFieldConstructorData) {
		this.id = data.id;
		this.name = data.name;
		this.fieldType = data.fieldType;
		this.tier = data.tier;
		this.choices = data.choices;
	}
}

/** Nullable matrix field data. */
export class NullMatrixField extends MatrixField implements NullObject {
	/** Is null check. */
	public readonly isNull: boolean = true;

	public constructor(
		data: MatrixFieldConstructorData = {
			id: NULL_OBJECT_ID,
			name: '',
			fieldType: MatrixFieldType.Text,
			tier: AccessTierLevel.Tier1,
			choices: [],
		},
	) {
		super(data);
	}
}

/** Constructor data.  */
export type MatrixFieldConstructorData = MatrixField;
