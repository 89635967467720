import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { ExportFileFormat } from '@scriptac/common/core/enums/export-file-format';

/** Export menu buttons. */
@Component({
	selector: 'scriptaw-export-menu-buttons',
	templateUrl: './export-menu-buttons.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMenuButtonsComponent {

	/** Export file format. */
	public readonly exportFileFormat = ExportFileFormat;

	/** Emits when file export format is selected. */
	@Output()
	public readonly selectExportFileFormat = new EventEmitter<ExportFileFormat>();

}
