<form [formGroup]="filtersForm">
	<scriptac-label
		class="full-width"
		labelText="Search"
	>
		<input
			formControlName="search"
			placeholder="Search"
		/>
	</scriptac-label>
</form>

<div class="user-list">
	@if (listManager.listLoading$ | async) {
		<mat-progress-bar mode="indeterminate" />
	}

	<mat-selection-list
		(scroll)="onScroll($event)"
		[formControl]="listControl"
		[multiple]="false"
	>
		@if (companies$ | async; as companies) {
			@if (companies.length) {
				<mat-list-option [value]="null">-- No company --</mat-list-option>
				@for (company of companies; track company.id) {
					<mat-list-option
						role="listitem"
						[value]="company"
					>
						{{ company.name }}
					</mat-list-option>
				}
			} @else {
				<mat-list-item>No companies found</mat-list-item>
			}
		} @else {
			<mat-list-item>Loading...</mat-list-item>
		}
	</mat-selection-list>
</div>

<p>Option selected: {{ listControl.value[0]?.name }}</p>

<div class="controls full-width">
	<button
		(click)="onClose()"
		type="button"
		mat-flat-button
		color="warn"
	>
		Cancel
	</button>
	<button
		(click)="onConfirm()"
		type="button"
		mat-flat-button
		color="accent"
	>
		Confirm
	</button>
</div>
