import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../../models/fetch-list-options';
import { Law, LawEdit } from '../../models/law';
import { PagedList } from '../../models/paged-list';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { LawDto } from '../mappers/dto/law-dto';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { LawMapper } from '../mappers/law.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { LawsListFiltersMapper } from '../mappers/laws-list-filters.mapper';
import { LawsListFilters } from '../../models/laws-list-filters';

import { PaginationApiService } from './pagination-api.service';

/** Service for access to law API. */
@Injectable({ providedIn: 'root' })
export class LawApiService {
	private readonly lawMapper = inject(LawMapper);

	private readonly lawsFiltersMapper = inject(LawsListFiltersMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly listMapper = inject(PagedListMapper);

	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly paginationApiService = inject(PaginationApiService);

	private readonly lawsUrl = new URL('laws/laws/', this.config.apiUrl).toString();

	/**
	 * Get laws paged list.
	 * @param options Pagination, sorting and filtering options.
	 */
	public getLawsPagedList(options: FetchListOptions<LawsListFilters>): Observable<PagedList<Law>> {
		const params = this.paramsMapper.toDto(options, this.lawsFiltersMapper);

		return this.http
			.get<PagedListDto<LawDto>>(this.lawsUrl, { params })
			.pipe(
				map(response => this.listMapper.fromDto(response, this.lawMapper, options.pagination)),
			);
	}

	/**
	 * Get all laws list.
	 * @param options Filter options.
	 */
	public getAllLaws(options: LawsListFilters): Observable<Law[]> {
		const params = new HttpParams({
			fromObject: { ...this.lawsFiltersMapper.toDto(options) },
		});

		return this.paginationApiService.getAllItemsFromPagedList(
			this.http.get<PagedListDto<LawDto>>(this.lawsUrl, { params }),
			true,
		).pipe(
			map(laws => laws.map(law => this.lawMapper.fromDto(law))),
		);
	}

	/**
	 * Get law by id.
	 * @param id Law id.
	 */
	public getLawById(id: number): Observable<Law> {
		const url = new URL(`${String(id)}/`, this.lawsUrl).toString();

		return this.http.get<LawDto>(url).pipe(
			map(law => this.lawMapper.fromDto(law)),
		);
	}

	/**
	 * Update law by id.
	 *
	 * @param id Law id.
	 * @param law Law data.
	 */
	public updateLaw(id: number, law: LawEdit): Observable<Law> {
		const url = new URL(`${id}/`, this.lawsUrl).toString();
		const lawDto = this.lawMapper.toDto(law);

		return this.http.put<LawDto>(url, lawDto).pipe(
			map(dto => this.lawMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lawMapper),
		);
	}

	/**
	 * Create new law.
	 *
	 * @param law Law data.
	 */
	public createLaw(law: LawEdit): Observable<Law> {
		const lawDto = this.lawMapper.toDto(law);

		return this.http.post<LawDto>(this.lawsUrl, lawDto).pipe(
			map(dto => this.lawMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lawMapper),
		);
	}

	/**
	 * Delete law.
	 *
	 * @param lawId Law id.
	 */
	public deleteLaw(lawId: number): Observable<void> {
		return this.http.delete<number>(`${this.lawsUrl}${lawId}/`).pipe(
			map(() => undefined),
		);
	}
}
