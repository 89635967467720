import { Industry } from './industry';
import { ShortMatrix } from './matrix';
import { Naupa2Code } from './naupa-2-code';
import { PropertyType } from './property-type';
import { State } from './state';

/**
 * Naupa code with description.
 */
export class NaupaCodeWithDescription {
	/** Id. */
	public readonly id: number;

	/** Naupa 2 code id. */
	public readonly naupaCodeId: number;

	/** Naupa 2 code data. */
	public readonly naupaCode: Naupa2Code;

	/** Property type id. */
	public readonly propertyTypeId: number;

	/** Property type id. */
	public readonly propertyType: PropertyType;

	/** Industries ids. */
	public readonly industryIds: number[];

	/** Industries data. */
	public readonly industries: Industry[];

	/** Jurisdiction ids. */
	public readonly jurisdictionIds: number[];

	/** Jurisdiction ids. */
	public readonly jurisdictions: State[];

	/** Matrices ids. */
	public readonly matrixIds: number[];

	/** Matrices data. */
	public readonly matrices: ShortMatrix[];

	public constructor(data: NaupaCodeWithDescriptionInitArgs) {
		this.id = data.id;
		this.naupaCodeId = data.naupaCodeId;
		this.naupaCode = data.naupaCode;
		this.propertyTypeId = data.propertyTypeId;
		this.propertyType = data.propertyType;
		this.industryIds = data.industryIds;
		this.industries = data.industries;
		this.jurisdictionIds = data.jurisdictionIds;
		this.jurisdictions = data.jurisdictions;
		this.matrixIds = data.matrixIds;
		this.matrices = data.matrices;
	}
}

type NaupaCodeWithDescriptionInitArgs = NaupaCodeWithDescription;
