<nav>
	<a [routerLink]="routePaths.welcome">
		<img
			src="assets/scripta-light.svg"
			alt="logo"
		/>
	</a>
	<div class="links-row">
		@for (link of navLinks; track $index) {
			<a
				class="nav-link"
				routerLinkActive="active"
				[title]="link.title"
				[routerLink]="link.route"
			>
				<mat-icon [svgIcon]="link.icon" />
				<span class="nav-title">{{ link.title }}</span>
			</a>
		}
	</div>
</nav>
<ng-content />
<img
	class="background"
	[src]="backgroundImg"
	alt="Page Background"
/>
