import { Injectable } from '@angular/core';

import { Region } from '../../models/region';

import { RegionDto } from './dto/region-dto';
import { IMapperFromDto } from './mappers';

/** Mapper for regions. */
@Injectable({ providedIn: 'root' })
export class RegionMapper implements IMapperFromDto<RegionDto, Region> {
	/** @inheritdoc */
	public fromDto(dto: RegionDto): Region {
		return new Region({
			id: dto.id,
			name: dto.name,
			geonameCode: dto.geoname_code,
		});
	}
}
