import { Injectable } from '@angular/core';

import { UsersFilters } from '../api/app-users-api.service';

import { UsersFiltersDto } from './dto/user-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for user filters. */
@Injectable({ providedIn: 'root' })
export class UsersFiltersMapper implements IMapperToDto<UsersFiltersDto, UsersFilters> {
	/** @inheritdoc */
	public toDto(data: UsersFilters): UsersFiltersDto {
		const params: UsersFiltersDto = {};

		if (data.search) {
			params.search = data.search;
		}

		if (data.companyIdList?.length) {
			params.company__in = data.companyIdList;
		}

		if (data.hasCompany != null) {
			params.without_company = data.hasCompany;
		}

		if (data.isCompanyOwner != null) {
			params.without_company = data.isCompanyOwner;
		}

		if (data.inCompanyOrWithout != null) {
			params.in_company_or_without = data.inCompanyOrWithout;
		}

		return params;
	}
}
