/** App users tier statistic. */
export class TierStats {
	/** Total number of users. */
	public readonly totalCount: number;

	/** Users without tier. */
	public readonly noTier: number;

	/** Users with tier_1. */
	public readonly tier1: number;

	/** Users with tier_2. */
	public readonly tier2: number;

	/** Users with tier_3. */
	public readonly tier3: number;

	public constructor(data: TierStatsConstructorData) {
		this.totalCount = data.totalCount;
		this.noTier = data.noTier;
		this.tier1 = data.tier1;
		this.tier2 = data.tier2;
		this.tier3 = data.tier3;
	}
}

type TierStatsConstructorData = TierStats;
