import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
	ConfirmDialogComponent,
	ConfirmDialogData,
} from 'projects/web/src/app/modules/shared/components/confirm-dialog/confirm-dialog.component';

/** Dialog utils service. */
@Injectable({
	providedIn: 'root',
})
export class DialogUtilsService {
	private readonly dialog = inject(MatDialog);

	/**
	 * Open confirmation modal.
	 * @param message Message.
	 */
	public openConfirmationModal(message: string): Observable<boolean | undefined> {
		return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(
			ConfirmDialogComponent,
			{
				width: '400px',
				data: { message },
			},
		).afterClosed();
	}
}
