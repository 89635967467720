import { Injectable } from '@angular/core';

import { NullNaupa2Code } from '../../models/naupa-2-code';
import { State } from '../../models/state';
import { KeywordFilter, MatrixSearchFilters, PropCodeFilter, TopicFilter } from '../../models/matrix-search-filter';
import { MatrixSearchName } from '../../models/matrix-search-name';
import { MatrixSearchType } from '../../enums/matrix-search-type';
import { stringifyListByProp } from '../../utils/stringifyListByProp';
import { ALL_OPTION_ID } from '../../utils/constants';

import { IMapperToDto } from './mappers';
import { MatrixSearchFilterDto } from './dto/matrix-search-filter-dto';

/** Search filters query params. */
export type SearchQueryParams = {

	/** Whether include pending revision. */
	readonly includePending?: string;

	/** States list. */
	readonly states?: string;

	/** Topics list. */
	readonly topics?: string;

	/** Keywords list.*/
	readonly keywords?: string;

	/** Naupa 2 codes list. */
	readonly naupa2Codes?: string;
};

/** Matrix search results mapper. */
@Injectable({ providedIn: 'root' })
export class SearchResultFiltersMapper implements IMapperToDto<MatrixSearchFilterDto, MatrixSearchFilters> {

	/** @inheritdoc */
	public toDto(data: MatrixSearchFilters): MatrixSearchFilterDto {
		const orderingParam = { ordering: 'name' };
		switch (data.searchType) {
			case MatrixSearchType.Topic:
				return {
					...orderingParam,
					search_type: data.searchType,
					id__in: data.topicsForm ?
						stringifyListByProp(data.topicsForm.topics.filter(name => name.id !== ALL_OPTION_ID), 'id') :
						undefined,
					include_pending: data.topicsForm?.includePending,
					jurisdictions: data.topicsForm ?
						stringifyListByProp(data.topicsForm.states.filter(name => name.id !== ALL_OPTION_ID), 'id') :
						undefined,
				};
			case MatrixSearchType.Keyword:
				return {
					...orderingParam,
					search_type: data.searchType,
					id__in: data.keywordsForm ?
						stringifyListByProp(data.keywordsForm.keywords.filter(name => name.id !== ALL_OPTION_ID), 'id') :
						undefined,
					include_pending: data.keywordsForm?.includePending,
					jurisdictions: data.keywordsForm ?
						stringifyListByProp(data.keywordsForm.states.filter(name => name.id !== ALL_OPTION_ID), 'id') :
						undefined,
				};
			default:
				return {
					...orderingParam,
					jurisdictions: data.propCodesForm ?
						stringifyListByProp(data.propCodesForm.states.filter(name => name.id !== ALL_OPTION_ID), 'id') : undefined,
					include_pending: data.propCodesForm?.includePending,
					naupa2_codes: data.propCodesForm ? stringifyListByProp(data.propCodesForm.naupa2Codes, 'id') : undefined,
				};
		}
	}

	/**
	 * To query params.
	 * @param data Filters.
	 */
	public toQueryParams(data: MatrixSearchFilters): SearchQueryParams {
		switch (data.searchType) {
			case MatrixSearchType.Topic:
				return {
					topics: data.topicsForm?.topics.length ?
						data.topicsForm.topics.map(topic => `${topic.id}:${topic.name}`).join(';') :
						undefined,
					includePending: data.topicsForm ? data.topicsForm.includePending.toString() : undefined,
					states: data.topicsForm?.states.length ?
						this.stringifyStates(data.topicsForm.states) :
						undefined,
					naupa2Codes: data.topicsForm?.naupa2Codes.length ?
						data.topicsForm?.naupa2Codes.map(code => `${code.id}:${code.code}`).join(',') :
						undefined,
				};
			case MatrixSearchType.Keyword:
				return {
					keywords: data.keywordsForm?.keywords.length ?
						data.keywordsForm.keywords.map(keyword => `${keyword.id}:${keyword.name}`).join(';') :
						undefined,
					includePending: data.keywordsForm ? data.keywordsForm.includePending.toString() : undefined,
					states: data.keywordsForm?.states.length ?
						this.stringifyStates(data.keywordsForm.states) :
						undefined,
					naupa2Codes: data.keywordsForm?.naupa2Codes.length ?
						data.keywordsForm?.naupa2Codes.map(code => `${code.id}:${code.code}`).join(',') :
						undefined,
				};
			default:
				return {
					includePending: data.propCodesForm ? data.topicsForm?.includePending.toString() : undefined,
					states: data.propCodesForm?.states.length ?
						this.stringifyStates(data.propCodesForm.states) :
						undefined,
					naupa2Codes: data.propCodesForm?.naupa2Codes.length ?
						data.propCodesForm?.naupa2Codes.map(code => `${code.id}:${code.code}`).join(',') :
						undefined,
				};
		}
	}

	/**
	 * To query params.
	 * @param data Filters.
	 */
	public fromQueryParams(data: SearchQueryParams): MatrixSearchFilters {
		const states = data.states ? data.states.split(';').map(state => {
			const [id, geonameCode, name] = state.split(':');
			return new State({
				id: Number(id),
				geonameCode,
				name,
			});
		}) : [];
		const naupa2Codes = data.naupa2Codes ? data.naupa2Codes.split(',').map(code => {
			const [id, name] = code.split(':');
			return new NullNaupa2Code({
				id: Number(id),
				code: name,
			});
		}) : [];

		if (data.topics) {
			const topics = data.topics ? data.topics.split(';').map(topic => {
				const [id, name] = topic.split(':');
				return new MatrixSearchName({
					id: Number(id),
					name,
				});
			}) : [];
			return {
				searchType: MatrixSearchType.Topic,
				topicsForm: {
					includePending: Boolean(data.includePending) && data.includePending === 'true',
					states,
					topics,
					naupa2Codes,
				},
				propCodesForm: {} as PropCodeFilter,
				keywordsForm: {} as KeywordFilter,
			};
		}

		if (data.keywords) {
			const keywords = data.keywords ? data.keywords.split(';').map(keyword => {
				const [id, name] = keyword.split(':');
				return new MatrixSearchName({
					id: Number(id),
					name,
				});
			}) : [];
			return {
				searchType: MatrixSearchType.Keyword,
				keywordsForm: {
					includePending: Boolean(data.includePending) && data.includePending === 'true',
					states,
					keywords,
					naupa2Codes,
				},
				propCodesForm: {} as PropCodeFilter,
				topicsForm: {} as TopicFilter,
			};
		}

		return {
			searchType: null,
			propCodesForm: {
				includePending: Boolean(data.includePending) && data.includePending === 'true',

				// TODO (Danil K): Implement categories mapping from query params.
				categories: [],
				states,
				naupa2Codes,
			},
			keywordsForm: {} as KeywordFilter,
			topicsForm: {} as TopicFilter,
		};
	}

	/**
	 * Stringify states array.
	 * @param states States.
	 */
	public stringifyStates(states: State[]): string {
		return states.map(state => `${state.id}:${state.geonameCode}:${state.name}`).join(';');
	}
}
