import { ChangeDetectionStrategy, Component, forwardRef, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { SortDirection } from '@scriptac/common/core/enums/sort-direction';
import { MatrixSearchName } from '@scriptac/common/core/models/matrix-search-name';
import { SearchPageService } from '@scriptac/common/core/services/search-page.service';
import { MatrixSearchType } from '@scriptac/common/core/enums/matrix-search-type';
import { MatrixSearchNameFilters } from '@scriptac/common/core/models/matrix-search-name-filters';
import { ALL_OPTION_ID } from '@scriptac/common/core/utils/constants';

/**
 * Keyword autocomplete with chips and All option.
 */
@Component({
	selector: 'scriptaw-matrix-names-autocomplete',
	templateUrl: './matrix-names-autocomplete.component.html',
	styleUrls: [
		'./matrix-names-autocomplete.component.scss',
		'../naupa-code-autocomplete/naupa-code-autocomplete.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MatrixNamesAutocompleteComponent),
			multi: true,
		},
	],
})
@DestroyableComponent()
export class MatrixNamesAutocompleteComponent extends SimpleValueAccessor<MatrixSearchName[]> {
	private readonly fb = inject(NonNullableFormBuilder);

	private readonly searchService = inject(SearchPageService);

	/** Placeholder. */
	@Input()
	public placeholder = 'Select';

	/** Search type to get matrix names. */
	@Input()
	public searchType: MatrixSearchType[] = [MatrixSearchType.Keyword];

	/** List of selected options. */
	public readonly selectedOptions = new Map<number, MatrixSearchName>();

	/** Control for input. */
	public readonly searchControl = this.fb.control('');

	private readonly filter$: Observable<MatrixSearchNameFilters> = listenControlChanges<string>(this.searchControl).pipe(
		map(search => ({
			search,
			searchType: this.searchType,
		})),
	);

	/** List manager. */
	public readonly listManager = new ListManager<MatrixSearchName, MatrixSearchNameFilters>({
		strategy: new InfiniteScrollListStrategy(),
		filter$: this.filter$,
		pageSize: 30,
	});

	/** Options list. */
	private readonly optionsList$ = this.listManager
		.getPaginatedItems(option => this.searchService.getMatrixSearchNamesList(option))
		.pipe(
			map(list => [{ id: ALL_OPTION_ID, name: 'All' } as MatrixSearchName].concat(list)),
			shareReplay({
				bufferSize: 1,
				refCount: true,
			}),
		);

	private readonly reload$ = new Subject();

	/** Filtered options list. */
	public readonly options$ = combineLatest([
		this.optionsList$,
		this.reload$.pipe(startWith(null)),
	]).pipe(
		map(([options]) => options.filter(option => !this.selectedOptions.has(option.id))),
	);

	public constructor() {
		super();

		this.listManager.setSort({
			direction: SortDirection.ASC,
			field: 'name',
		});
	}

	/**
	 * Remove option from selected by id.
	 *
	 * @param option Option.
	 */
	public remove(option: MatrixSearchName): void {
		this.selectedOptions.delete(option.id);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedOptions.values()];
	}

	/**
	 * Add selected value to selected codes.
	 *
	 * @param event Autocomplete select event.
	 */
	public selected(event: MatAutocompleteSelectedEvent): void {
		const option: MatrixSearchName = event.option.value;

		if (!option?.id) {
			return;
		}

		// If new option is All then clear others
		if (option.id === ALL_OPTION_ID) {
			this.selectedOptions.clear();
		} else if (this.selectedOptions.get(ALL_OPTION_ID)) {
			// If selected option not All, but we already select All then we should remove All option
			this.selectedOptions.delete(ALL_OPTION_ID);
		}

		this.selectedOptions.set(option.id, option);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedOptions.values()];

		this.searchControl.setValue('');
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.controlValue.forEach(val => this.selectedOptions.set(val.id, val));
		}
	}
}
