import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Law, LawEdit, SearchResultsLaw } from '../../models/law';

import { LawDto, LawEditDto, SearchResultsLawDto } from './dto/law-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { RevisionDto } from './dto/revision-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { MatrixFieldMapper } from './matrix-field-mapper';
import { StateMapper } from './state.mapper';
import { RevisionMapper } from './revision.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for laws. */
@Injectable({ providedIn: 'root' })
export class LawMapper implements IMapperFromDto<LawDto, Law>, IMapperToDto<LawEditDto, LawEdit> {
	private readonly stateMapper = inject(StateMapper);

	private readonly matrixFieldMapper = inject(MatrixFieldMapper);

	private readonly revisionMapper = inject(RevisionMapper);

	/** @inheritdoc */
	public toDto(data: LawEdit): LawEditDto {
		return {
			jurisdiction: data.state,
			matrix: data.matrix,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: LawDto): Law {
		return new Law({
			id: dto.id,
			state: this.stateMapper.fromDto(dto.jurisdiction_data),
			stateId: dto.jurisdiction,
			matrixId: dto.matrix,
			currentRevision: dto.current_revision ? this.revisionMapper.fromDto(dto.current_revision) : null,
			pendingRevision: dto.pending_revision ? this.revisionMapper.fromDto(dto.pending_revision) : null,
			enactedRevision: dto.enacted_revision ? this.revisionMapper.fromDto(dto.enacted_revision) : null,
			revisions: dto.revisions ? dto.revisions

			// We have to explicitly declare filter output type.
				.filter((rev): rev is RevisionDto => Boolean(rev))
				.map(rev => this.revisionMapper.fromDto(rev)) : [],
			matrix: dto.matrix_data ? {
				id: dto.matrix_data.id,
				name: dto.matrix_data.name,
				fields: dto.matrix_data.fields.map(f => this.matrixFieldMapper.fromDto(f)),
			} : null,
		});
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<LawEditDto>,
	): EntityValidationErrors<LawEdit> {
		return {
			state: extractErrorMessageByErrorKey(errorDto, 'jurisdiction'),
			matrix: extractErrorMessageByErrorKey(errorDto, 'matrix'),
		};
	}

	/** @inheritdoc */
	public fromSearchResultsLawDto(dto: SearchResultsLawDto): SearchResultsLaw {
		return {
			hasHistory: dto.has_history,
			...this.fromDto(dto),
		};
	}
}
