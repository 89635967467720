import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';

import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { UserProfile } from '@scriptac/common/core/models/user-profile';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { checkUserAccess } from '@scriptac/common/core/utils/check-user-access';

/**
 * User Tier lock component.
 */
@Component({
	selector: 'scriptaw-user-tier-lock',
	templateUrl: './user-tier-lock.component.html',
	styleUrls: ['./user-tier-lock.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTierLockComponent {
	private readonly currentUserService = inject(CurrentUserService);

	/** Access tier. */
	@Input()
	public tier: AccessTierLevel = AccessTierLevel.Tier1;

	/** Current user. */
	public readonly currentUser$ = this.currentUserService.currentUser$;

	/**
	 * Check whether show cell value according to user role and tier.
	 * @param user User.
	 */
	public showCellValue(user: UserProfile): boolean {
		return checkUserAccess(user, this.tier);
	}
}
