import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { PropertyType } from '../models/property-type';
import { State } from '../models/state';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { PropertyTypeDto, PropertyTypeEditDto } from './mappers/dto/property-type-dto';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';
import { PropertyTypeFiltersMapper } from './mappers/property-type-filters.mapper';
import { PropertyTypeMapper } from './mappers/property-type.mapper';

/** PropertyType filtering options. */
export type PropertyTypeFilters = {

	/** Search data. */
	readonly search?: string;

	/** Jurisdiction ids. */
	readonly jurisdictions?: State[];
};

/** Service for Property Types management. */
@Injectable({
	providedIn: 'root',
})
export class PropertyTypeService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly listMapper = inject(PagedListMapper);

	private readonly propertyTypeMapper = inject(PropertyTypeMapper);

	private readonly propertyTypeFiltersMapper = inject(PropertyTypeFiltersMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly propertyTypesUrl = new URL('property-codes/property-types/', this.config.apiUrl).toString();

	/**
	 * Get property types list.
	 *
	 * @param options Paged and sort options.
	 */
	public getPropertyTypesList(options: FetchListOptions<PropertyTypeFilters>): Observable<PagedList<PropertyType>> {
		const params = this.paramsMapper.toDto(options, this.propertyTypeFiltersMapper);

		return this.http.get<PagedListDto<PropertyTypeDto>>(this.propertyTypesUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(response, this.propertyTypeMapper, options.pagination)),
		);
	}

	/**
	 * Save PropertyType.
	 * @param value PropertyType to save.
	 */
	public savePropertyType(value: PropertyType): Observable<PropertyType> {
		const propertyTypeDto = this.propertyTypeMapper.toDto(value);

		const request$ = propertyTypeDto.id ?
			this.updatePropertyType(propertyTypeDto) :
			this.createPropertyType(propertyTypeDto);

		return request$.pipe(
			map(dto => this.propertyTypeMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.propertyTypeMapper),
		);
	}

	/**
	 * Delete property type.
	 * @param id Property type id.
	 */
	public deletePropertyType(id: number): Observable<void> {
		const url = new URL(`${id}/`, this.propertyTypesUrl).toString();
		return this.http.delete(url).pipe(
			map(() => undefined),
		);
	}

	private createPropertyType(dto: PropertyTypeEditDto): Observable<PropertyTypeDto> {
		return this.http.post<PropertyTypeDto>(this.propertyTypesUrl, dto);
	}

	private updatePropertyType(dto: PropertyTypeEditDto): Observable<PropertyTypeDto> {
		const url = new URL(`${dto.id}/`, this.propertyTypesUrl).toString();
		return this.http.put<PropertyTypeDto>(url, dto);
	}
}
