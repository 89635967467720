import { enumToArray } from '../utils/enum-to-array';

/** Revision statuses. */
export enum RevisionStatus {

	/** Priority revision.  */
	Prior = 'PRIOR',

	/** Current revision.  */
	Current = 'CURRENT',

	/** Pending revision.  */
	Pending = 'PENDING',

	/** Enacted revision.  */
	Enacted = 'ENACTED',

	/** Canceled revision.  */
	Canceled = 'CANCELED',
}

export namespace RevisionStatus {
	const TITLE_MAP: Record<RevisionStatus, string> = {
		[RevisionStatus.Prior]: 'Prior',
		[RevisionStatus.Current]: 'Current',
		[RevisionStatus.Pending]: 'Pending',
		[RevisionStatus.Enacted]: 'Enacted',
		[RevisionStatus.Canceled]: 'Canceled',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value: RevisionStatus): string {
		const readable = TITLE_MAP[value];
		if (readable === undefined) {
			console.warn(`Unknown Revision Status: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): RevisionStatus[] {
		return enumToArray(RevisionStatus);
	}
}
