import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Matrix, MatrixEdit } from '../../models/matrix';

import { MatrixDto, MatrixEditDto } from './dto/matrix-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { MatrixFieldMapper } from './matrix-field-mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for matrix. */
@Injectable({ providedIn: 'root' })
export class MatrixMapper
implements IMapperFromDto<MatrixDto, Matrix>, IMapperToDto<MatrixEditDto, MatrixEdit> {
	private readonly matrixFieldMapper = inject(MatrixFieldMapper);

	/** @inheritdoc */
	public toDto(data: MatrixEdit): MatrixEditDto {
		return {
			name: data.name,
			search_type: data.searchType,
			fields: data.fields ? data.fields.map(f => this.matrixFieldMapper.toDto(f)) : [],
		};
	}

	/** @inheritdoc */
	public fromDto(dto: MatrixDto): Matrix {
		return new Matrix({
			id: dto.id,
			name: dto.name,
			searchType: dto.search_type,
			specialType: dto.special_type,
			fields: dto.fields.map(f => this.matrixFieldMapper.fromDto(f)),
			coveredNaupa2Codes: dto.covered_naupa2_codes,
		});
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<MatrixDto>,
	): EntityValidationErrors<Matrix> {
		return {
			id: extractErrorMessageByErrorKey(errorDto, 'id'),
			name: extractErrorMessageByErrorKey(errorDto, 'name'),
			fields: extractErrorMessageByErrorKey(errorDto, 'fields'),
			coveredNaupa2Codes: extractErrorMessageByErrorKey(errorDto, 'covered_naupa2_codes'),
		};
	}
}
