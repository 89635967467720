import { DormancyLaw } from './law';
import { MatrixValue } from './matrix-value';
import { Naupa2Code } from './naupa-2-code';

/**
 * Dormancy.
 */
export class Dormancy {
	/** Id. */
	public readonly id: number;

	/** Law. */
	public readonly law: DormancyLaw;

	/** Dormancy value. */
	public readonly value: string;

	/** Alternative value. */
	public readonly alternativeValue: string;

	/** Naupa2 code values. */
	public readonly naupa2Codes: Naupa2Code[];

	/** Naupa2 code values. */
	public readonly extraValues: MatrixValue[];

	public constructor(data: DormancyInitArgs) {
		this.id = data.id;
		this.law = data.law;
		this.value = data.value;
		this.alternativeValue = data.alternativeValue;
		this.naupa2Codes = data.naupa2Codes;
		this.extraValues = data.extraValues;
	}
}

type DormancyInitArgs = Dormancy;
