import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { EditReportingTemplate } from '../../models/edit-reporting-template';
import { ReportingTemplate } from '../../models/reporting-template';

import { EditReportingTemplateDto } from './dto/edit-reporting-template-dto';
import { ReportingTemplateDto } from './dto/reporting-template-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { StateMapper } from './state.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for Reporting template. */
@Injectable({ providedIn: 'root' })
export class ReportingTemplateMapper implements
  IMapperFromDto<ReportingTemplateDto, ReportingTemplate>,
  IValidationErrorMapper<EditReportingTemplateDto, EditReportingTemplate> {
	private readonly stateMapper = inject(StateMapper);

	/** @inheritdoc */
	public fromDto(dto: ReportingTemplateDto): ReportingTemplate {
		return new ReportingTemplate({
			id: dto.id,
			state: this.stateMapper.fromDto(dto.jurisdiction_data),
			templateUrl: dto.reporting_template ?? null,
		});
	}

	/**
	 * Transform template edit data to edit DTO.
	 * @param data Reporting template edit data.
	 */
	public toEditDto(data: EditReportingTemplate): EditReportingTemplateDto {
		return {
			jurisdiction: data.jurisdictionId,
			reporting_template: data.templateUrl,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<EditReportingTemplateDto>): EntityValidationErrors<EditReportingTemplate> {
		return {
			jurisdictionId: extractErrorMessageByErrorKey(errorDto, 'jurisdiction'),
		};
	}
}
