<div class="progress-bar-container">
	@if (loading) {
		<mat-progress-bar mode="indeterminate" />
	}
</div>

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
	<table
		mat-table
		class="full-width"
		[dataSource]="dataSource"
	>
		@for (col of columns; track col.name) {
			<ng-container [matColumnDef]="col.name">
				<th
					mat-header-cell
					*matHeaderCellDef
				>
					@if (shouldUseHeaderCellTemplate(col)) {
						<ng-container
							[ngTemplateOutlet]="headerCellTemplate"
							[ngTemplateOutletContext]="{ $implicit: col, data: col.data }"
						/>
					} @else {
						{{ col.headerText }}
					}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					@if (col.name !== titleColumnName) {
						<scriptaw-user-tier-lock [tier]="getCellTier(col, element)">
							<scriptaw-matrix-dynamic-cell [matrixValue]="element[col.name]" />
							@if (element[col.name]?.note) {
								<span>({{ element[col.name].note }})</span>
							}
						</scriptaw-user-tier-lock>
					} @else {
						{{ element[col.name] }}
					}
				</td>
			</ng-container>
		}

		<!-- Header cells -->
		<tr
			*matHeaderRowDef="columnNames"
			mat-header-row
			[class.hidden]="dataSource.data.length === 0"
		></tr>

		<tr
			*matRowDef="let row; columns: columnNames"
			mat-row
		></tr>

		<!-- Empty Table message -->
		<ng-container matColumnDef="noRecords">
			<td
				*matFooterCellDef
				mat-cell
				class="empty-table-message"
				[attr.colspan]="columns.length"
			>
				@if (!loading) {
					<span>{{ emptyMessage }}</span>
				} @else {
					Loading data...
				}
			</td>
		</ng-container>
		<tr
			*matFooterRowDef="['noRecords']"
			mat-footer-row
			[class.hidden]="dataSource.data.length > 0"
		></tr>
	</table>
</div>
