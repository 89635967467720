<table
	mat-table
	class="full-width"
>
	@for (col of columns; track col.name) {
		<ng-container [matColumnDef]="col.name">
			<th
				*matHeaderCellDef
				mat-header-cell
				class="header-cell"
			>
				@if (col.name !== "title") {
					<span>
						{{ col.headerText }}
					</span>
				} @else {}
			</th>
		</ng-container>
	}

	<!-- Header cells -->
	<tr
		*matHeaderRowDef="columnNames"
		mat-header-row
	></tr>

	<tr
		*matRowDef="let row; columns: columnNames"
		mat-row
	></tr>

	<!-- Empty Table message -->
	<ng-container matColumnDef="noRecords">
		<td
			*matFooterCellDef
			mat-cell
			class="empty-table-message"
			[attr.colspan]="columns.length"
		>
			{{ emptyMessage }}
		</td>
	</ng-container>
	<tr
		*matFooterRowDef="['noRecords']"
		mat-footer-row
		[class.hidden]="columnNames.length > 0"
	></tr>
</table>
<template>
	<mat-icon
		#lockIcon
		class="lock-icon"
		svgIcon="lock-black"
	/>
</template>
