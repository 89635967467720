@for (form of formControls; track $index; let i = $index) {
	<div [formGroup]="form">
		@let value = form.getRawValue();

		<fieldset class="field-control">
			@switch (value.field.fieldType) {
				@case (matrixFieldType.Text) {
					<span class="label">{{ value.field.name }}</span>
					<quill-editor
						[modules]="editorOptions"
						formControlName="textValue"
						#editor
						(click)="editor.quillEditor.focus()"
					/>
					<scriptac-form-control-validation-message controlName="textValue" />
				}

				@case (matrixFieldType.Dormancy) {
					<scriptac-label [labelText]="value.field.name">
						<input
							formControlName="value"
							placeholder="Dormancy Value"
						/>
					</scriptac-label>
				}

				@case (matrixFieldType.AlternativeDormancy) {
					<scriptac-label [labelText]="value.field.name">
						<input
							formControlName="alternativeValue"
							placeholder="Alternative Dormancy Value"
						/>
					</scriptac-label>
				}

				@case (matrixFieldType.Bool) {
					<scriptac-label [labelText]="value.field.name">
						<mat-slide-toggle
							class="toggle"
							formControlName="boolValue"
						>
							Enable
						</mat-slide-toggle>
					</scriptac-label>
				}

				@case (matrixFieldType.Date) {
					<scriptac-label [labelText]="value.field.name">
						<input
							formControlName="dateValue"
							placeholder="Choose a Date"
							[matDatepicker]="picker"
						/>
						<mat-datepicker-toggle
							matSuffix
							class="datepicker-toggle"
							[for]="picker"
						/>
						<mat-datepicker
							color="accent"
							#picker
						/>
					</scriptac-label>
				}

				@case (matrixFieldType.MultiChoice) {
					<scriptac-label [labelText]="value.field.name">
						<mat-select
							multiple
							formControlName="multipleChoicesValues"
							placeholder="Multiple Choice"
							[compareWith]="choiceComparisonFunction"
						>
							@for (choice of value.field.choices; track choice.id) {
								<mat-option [value]="choice">
									<quill-view
										class="small-quill"
										[content]="choice.name"
									/>
								</mat-option>
							}
						</mat-select>
					</scriptac-label>
					<div class="form-field-buttons">
						<button
							mat-button
							type="button"
							class="open-list-button"
							(click)="openEditField(value.field)"
						>
							Open list / Add new
						</button>
					</div>
				}

				@case (matrixFieldType.SingleChoice) {
					<scriptac-label [labelText]="value.field.name">
						<mat-select
							formControlName="singleChoiceValue"
							placeholder="Single Choice"
							[compareWith]="choiceComparisonFunction"
						>
							<mat-option [value]="null">-- Empty --</mat-option>
							@for (choice of value.field.choices; track choice.id) {
								<mat-option [value]="choice">
									<quill-view
										class="small-quill"
										[content]="choice.name"
									/>
								</mat-option>
							}
						</mat-select>
					</scriptac-label>
					<div class="form-field-buttons">
						<button
							mat-button
							type="button"
							class="open-list-button"
							(click)="openEditField(value.field)"
						>
							Open list / Add new
						</button>
					</div>
				}

				@case (matrixFieldType.Naupa2Code) {
					<scriptac-label [labelText]="value.field.name">
						<scriptaw-naupa-code-autocomplete
							formControlName="naupa2CodeValues"
							placeholder="NAUPA II Codes"
						/>
					</scriptac-label>
				}

				@case (matrixFieldType.HolderType) {
					<scriptac-label [labelText]="value.field.name">
						<scriptaw-holder-type-autocomplete
							formControlName="holderTypeValues"
							placeholder="Holder Types"
						/>
					</scriptac-label>
				}
			}

			<scriptac-label labelText="Comment">
				<input
					formControlName="note"
					placeholder="Note"
				/>
			</scriptac-label>
			<mat-divider />
		</fieldset>
	</div>
}
