import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Company, CompanyAppUser, CompanyEditData } from '../../models/company';
import { FetchListOptions } from '../../models/fetch-list-options';
import { PagedList } from '../../models/paged-list';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { CompanyUsersParamsMapper } from '../mappers/company-users-params.mapper';
import { CompanyMapper } from '../mappers/company.mapper';
import { CompanyDto } from '../mappers/dto/company-dto';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';

/** Companies filtering options. */
export type CompaniesFilters = {

	/** Search data. */
	readonly search: string | null;
};

/** Service to access in companies API. */
@Injectable({ providedIn: 'root' })
export class CompaniesApiService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly listMapper = inject(PagedListMapper);

	private readonly companyMapper = inject(CompanyMapper);

	private readonly companyUsersParamsMapper = inject(CompanyUsersParamsMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly companiesUrl = new URL('users/companies/', this.config.apiUrl).toString();

	/**
	 * Get companies paged list.
	 * @param options Pagination, sorting and filtering options.
	 */
	public getCompaniesPagedLists(
		options: FetchListOptions<CompaniesFilters>,
	): Observable<PagedList<Company>> {
		const params = this.paramsMapper.toDto(options);

		return this.http.get<PagedListDto<CompanyDto>>(this.companiesUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(response, this.companyMapper, options.pagination)),
		);
	}

	/**
	 * Get company by id.
	 * @param id Id to get company.
	 */
	public getCompanyById(id: number): Observable<Company> {
		const url = new URL(`${id}/`, this.companiesUrl).toString();

		return this.http.get<CompanyDto>(url).pipe(
			map(company => this.companyMapper.fromDto(company)),
		);
	}

	/**
	 * Remove users from company by id.
	 *
	 * @param companyId Id of company.
	 * @param userIdList List of users id.
	 */
	public removeUsersFromCompany(companyId: number, userIdList: number[]): Observable<CompanyAppUser[]> {
		const url = new URL(`${companyId}/remove-users/`, this.companiesUrl).toString();

		return this.http
			.post<CompanyDto>(url, this.companyUsersParamsMapper.toDto({ userIdList }))
			.pipe(
				map(company => this.companyMapper.fromDto(company)),
				map(company => company.usersData),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.companyMapper),
			);
	}

	/**
	 * Remove users from company by id.
	 *
	 * @param companyId Id of company.
	 * @param userIdList List of users id.
	 */
	public addUsersAtCompany(companyId: number, userIdList: number[]): Observable<CompanyAppUser[]> {
		const url = new URL(`${companyId}/add-users/`, this.companiesUrl).toString();

		return this.http
			.post<CompanyDto>(url, this.companyUsersParamsMapper.toDto({ userIdList }))
			.pipe(
				map(company => this.companyMapper.fromDto(company)),
				map(company => company.usersData),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.companyMapper),
			);
	}

	/**
	 * Update company by id.
	 *
	 * @param id Company id.
	 * @param company Company data.
	 */
	public updateCompany(id: number, company: CompanyEditData): Observable<Company> {
		const url = new URL(`${id}/`, this.companiesUrl).toString();
		const companyDto = this.companyMapper.toDto(company);

		return this.http.put<CompanyDto>(url, companyDto).pipe(
			map(dto => this.companyMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.companyMapper),
		);
	}

	/**
	 * Update company by id.
	 *
	 * @param company Company data.
	 */
	public createCompany(company: CompanyEditData): Observable<Company> {
		const companyDto = this.companyMapper.toDto(company);

		return this.http.post<CompanyDto>(this.companiesUrl, companyDto).pipe(
			map(dto => this.companyMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.companyMapper),
		);
	}
}
