import { Injectable } from '@angular/core';

import { MatrixSearchNameFilters } from '../../models/matrix-search-name-filters';

import { IMapperToDto } from './mappers';
import { MatrixSearchNameFiltersDto } from './dto/matrix-search-name-filters-dto';

/** Mapper for search matrix names filters. */
@Injectable({ providedIn: 'root' })
export class MatrixSearchNameFiltersMapper implements IMapperToDto<MatrixSearchNameFiltersDto, MatrixSearchNameFilters> {

	/** @inheritdoc */
	public toDto(data: MatrixSearchNameFilters): MatrixSearchNameFiltersDto {
		const params: MatrixSearchNameFiltersDto = {};

		if (data.search) {
			params.search = data.search;
		}

		if (data.searchType) {
			params.search_type__in = data.searchType.join(',');
		}

		return params;
	}
}
