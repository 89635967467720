<div class="control-container">
	<mat-chip-grid
		multiple
		#chipList
	>
		@for (state of getSortedSelectedStates(); track state.id) {
			<mat-chip-row
				(removed)="remove(state)"
				class="selected-option"
			>
				{{ state.geonameCode }}
				@if (shouldShowRemoveButton(state)) {
					<mat-icon
						matChipRemove
						class="cancel-icon"
					>
						cancel
					</mat-icon>
				}
			</mat-chip-row>
		}
		<input
			matInput
			class="autocomplete-input"
			#trigger="matAutocompleteTrigger"
			[formControl]="stateControl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[placeholder]="placeholder"
			(click)="trigger.openPanel()"
		/>
		<mat-icon
			matSuffix
			class="dropdown-arrow"
		>
			arrow_drop_down
		</mat-icon>
	</mat-chip-grid>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="selected($event)"
	>
		@let states = filteredStates$ | async;

		@if (!states?.length) {
			<mat-option>Not found</mat-option>
		}

		@for (state of states; track state.id) {
			<mat-option [value]="state">
				{{ state.name }}
			</mat-option>
		}
	</mat-autocomplete>
</div>
