import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortDirection } from '../enums/sort-direction';
import { EditReportingTemplate } from '../models/edit-reporting-template';
import { FetchListOptions } from '../models/fetch-list-options';
import { SearchResultsLaw } from '../models/law';
import { MatrixSearchResult } from '../models/matrix-search-result';
import { PagedList } from '../models/paged-list';
import { ReportingTemplate } from '../models/reporting-template';
import { State } from '../models/state';
import { HolderType } from '../models/holder-type';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { EditReportingTemplateDto } from './mappers/dto/edit-reporting-template-dto';
import { SearchResultsLawDto } from './mappers/dto/law-dto';
import { MatrixSearchResultDto } from './mappers/dto/matrix-search-result-dto';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { ReportingTemplateDto } from './mappers/dto/reporting-template-dto';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { LawMapper } from './mappers/law.mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';
import { ReportingTemplateMapper } from './mappers/reporting-template.mapper';
import { ReportingTemplatesFilterMapper } from './mappers/reporting-templates-filters.mapper';
import { SearchResultsMapper } from './mappers/search-results.mapper';
import { JurisdictionReportingTemplateDto } from './mappers/dto/jurisdiction-reporting-template-dto';
import { JurisdictionReportingTemplateMapper } from './mappers/jurisdiction-reporting-template.mapper';
import { JurisdictionReportingTemplateFiltersMapper } from './mappers/jurisdiction-reporting-template-filters.mapper';

/**
 * Reporting templates filter.
 */
export type ReportingMechanicsFilters = {

	/** States. */
	readonly states?: State[];

	/** Holder Types. */
	readonly holderTypes?: HolderType[];
};

/**
 * Reporting Data service.
 */
@Injectable({
	providedIn: 'root',
})
export class ReportingDataService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly listMapper = inject(PagedListMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly reportingTemplateMapper = inject(ReportingTemplateMapper);

	private readonly reportingTemplateFiltersMapper = inject(ReportingTemplatesFilterMapper);

	private readonly matrixMapper = inject(SearchResultsMapper);

	private readonly lawMapper = inject(LawMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly pagedListMapper = inject(PagedListMapper);

	private readonly jurisdictionReportingTemplateMapper = inject(JurisdictionReportingTemplateMapper);

	private readonly jurisdictionReportingTemplateFiltersMapper = inject(JurisdictionReportingTemplateFiltersMapper);

	private readonly templatesApiUrl = new URL('laws/reporting-templates/', this.config.apiUrl).toString();

	private readonly reportingMatrixUrl = new URL('laws/reporting-mechanics/matrix/', this.config.apiUrl).toString();

	private readonly reportingMatrixLawsUrl = new URL('laws/reporting-mechanics/laws/', this.config.apiUrl).toString();

	private readonly dueDiligenceMatrixUrl = new URL('laws/due-diligence/matrix/', this.config.apiUrl).toString();

	private readonly dueDiligenceMatrixLawsUrl = new URL('laws/due-diligence/laws/', this.config.apiUrl).toString();

	private readonly jurisdictionReportingUrl = new URL('laws/jurisdiction-reporting-templates/', this.config.apiUrl).toString();

	/**
	 * Gets reporting templates.
	 * @param filters Filters.
	 */
	public getReportingTemplates(filters: FetchListOptions<ReportingMechanicsFilters>): Observable<PagedList<ReportingTemplate>> {
		const params = this.paramsMapper.toDto(filters, this.jurisdictionReportingTemplateFiltersMapper);

		return this.http.get<PagedListDto<JurisdictionReportingTemplateDto>>(this.jurisdictionReportingUrl, { params }).pipe(
			map(response => this.pagedListMapper.fromDto(response, this.jurisdictionReportingTemplateMapper, filters.pagination)),
		);
	}

	/**
	 * Get reporting matrix.
	 */
	public getReportingMatrix(): Observable<MatrixSearchResult> {
		return this.http.get<MatrixSearchResultDto>(this.reportingMatrixUrl).pipe(
			map(dto => this.matrixMapper.fromDto(dto)),
		);
	}

	/**
	 * Get due diligence matrix.
	 */
	public getDueDiligenceMatrix(): Observable<MatrixSearchResult> {
		return this.http.get<MatrixSearchResultDto>(this.dueDiligenceMatrixUrl).pipe(
			map(dto => this.matrixMapper.fromDto(dto)),
		);
	}

	/**
	 * Get reporting matrix laws list.
	 * @param options Options.
	 */
	public getReportingMatrixLaws(options: FetchListOptions<ReportingMechanicsFilters>): Observable<PagedList<SearchResultsLaw>> {
		return this.getMatrixLaws(options, this.reportingMatrixLawsUrl);
	}

	/**
	 * Get reporting matrix laws list.
	 * @param options Options.
	 */
	public getDueDiligenceMatrixLaws(options: FetchListOptions<ReportingMechanicsFilters>): Observable<PagedList<SearchResultsLaw>> {
		return this.getMatrixLaws(options, this.dueDiligenceMatrixLawsUrl);
	}

	/**
	 * Save reporting template.
	 * @param data Reporting template data.
	 */
	public saveReportingTemplate(data: EditReportingTemplate): Observable<ReportingTemplate> {
		const templateDto = this.reportingTemplateMapper.toEditDto(data);

		const request$ = data.templateId ?
			this.updateTemplate(templateDto, data.templateId) :
			this.createTemplate(templateDto);

		return request$.pipe(
			map(dto => this.reportingTemplateMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.reportingTemplateMapper),
		);
	}

	/**
	 * Delete reporting template by id.
	 * @param id Template id.
	 */
	public deleteTemplate(id: number): Observable<void> {
		const url = new URL(`${id}/`, this.templatesApiUrl).toString();
		return this.http.delete<void>(url);
	}

	private createTemplate(dto: EditReportingTemplateDto): Observable<ReportingTemplateDto> {
		return this.http.post<ReportingTemplateDto>(this.templatesApiUrl, dto);
	}

	private updateTemplate(dto: EditReportingTemplateDto, id: number): Observable<ReportingTemplateDto> {
		const url = new URL(`${id}/`, this.templatesApiUrl).toString();
		return this.http.put<ReportingTemplateDto>(url, dto);
	}

	private getMatrixLaws(options: FetchListOptions<ReportingMechanicsFilters>, apiUrl: string): Observable<PagedList<SearchResultsLaw>> {
		const params = this.paramsMapper.toDto({
			...options,
			sort: {
				direction: SortDirection.ASC,
				field: 'jurisdiction__name',
			},
		}, this.reportingTemplateFiltersMapper);

		return this.http
			.get<PagedListDto<SearchResultsLawDto>>(apiUrl, { params })
			.pipe(
				map(response => this.listMapper.fromDto(
					response,
					dto => this.lawMapper.fromSearchResultsLawDto(dto),
					options.pagination,
				)),
			);
	}
}
