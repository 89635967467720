import { enumToArray } from '../utils/enum-to-array';

/** Type of matrix search. */
export enum MatrixSearchType {

	/** Topic search type. */
	Topic = 'TOPIC',

	/** Keyword search type. */
	Keyword = 'KEYWORD',

	/** Not included search type (used for matrixes with special type). */
	NotIncluded = 'NOT_INCLUDED',
}

export namespace MatrixSearchType {

	const TITLE_MAP: Record<MatrixSearchType, string> = {
		[MatrixSearchType.Topic]: 'Topic',
		[MatrixSearchType.Keyword]: 'Keyword',
		[MatrixSearchType.NotIncluded]: '',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value: MatrixSearchType): string {
		const readable = TITLE_MAP[value];
		if (readable == null) {
			console.warn(`Unknown type: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): MatrixSearchType[] {
		return enumToArray(MatrixSearchType).filter(type => type !== MatrixSearchType.NotIncluded);
	}
}
