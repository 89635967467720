import { Injectable, inject } from '@angular/core';

import { PropertyType } from '../../models/property-type';
import { Rule } from '../../models/rule';

import { RuleDto } from './dto/rule-dto';
import { IMapperFromDto } from './mappers';
import { StateMapper } from './state.mapper';

/** Rule mapper. */
@Injectable({ providedIn: 'root' })
export class RuleMapper implements IMapperFromDto<RuleDto, Rule> {
	private readonly stateMapper = inject(StateMapper);

	/** @inheritdoc */
	public fromDto(dto: RuleDto): Rule {
		return new Rule({
			id: dto.id,
			naupa2CodeId: dto.naupa2_code,
			propertyTypeId: dto.property_type,
			stateIds: dto.jurisdictions,
			statesData: dto.jurisdictions_data ?
				dto.jurisdictions_data.map(stateDto => this.stateMapper.fromDto(stateDto)) :
				null,
			propertyTypeData: dto.property_type_data ? new PropertyType({
				id: dto.property_type_data.id,
				name: dto.property_type_data.name,
				rules: [],
			}) : null,
		});
	}
}
