import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../../models/fetch-list-options';
import { PagedList } from '../../models/paged-list';
import { PriorityRule, PriorityRuleEdit } from '../../models/priority-rule';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { PriorityRuleDto } from '../mappers/dto/priority-rule-dto';
import { PriorityRuleMapper } from '../mappers/priority-rule.mapper';
import { AppConfigService } from '../app-config.service';

/**
 * Priority rules service.
 */
@Injectable({
	providedIn: 'root',
})
export class PriorityRulesApiService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly priorityRuleMapper = inject(PriorityRuleMapper);

	private readonly listMapper = inject(PagedListMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly apiUrl = new URL('laws/priority-rules/', this.config.apiUrl).toString();

	/**
	 * Get priority rules list.
	 * @param options Options.
	 */
	public getPriorityRules(options: FetchListOptions): Observable<PagedList<PriorityRule>> {
		const params = this.paramsMapper.toDto(options);

		return this.http.get<PagedListDto<PriorityRuleDto>>(this.apiUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(
				response,
				this.priorityRuleMapper,
				options.pagination,
			)),
		);
	}

	/**
	 * Get rule by id.
	 * @param id Rule id.
	 */
	public getPriorityRuleById(id: number): Observable<PriorityRule> {
		const url = new URL(`${id}/`, this.apiUrl).toString();
		return this.http.get<PriorityRuleDto>(url).pipe(
			map(dto => this.priorityRuleMapper.fromDto(dto)),
		);
	}

	/**
	 * Delete rule.
	 * @param id Rule id.
	 */
	public deleteRule(id: number): Observable<void> {
		const url = new URL(`${id}/`, this.apiUrl).toString();

		return this.http.delete<void>(url);
	}

	/**
	 * Create rule.
	 * @param newRule Rule info.
	 */
	public createRule(newRule: PriorityRuleEdit): Observable<PriorityRule> {
		const dto = this.priorityRuleMapper.toDto(newRule);

		return this.http.post<PriorityRuleDto>(this.apiUrl, dto).pipe(
			map(updatedDto => this.priorityRuleMapper.fromDto(updatedDto)),
		);
	}

	/**
	 * Update rule.
	 * @param id Rule id.
	 * @param updatedRule Rule info.
	 */
	public updateRule(id: number, updatedRule: PriorityRuleEdit): Observable<PriorityRule> {
		const url = new URL(`${id}/`, this.apiUrl).toString();
		const dto = this.priorityRuleMapper.toDto(updatedRule);

		return this.http.put<PriorityRuleDto>(url, dto).pipe(
			map(updatedDto => this.priorityRuleMapper.fromDto(updatedDto)),
		);
	}
}
