<mat-divider />
<div class="header">
	<h3>Reporting Information</h3>
</div>
<mat-divider />

<form
	class="filters-form"
	[formGroup]="filtersForm"
>
	<scriptac-label
		labelText="Jurisdiction"
		class="jurisdiction-select"
	>
		<scriptaw-states-autocomplete
			formControlName="states"
			[needAllOption]="true"
		/>
	</scriptac-label>
	@if (showExportButton$ | async) {
		<scriptaw-export-menu-buttons (selectExportFileFormat)="exportResults($event)" />
	}
</form>

<scriptaw-base-table
	emptyMessage="No items found."
	[rows]="reportingTemplates$ | async"
	[columns]="(tableColumns$ | async) || []"
	[trackBy]="trackById"
	[loading]="tableManager.listLoading$ | async"
	[pagination]="tableManager.pagePagination$ | async"
	(paginationChange)="tableManager.setPagination($event, true)"
>
	<!-- Jurisdiction. -->
	<ng-container scriptawTableColumn="jurisdiction">
		<ng-container *scriptawTableCell="let row">
			<span>{{ row.state.name }}</span>
		</ng-container>
	</ng-container>

	<!-- View Data. -->
	<ng-container scriptawTableColumn="viewData">
		<ng-container *scriptawTableCell="let row">
			<a
				mat-flat-button
				class="link"
				[routerLink]="viewDataLink$ | async"
				[queryParams]="getViewDataQueryParams(row)"
			>
				View Data
			</a>
		</ng-container>
	</ng-container>

	<!-- Download Data. -->
	<ng-container scriptawTableColumn="downloadTemplate">
		<ng-container *scriptawTableCell="let row">
			@if (row.templateUrl) {
				<div class="template-cell">
					<a
						mat-flat-button
						class="link"
						download="Due Diligence Template"
						[href]="row.templateUrl | safe: 'resourceUrl'"
					>
						Download
					</a>
					@if (isAdmin$ | async) {
						<div class="actions">
							<button
								mat-icon-button
								type="button"
								title="Edit Template"
								(click)="editTemplate(row)"
							>
								<mat-icon svgIcon="edit" />
							</button>
							<button
								mat-icon-button
								type="button"
								title="Delete Template"
								(click)="deleteTemplate(row)"
							>
								<mat-icon svgIcon="trash" />
							</button>
						</div>
					}
				</div>
			} @else {
				@if (isAdmin$ | async) {
					<button
						mat-flat-button
						class="link"
						type="button"
						(click)="editTemplate(row)"
					>
						Upload
					</button>
				}
			}
		</ng-container>
	</ng-container>
</scriptaw-base-table>
