<scriptaw-base-table
	emptyMessage="No items found."
	[rows]="priorityRules$ | async"
	[columns]="displayedTableColumns"
	[trackBy]="trackById"
	[loading]="tableManager.listLoading$ | async"
	[pagination]="tableManager.pagePagination$ | async"
	(paginationChange)="tableManager.setPagination($event, true)"
>
	<!-- Rule type. -->
	<ng-container scriptawTableColumn="rule">
		<ng-container *scriptawTableCell="let row">
			<span class="rule-cell">{{ priorityRules.toReadable(row.rule) }}</span>
		</ng-container>
	</ng-container>

	<!-- First Priority. -->
	<ng-container scriptawTableColumn="firstPriority">
		<ng-container *scriptawTableCell="let row">
			{{ row.firstPriority }}
		</ng-container>
	</ng-container>

	<!-- Second Priority. -->
	<ng-container scriptawTableColumn="secondPriority">
		<ng-container *scriptawTableCell="let row">
			{{ row.secondPriority }}
		</ng-container>
	</ng-container>

	<!-- Source. -->
	<ng-container scriptawTableColumn="source">
		<ng-container *scriptawTableCell="let row">
			{{ row.source }}
		</ng-container>
	</ng-container>

	<!-- Applicability. -->
	<ng-container scriptawTableColumn="applicability">
		<ng-container *scriptawTableCell="let row">
			{{ row.applicability }}
		</ng-container>
	</ng-container>

	<!-- Actions. -->
	<ng-container scriptawTableColumn="actions">
		<ng-container *scriptawTableCell="let row">
			<div class="actions">
				<a
					mat-icon-button
					title="Edit Priority Rule"
					[routerLink]="routePaths.adminPriorityRuleEdit(row.id)"
				>
					<mat-icon svgIcon="edit" />
				</a>
				<button
					mat-icon-button
					type="button"
					title="Delete Priority Rule"
					(click)="deleteRule(row.id)"
				>
					<mat-icon svgIcon="trash" />
				</button>
			</div>
		</ng-container>
	</ng-container>
</scriptaw-base-table>
