import { Observable, BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';
import {
	map,
	switchMapTo,
	withLatestFrom,
	tap,
	switchMap,
	shareReplay,
	debounceTime,
	startWith,
} from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { PaginationOptions } from '../models/pagination-options';
import { Sort } from '../models/sort';

import { toggleExecutionState } from './toggle-execution-state';

type FetchItemsApiRequest<TItem, TFilter> = (
	options: FetchListOptions<TFilter>,
) => Observable<PagedList<TItem>>;

/**
 * Provide API to handle "PagedList".
 */
type IListHandleStrategy<T> = {

	/** Handle paginated list according to some rules. */
	handle(paginatedList: PagedList<T>): T[];
};

/**
 * Handler for "Infinite scroll" functionality.
 *
 * The point is to concatenate items from new PagedList with the previous one
 * until "PagedList.pagination.page" does not equal 1.
 */
export class InfiniteScrollListStrategy<T> implements IListHandleStrategy<T> {
	private list: T[] = [];

	/** @inheritdoc */
	public handle(paginatedList: PagedList<T>): T[] {
		if (paginatedList.pagination.page === 0) {
			this.list = paginatedList.items;
		} else {
			this.list = this.list.concat(paginatedList.items);
		}
		return this.list;
	}
}

/**
 * Handle for "table" functionality.
 *
 * Common strategy that just return items from received "PagedList" instance.
 */
export class TableListStrategy<T> implements IListHandleStrategy<T> {
	/** @inheritdoc */
	public handle(paginatedList: PagedList<T>): T[] {
		return paginatedList.items;
	}
}

/** Data for ListManager constructor. */
export type ListManagerConstructorData<TItem, TFilter> = {

	/** List strategy. */
	readonly strategy: IListHandleStrategy<TItem>;

	/** List of filters. */
	readonly filter$?: Observable<TFilter>;

	/** Sorting. */
	readonly sort$?: Observable<Sort | undefined>;

	/** Page size. */
	readonly pageSize?: number;

	/** Items compare function. */
	readonly compareFunction?: (first: TItem, second: TItem) => boolean;
};

/**
 * Provide functionality to work with lists.
 * Handle pagination, filters and sorting.
 *
 * Please note that list manager will use its own filter and sort
 * streams if you don't provide them in constructor.
 */
export class ListManager<TItem, TFilter = {}> {
	/** Emits information about page pagination. */
	public readonly pagePagination$: Observable<PaginationOptions>;

	/** Emits value of selected filters. */
	public readonly filter$: Observable<TFilter | undefined>;

	/** Emits value of selected sort. */
	public readonly sort$: Observable<Sort | undefined>;

	/** List loading state. */
	public readonly listLoading$: Observable<boolean>;

	/** Reload list. */
	private readonly reload$ = new Subject();

	private readonly loading$ = new BehaviorSubject(false);

	private readonly pagination$ = new BehaviorSubject(new PaginationOptions({ pageSize: this.data.pageSize }));

	private readonly sortValue$ = new BehaviorSubject<Sort | undefined>(undefined);

	private readonly filterValue$ = new BehaviorSubject<TFilter | undefined>(undefined);

	private readonly resetPaginationParams$: Observable<[Sort | undefined, TFilter | undefined]>;

	private readonly updatedValue$ = new Subject<TItem>();

	private readonly compareFunction: (first: TItem, second: TItem) => boolean;

	public constructor(private readonly data: ListManagerConstructorData<TItem, TFilter>) {
		this.listLoading$ = this.loading$.asObservable();
		this.compareFunction = data.compareFunction ?? this.defaultEqualityFunction;

		if (this.data.filter$) {
			this.filter$ = this.data.filter$;
		} else {
			this.filter$ = this.filterValue$.asObservable();
		}

		if (this.data.sort$) {
			this.sort$ = this.data.sort$;
		} else {
			this.sort$ = this.sortValue$.asObservable();
		}

		this.resetPaginationParams$ = combineLatest([this.sort$, this.filter$]).pipe(
			debounceTime(400),
			tap(() => this.loading$.next(true)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.pagePagination$ = merge(
			this.pagination$,
			this.resetPaginationParams$.pipe(map(() => this.resetPagination())),
		).pipe(shareReplay({ bufferSize: 1, refCount: true }));
	}

	/**
	 * Get list of paginated items from server.
	 * @param func Api request function.
	 * @returns Paginated items list.
	 */
	public getPaginatedItems(func: FetchItemsApiRequest<TItem, TFilter>): Observable<TItem[]> {
		return this.reload$.pipe(
			startWith(null),
			switchMapTo(this.resetPaginationParams$),
			withLatestFrom(this.pagePagination$),
			switchMap(([[sort, filter], pagination]) => func({ pagination, sort, filters: filter }).pipe(
				toggleExecutionState(this.loading$),
			)),
			tap(pagedList => this.setPagination(pagedList.pagination)),
			map(list => this.data.strategy.handle(list)),
			switchMap(list => this.initUpdateValueStream(list)),
		);
	}

	/**
	 * Sort changed.
	 * @param sort Updated sort.
	 */
	public setSort(sort?: Sort): void {
		this.sortValue$.next(sort);
	}

	/**
	 * Filters changed.
	 * @param filters Updated filters.
	 */
	public filtersChanged(filters?: TFilter): void {
		this.filterValue$.next(filters);
	}

	/**
	 * Pagination changed.
	 * @param pagination Updated pagination.
	 * @param triggerReload Should reload the list.
	 */
	public setPagination(pagination: PaginationOptions, triggerReload = false): void {
		this.pagination$.next(pagination);
		if (triggerReload) {
			this.reload$.next(undefined);
		}
	}

	/**
	 * Go to next page.
	 */
	public nextPage(): void {
		if (!this.pagination$.value.haveNext) {
			return;
		}

		const nextPagination = new PaginationOptions({
			page: this.pagination$.value.page + 1,
			pageSize: this.data.pageSize,
		});
		this.setPagination(nextPagination, true);
	}

	/**
	 * Update item in list.
	 * If item is not presented in the list then append item to the start of the list.
	 * @see WARNING: It won't work until you provide compare function for List manager.
	 * @param item Item.
	 */
	public updateItem(item: TItem): void {
		this.updatedValue$.next(item);
	}

	/**
	 * Manually reload list.
	 * If table strategy is used then update only current page.
	 */
	public reloadList(): void {
		this.reload$.next(undefined);
	}

	/** Reset pagination. */
	private resetPagination(): PaginationOptions {
		const currentPagination = this.pagination$.value;
		return new PaginationOptions({
			page: 0,
			pageSize: currentPagination.pageSize,
			totalCount: currentPagination.totalCount,
		});
	}

	private initUpdateValueStream(list: TItem[]): Observable<TItem[]> {
		return this.updatedValue$.pipe(
			startWith(null),
			map(updatedValue => {
				if (updatedValue) {
					const index = list.findIndex(item => this.compareFunction(item, updatedValue));
					if (index !== -1) {
						list[index] = updatedValue;
					} else {
						return [...list, updatedValue];
					}
				}

				return [...list];
			}),
		);
	}

	private defaultEqualityFunction(first: TItem, second: TItem): boolean {
		return first === second;
	}
}
