import { Injectable, inject } from '@angular/core';

import { MatrixValue, MatrixValueEdit } from '../../models/matrix-value';
import { EntityValidationErrors } from '../../models/app-error';

import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { MatrixValueDto, MatrixValueEditDto } from './dto/matrix-value-dto';
import { MatrixFieldMapper } from './matrix-field-mapper';
import { ChoicesValueMapper } from './choices-value.mapper';
import { Naupa2CodeMapper } from './naupa-2-code.mapper';
import { DateMapper } from './date.mapper';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { HolderTypeMapper } from './holder-type.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Matrix value mapper. */
@Injectable({ providedIn: 'root' })
export class MatrixValueMapper implements IMapperFromDto<MatrixValueDto, MatrixValue>,
  IValidationErrorMapper<MatrixValueEditDto, MatrixValueEdit> {
	private readonly fieldMapper = inject(MatrixFieldMapper);

	private readonly choicesValueMapper = inject(ChoicesValueMapper);

	private readonly naupa2CodeMapper = inject(Naupa2CodeMapper);

	private readonly dateMapper = inject(DateMapper);

	private readonly holderTypeMapper = inject(HolderTypeMapper);

	/** @inheritdoc */
	public fromDto(dto: MatrixValueDto): MatrixValue {
		return new MatrixValue({
			id: dto.id,
			fieldId: dto.field,
			field: this.fieldMapper.fromDto(dto.field_data),
			textValue: dto.text_value ?? '',
			dateValue: dto.date_value ? this.dateMapper.fromDto(dto.date_value) : null,
			boolValue: dto.bool_value,
			choicesValues: dto.choices_values_data?.map(item => this.choicesValueMapper.fromDto(item)) ?? [],
			naupa2CodeValues: dto.naupa2_code_values_data?.map(item => this.naupa2CodeMapper.fromDto(item)) ?? [],
			holderTypeValues: dto.holder_type_values_data?.map(item => this.holderTypeMapper.fromDto(item)) ?? [],
			note: dto.note,
		});
	}

	/** @inheritdoc */
	public toDto(data: MatrixValueEdit): MatrixValueEditDto {
		let choicesValues: number[] = [];

		if (data.multipleChoicesValues) {
			choicesValues = data.multipleChoicesValues.map(choice => choice.id);
		}
		if (data.singleChoiceValue) {
			choicesValues = [data.singleChoiceValue.id];
		}

		return {
			id: data.id ?? null,
			field: data.field.id,
			text_value: data.textValue ?? data.value ?? data.alternativeValue ?? null,
			bool_value: data.boolValue ?? null,
			date_value: data.dateValue ? this.dateMapper.toDto(data.dateValue) : null,
			choices_values: choicesValues,
			naupa2_code_values: data.naupa2CodeValues ? data.naupa2CodeValues.map(c => c.id) : [],
			holder_type_values: data.holderTypeValues ? data.holderTypeValues.map(item => item.id) : [],
			note: data.note,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<MatrixValueEditDto>,
	): EntityValidationErrors<MatrixValueEdit> {
		return {
			textValue: extractErrorMessageByErrorKey(errorDto, 'text_value'),
			boolValue: extractErrorMessageByErrorKey(errorDto, 'bool_value'),
			dateValue: extractErrorMessageByErrorKey(errorDto, 'date_value'),
			singleChoiceValue: extractErrorMessageByErrorKey(errorDto, 'choices_values'),
			multipleChoicesValues: extractErrorMessageByErrorKey(errorDto, 'choices_values'),
			naupa2CodeValues: extractErrorMessageByErrorKey(errorDto, 'naupa2_code_values'),
			holderTypeValues: extractErrorMessageByErrorKey(errorDto, 'holder_type_values'),
			note: extractErrorMessageByErrorKey(errorDto, 'note'),
		};
	}
}
