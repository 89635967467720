import { enumToArray } from '../utils/enum-to-array';

/** Export file format. */
export enum ExportFileFormat {
	CSV = 'csv',
	XLSX = 'xlsx',
}

export namespace ExportFileFormat {
	const TITLE_MAP: Record<ExportFileFormat, string> = {
		[ExportFileFormat.CSV]: 'CSV',
		[ExportFileFormat.XLSX]: 'XLSX',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value: ExportFileFormat): string {
		const readable = TITLE_MAP[value];
		return readable;
	}

	/** Get list of all enum items. */
	export function toArray(): ExportFileFormat[] {
		return enumToArray(ExportFileFormat);
	}
}
