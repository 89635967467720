import { Component, ChangeDetectionStrategy, forwardRef, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';
import { SortDirection } from '@scriptac/common/core/enums/sort-direction';
import { PropertyCodeCategory } from '@scriptac/common/core/models/property-code-category';
import { PropertyCodeCategoryFilters, PropertyCodeCategoryService } from '@scriptac/common/core/services/property-code-category.service';
import { ALL_OPTION_ID } from '@scriptac/common/core/utils/constants';

/** Multi select for property code category. */
@Component({
	selector: 'scriptaw-category-autocomplete',
	templateUrl: './category-autocomplete.component.html',
	styleUrls: ['./category-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CategoryAutocompleteComponent),
			multi: true,
		},
	],
})
@DestroyableComponent()
export class CategoryAutocompleteComponent extends SimpleValueAccessor<PropertyCodeCategory[]> {
	private readonly fb = inject(NonNullableFormBuilder);

	private readonly categoryService = inject(PropertyCodeCategoryService);

	/** Placeholder. */
	@Input()
	public placeholder = 'Select';

	/** Hide state specific categories. */
	@Input()
	public hideStateSpecific = true;

	/** Jurisdiction ids. */
	@Input()
	public set jurisdictionIds(list: number[]) {
		if (JSON.stringify(list) === JSON.stringify(this.jurisdictionIds$.value)) {
			return;
		}

		// If all states were selected, should not filter selected items.
		if (list.includes(ALL_OPTION_ID)) {
			this.jurisdictionIds$.next(list);
			return;
		}

		// To display only categories according to selected states
		const selectedCodes = [...this.selectedItems.values()];
		this.selectedItems.clear();
		for (const stateId of list) {
			for (const selectedValue of selectedCodes) {
				const hasStateInList = selectedValue.states.map(state => state.id).includes(stateId);
				if (hasStateInList) {
					this.selectedItems.set(selectedValue.id, selectedValue);
				}
			}
		}
		this.controlValue = [...this.selectedItems.values()];

		this.jurisdictionIds$.next(list);
	}

	/** List of selected codes. */
	public readonly selectedItems = new Map<number, PropertyCodeCategory>();

	/** Control for input. */
	public readonly searchControl = this.fb.control('');

	/** Jurisdiction ids. */
	private readonly jurisdictionIds$ = new BehaviorSubject<number[]>([]);

	private readonly filter$: Observable<PropertyCodeCategoryFilters> = combineLatest([
		listenControlChanges<string>(this.searchControl),
		this.jurisdictionIds$,
	]).pipe(
		map(([search, ids]) => ({
			jurisdictionIds: ids,
			search,
			hideStateSpecific: this.hideStateSpecific,
		})),
	);

	/** List manager. */
	public readonly listManager = new ListManager<PropertyCodeCategory, PropertyCodeCategoryFilters>({
		strategy: new InfiniteScrollListStrategy(),
		filter$: this.filter$,
		pageSize: 30,
	});

	/** Codes list. */
	private readonly codesList$ = this.listManager
		.getPaginatedItems(option => this.categoryService.getCategoriesList(option))
		.pipe(
			shareReplay({
				bufferSize: 1,
				refCount: true,
			}),
		);

	private readonly reload$ = new Subject();

	/** Filtered codes list. */
	public readonly codes$ = combineLatest([
		this.codesList$,
		this.reload$.pipe(startWith(null)),
	]).pipe(
		map(([codes]) => codes.filter(code => !this.selectedItems.has(code.id))),
	);

	public constructor() {
		super();

		this.listManager.setSort({
			direction: SortDirection.ASC,
			field: 'code',
		});
	}

	/**
	 * Remove item from selected by id.
	 * @param item Category.
	 */
	public remove(item: PropertyCodeCategory): void {
		this.selectedItems.delete(item.id);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedItems.values()];
	}

	/**
	 * Add selected value to selected codes.
	 *
	 * @param event Autocomplete select event.
	 */
	public selected(event: MatAutocompleteSelectedEvent): void {
		const naupaCode: PropertyCodeCategory = event.option.value;

		if (!naupaCode?.id) {
			return;
		}

		this.selectedItems.set(naupaCode.id, naupaCode);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedItems.values()];

		this.searchControl.setValue('');
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.controlValue.forEach(val => this.selectedItems.set(val.id, val));
		}

		if (this.controlValue === null || this.controlValue.length === 0) {
			this.selectedItems.clear();
		}
	}
}
