import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { expand, reduce, scan } from 'rxjs/operators';

import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { PaginationOptionsMapper } from '../mappers/pagination-options.mapper';
import { PaginationOptions } from '../../models/pagination-options';

/**
 * Service to store helpers for server pagination.
 */
@Injectable({
	providedIn: 'root',
})
export class PaginationApiService {
	private readonly http = inject(HttpClient);

	private readonly paginationOptionsMapper = inject(PaginationOptionsMapper);

	/** Default pagination params. */
	public readonly defaultPaginationParams = this.paginationOptionsMapper.toDto(new PaginationOptions({
		pageSize: 10,
	}));

	/**
	 * Get all items from server using pagination API.
	 * We need this utility to get fetch all items using small chunks.
	 * It helps us to handle large queries which might stuck in some cases due to server timeout.
	 * @param request$ Request.
	 * @param shouldEmitEveryResponse Whether the observable should be triggered every time the queries are executed.
	 */
	public getAllItemsFromPagedList<TDto>(
		request$: Observable<PagedListDto<TDto>>,
		shouldEmitEveryResponse = false,
	): Observable<TDto[]> {
		const reducerFn = shouldEmitEveryResponse ? scan : reduce;

		return request$.pipe(
			expand(response => response.next ? this.http.get<PagedListDto<TDto>>(response.next) : EMPTY),
			reducerFn((acc: TDto[], items) => acc.concat(items.results ?? items), []),
		);
	}
}
