@if (currentUser$ | async; as user) {
	@if (showCellValue(user)) {
		<ng-content />
	} @else {
		<div class="lock-cell">
			<div>Tier {{ tier }} Access</div>
			<mat-icon
				class="lock-icon"
				svgIcon="lock-black"
			/>
		</div>
	}
}
