/** Holder type. */
export class HolderType {
	/** Holder type id. */
	public readonly id: number;

	/** Holder type name. */
	public readonly name: string;

	public constructor(data: HolderTypeConstructorData) {
		this.id = data.id;
		this.name = data.name;
	}

}

type HolderTypeConstructorData = HolderType;
