import { UserProfile } from '../models/user-profile';
import { UserType } from '../enums/user-type';

/**
 * Check user role and user tier with value tier to display data to him.
 * @param user User data.
 * @param valueTier Tier of value.
 */
export function checkUserAccess(user: UserProfile, valueTier: number): boolean {
	const isUserAdmin = user.userType === UserType.Admin;
	const checkUserAndValueTiers = user.appUserData?.currentAccessTier != null && user.appUserData.currentAccessTier.tier >= valueTier;
	return isUserAdmin || checkUserAndValueTiers;
}
