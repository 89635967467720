import { Injectable } from '@angular/core';

import { ReportingMechanicsFilters } from '../reporting-data.service';
import { ALL_OPTION_ID } from '../../utils/constants';

import { JurisdictionReportingTemplateFiltersDto } from './dto/jurisdiction-reporting-template-filters-dto';
import { IMapperToDto } from './mappers';

/** Jurisdiction reporting template filters mapper. */
@Injectable({ providedIn: 'root' })
export class JurisdictionReportingTemplateFiltersMapper implements
IMapperToDto<JurisdictionReportingTemplateFiltersDto, ReportingMechanicsFilters> {

	/** @inheritdoc */
	public toDto(data: ReportingMechanicsFilters): JurisdictionReportingTemplateFiltersDto {
		const params: JurisdictionReportingTemplateFiltersDto = {};

		const stateIds = data.states?.map(state => state.id).filter(id => id !== ALL_OPTION_ID);
		if (stateIds !== undefined && stateIds.length > 0) {
			params.id__in = stateIds.join(',');
		}

		return params;
	}
}
