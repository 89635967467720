import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';

/**
 * Custom table cell directive.
 * This directive is required for applying custom styles for base-table cells.
 */
@Directive({
	selector: '[scriptawCustomTableCell]',
})
export class CustomTableCellDirective implements OnInit {
	private readonly element = inject(ElementRef);

	/** Table cell class. */
	@Input()
	public cellClass = '';

	/** @inheritdoc */
	public ngOnInit(): void {
		const cellElement = this.element.nativeElement.parentElement as HTMLTableCellElement | null;
		if (cellElement && this.cellClass) {
			cellElement.classList.add(this.cellClass);
		}
	}
}
