import { MatrixSearchType } from '../enums/matrix-search-type';
import { SpecialMatrixType } from '../enums/special-matrix-type';

import { MatrixField, MatrixFieldEdit } from './matrix-field';

/** Matrix edit data. */
export type MatrixEdit = {

	/** Name of matrix. */
	readonly name: string;

	/** Matrix search type. */
	readonly searchType: MatrixSearchType;

	/** Matrix fields. */
	readonly fields: readonly MatrixFieldEdit[];
};

/** Impact matrix. */
export type ImpactMatrix = Pick<Matrix, 'id' | 'name' | 'searchType' | 'specialType'>;

/** Dormancy matrix. */
export type DormancyMatrix = ImpactMatrix;

/** Short matrix. */
export type ShortMatrix = Pick<Matrix, 'id' | 'name'>;

/** Matrix model. */
export class Matrix {
	/** Matrix id. */
	public readonly id: number;

	/** Matrix name. */
	public readonly name: string;

	/** Matrix search type. */
	public readonly searchType: MatrixSearchType;

	/** Matrix fields. */
	public readonly fields: MatrixField[];

	/** Special type. */
	public readonly specialType: SpecialMatrixType | null;

	/** NAUPA2 codes list. */
	public readonly coveredNaupa2Codes: readonly string[];

	public constructor(data: MatrixConstructorData) {
		this.id = data.id;
		this.name = data.name;
		this.fields = data.fields;
		this.searchType = data.searchType;
		this.specialType = data.specialType;
		this.coveredNaupa2Codes = data.coveredNaupa2Codes;
	}
}

/** Constructor data.  */
export type MatrixConstructorData = Matrix;
