/** IRA Distribution rule. */
export class IraDistributionRule {
	/** Rule id. */
	public readonly id: number;

	/** Surviving spouse. */
	public readonly survivingSpouse: string;

	/** Eligible designated bene. */
	public readonly eligibleDesignatedBene: string;

	/** Other relationship. */
	public readonly otherRelationship: string;

	/** Non individual. */
	public readonly nonIndividual: string;

	/** Non individual trust. */
	public readonly nonIndividualTrust: string;

	public constructor(data: IraDistributionRuleConstructorData) {
		this.id = data.id;
		this.survivingSpouse = data.survivingSpouse;
		this.eligibleDesignatedBene = data.eligibleDesignatedBene;
		this.otherRelationship = data.otherRelationship;
		this.nonIndividual = data.nonIndividual;
		this.nonIndividualTrust = data.nonIndividualTrust;
	}
}

type IraDistributionRuleConstructorData = IraDistributionRule;
