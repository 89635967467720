@if (loading$ | async) {
	<div class="progress-bar-container">
		<mat-progress-bar mode="indeterminate" />
	</div>
}

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
	<table
		mat-table
		class="full-width"
		[dataSource]="dataSource"
	>
		@for (col of columns; track col.name) {
			<ng-container [matColumnDef]="col.name">
				<th
					mat-header-cell
					*matHeaderCellDef
				>
					{{ col.headerText }}
				</th>
				<td
					mat-cell
					*matCellDef="let element"
				>
					@switch (col.name) {
						@case (titleColumnName) {
							<span class="title-cell">{{ element[col.name] }}</span>
						}
						@default {
							<div class="table-cell-text">
								{{ element[col.name] }}
							</div>
						}
					}
				</td>
			</ng-container>
		}

		<!-- Header row for title cell -->
		<ng-container matColumnDef="header-title">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="title-top-cell"
			></th>
		</ng-container>

		@if (iraDistributionRules$ | async) {
			<div></div>
		}

		@for (ira of iraDistributionRules$ | async; track ira.id) {
			<ng-container [matColumnDef]="'ira-col-' + ira.id">
				<th
					*matHeaderCellDef
					mat-header-cell
					[attr.colspan]="2"
				>
					<div class="table-header">
						<span>
							{{ getTableHeaderText(ira) }}
						</span>
						@if (showAdminActions) {
							<button
								mat-icon-button
								class="table-header-btn"
								type="button"
								title="Edit revision"
								(click)="editIraRule(ira)"
							>
								<mat-icon svgIcon="edit" />
							</button>
						}
					</div>
				</th>
			</ng-container>
		}

		<tr
			*matHeaderRowDef="headerColumns$ | async"
			mat-header-row
			[class.hidden]="dataSource.data.length === 0"
		></tr>

		<!-- Header cells -->
		<tr
			*matHeaderRowDef="columnNames"
			mat-header-row
			[class.hidden]="dataSource.data.length === 0"
		></tr>

		<tr
			*matRowDef="let row; columns: columnNames"
			mat-row
		></tr>

		<!-- Empty Table message -->
		<ng-container matColumnDef="noRecords">
			<td
				*matFooterCellDef
				mat-cell
				class="empty-table-message"
				[attr.colspan]="columns.length"
			>
				@if (loading$ | async) {
					<span>Loading data...</span>
				} @else {
					No item found.
				}
			</td>
		</ng-container>
		<tr
			*matFooterRowDef="['noRecords']"
			mat-footer-row
			[class.hidden]="dataSource.data.length > 0"
		></tr>
	</table>
</div>
