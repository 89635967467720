import { enumToArray } from '../utils/enum-to-array';

/** Types of priority rules. */
export enum PriorityRuleType {
	Default = 'DEFAULT_RULE',
	Federal = 'FEDERAL_RULE',
}

export namespace PriorityRuleType {
	const TITLE_MAP: Record<PriorityRuleType, string> = {
		[PriorityRuleType.Default]: 'Default Rule',
		[PriorityRuleType.Federal]: 'Federal Rule',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value?: PriorityRuleType | null): string {
		if (!value) {
			return '';
		}

		const readable = TITLE_MAP[value];
		if (readable === undefined) {
			console.warn(`Unknown Priority rule: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): PriorityRuleType[] {
		return enumToArray(PriorityRuleType);
	}
}
