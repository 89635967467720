import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { EditReportingTemplate } from '@scriptac/common/core/models/edit-reporting-template';
import { ReportingTemplate } from '@scriptac/common/core/models/reporting-template';
import { catchValidationData } from '@scriptac/common/core/rxjs/catch-validation-data';
import { FileStorageService } from '@scriptac/common/core/services/file-storage.service';
import { LocationService } from '@scriptac/common/core/services/location.service';
import { ReportingDataService } from '@scriptac/common/core/services/reporting-data.service';
import { assertNonNullablePropertiesWithReturn } from '@scriptac/common/core/utils/assert-non-null';
import { ControlsOf } from '@scriptac/common/core/utils/types/controls-of';
import { NullableFields } from '@scriptac/common/core/utils/types/nullable-fields';
import { S3FileUploadConfigType } from '@scriptac/common/core/enums/s3-file-upload-config-type';

type EditReportingTemplateFormControls = ControlsOf<
Pick<EditReportingTemplate, 'templateId' | 'templateUrl'> &
NullableFields<Pick<EditReportingTemplate, 'jurisdictionId'>> & {

	/** Template key. */
	readonly templateKey: string;
}
>;

/**
 * Reporting template edit data.
 */
export type ReportingTemplateEditData = {

	/** Reporting template. */
	readonly template: ReportingTemplate | null;
};

/**
 * Reporting template edit dialog.
 */
@Component({
	selector: 'scriptaw-reporting-template-edit-dialog',
	templateUrl: './reporting-template-edit-dialog.component.html',
	styleUrls: ['./reporting-template-edit-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingTemplateEditDialogComponent {
	private readonly data = inject<ReportingTemplateEditData>(MAT_DIALOG_DATA);

	private readonly dialogRef = inject<MatDialogRef<ReportingTemplateEditDialogComponent>>(MatDialogRef);

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly locationService = inject(LocationService);

	private readonly fileStorageService = inject(FileStorageService);

	private readonly reportingService = inject(ReportingDataService);

	/** Is edit mode. */
	public readonly isEdit = Boolean(this.data.template);

	/** Form. */
	public readonly form = this.fb.group<EditReportingTemplateFormControls>({
		templateId: this.fb.control(this.data.template?.id ?? null),
		jurisdictionId: this.fb.control(this.data.template?.state.id ?? null, Validators.required),
		templateUrl: this.fb.control(this.data.template?.templateUrl ?? '', Validators.required),
		templateKey: this.fb.control(this.data.template?.templateUrl ?? ''),
	});

	/** States. */
	public readonly states$ = this.locationService.states$;

	/**
	 * Handle form submit.
	 */
	public submitForm(): void {
		this.form.markAllAsTouched();

		if (this.form.invalid) {
			return;
		}

		const formValues = assertNonNullablePropertiesWithReturn(this.form.getRawValue(), 'jurisdictionId');

		const reportingTemplate: EditReportingTemplate = {
			jurisdictionId: formValues.jurisdictionId,
			templateId: formValues.templateId,
			templateUrl: formValues.templateKey,
		};

		this.reportingService.saveReportingTemplate(reportingTemplate).pipe(
			first(),
			catchValidationData(this.form),
		)
			.subscribe(template => this.dialogRef.close(template));
	}

	/**
	 * Save opened file.
	 * @param event Input event.
	 */
	public uploadFile(event: Event): void {
		const input = event.target as HTMLInputElement;
		const file = input.files?.[0];

		if (!file) {
			return;
		}

		this.fileStorageService.uploadFile(S3FileUploadConfigType.ReportingTemplates, file, file.name).pipe(
			first(),
		)
			.subscribe(({ key, url }) => {
				this.form.patchValue({
					templateUrl: url,
					templateKey: key,
				});
			});
	}

	/** Close dialog. */
	public onClose(): void {
		this.dialogRef.close();
	}
}
