import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
	Component,
	ChangeDetectionStrategy,
	ContentChildren,
	EventEmitter,
	Input,
	Output,
	QueryList,
	TrackByFunction,
	ViewChild,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

import { TableColumnInfo } from '@scriptac/common/core/models/column-info';
import { MatrixColumnInfo } from '@scriptac/common/core/models/matrix-column-info';
import { PaginationOptions } from '@scriptac/common/core/models/pagination-options';
import { resolvePath } from '@scriptac/common/core/utils/resolve-object-path';

import { TableColumnDirective } from '../../directives/table/table-column.directive';

/**
 * Advanced base table.
 */
@Component({
	selector: 'scriptaw-advanced-base-table',
	templateUrl: './advanced-base-table.component.html',
	styleUrls: ['./advanced-base-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedBaseTableComponent<T> {
	/** Loading indicator. */
	@Input()
	public loading: boolean | null = false;

	/** Whether align text in cells by left. */
	@Input()
	public alignTextLeft: boolean | null = false;

	/** Items to display. */
	@Input()
	public set rows(value: T[] | null) {
		if (value) {
			this.dataSource.data = value;
		}
	}

	/**
	 * Columns of matrix.
	 * @param columns Columns from parent component.
	 */
	@Input()
	public set columns(columns: MatrixColumnInfo[] | null) {
		this.columnsValue$.next(columns ?? []);
	}

	/** Get columns list. */
	public get columns(): MatrixColumnInfo[] {
		return this.columnsValue$.value.filter(col => col.shouldDisplay);
	}

	/** Pagination settings. */
	@Input()
	public pagination?: PaginationOptions | null;

	/** Sort settings. */
	@Input()
	public clickableRows: boolean | null = false;

	/** Is first column sticky. */
	@Input()
	public stickyFirstColumn: boolean | null = false;

	/** Whether to show fixed column. */
	@Input()
	public showFixedLastColumn: boolean | null = false;

	/**
	 * Fixed cell template.
	 */
	@ContentChild('fixedCellTemplate')
	public fixedCellTemplate: TemplateRef<unknown> | null = null;

	/** Message that displays when items not found. */
	@Input()
	public emptyMessage = 'No items found.';

	/** Function for trackBy.
	 * @param index Index.
	 */
	@Input()
	public trackBy: TrackByFunction<T> = (index: number) => index;

	/** Emitted when the item clicked. */
	@Output()
	public itemClick: EventEmitter<T> = new EventEmitter();

	/** Emitted when pagination changes. */
	@Output()
	public paginationChange: EventEmitter<PaginationOptions> = new EventEmitter();

	/** Emitted when columns data changed. */
	@Output()
	public readonly columnsUpdate = new EventEmitter();

	/** Table instance. */
	@ViewChild(MatTable)
	public table!: MatTable<T>;

	/** Columns templates. */
	@ContentChildren(TableColumnDirective)
	private columnTemplates?: QueryList<TableColumnDirective>;

	/** Internal representation of data source for support of native sorting feature. */
	public dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);

	/** Handle click on the specific item. */
	public get columnNames(): string[] {
		let columnsNames = this.columns?.map(c => c.name) ?? [];
		if (this.showFixedLastColumn) {
			columnsNames = columnsNames.concat(this.fixedColumnName);
		}
		return columnsNames;
	}

	/** Fixed column name. */
	public readonly fixedColumnName = 'fixed-column';

	private readonly columnsValue$ = new BehaviorSubject<MatrixColumnInfo[]>([]);

	/**
	 * Handle click on the specific item.
	 * @param item Item that was clicked.
	 */
	public onItemClick(item: T): void {
		if (this.clickableRows) {
			this.itemClick.emit(item);
		}
	}

	/**
	 * Get cell template by the name.
	 * @param name Column name.
	 */
	public getColumnByName(name: string): TableColumnDirective | undefined {
		return this.columnTemplates?.find(column => column.name === name);
	}

	/**
	 * Paginator changed.
	 * @param page Page event.
	 */
	public paginationChanged(page: PageEvent): void {
		this.paginationChange.emit(new PaginationOptions({
			page: page.pageIndex,
			pageSize: page.pageSize,
			totalCount: page.length,
		}));
	}

	/**
	 * Get table column header text (only used for default header).
	 * @param column Column info.
	 * @param textFromDirective Column header text from TableColumn directive.
	 */
	public getTableHeaderText(column: TableColumnInfo, textFromDirective: string): string {
		const textFromInfo = column.headerText ?? column.name ?? '';
		return textFromDirective ?? textFromInfo;
	}

	/**
	 * Get table cell classes.
	 * @param column Column info.
	 * @param element Column element.
	 * @param colName Column name.
	 */
	public getTableCellClass(
		column: TableColumnDirective,
		element: unknown,
		colName: string,
	): string | string[] {
		return column?.cellClassesGetter ? column.cellClassesGetter(element, colName) : '';
	}

	/**
	 * Get object value by key.
	 * @param obj Object.
	 * @param key Key.
	 */
	public getValue(obj: Record<string, unknown>, key: string): unknown {
		return resolvePath(key, obj);
	}

	/**
	 * Handler for element dropped after drag.
	 * As some columns can be hidden, we map swap indexes from displayed list to list with all options.
	 * @param event Event data.
	 */
	public dropListDropped(event: CdkDragDrop<T, T>): void {
		if (event && this.columns) {
			const firstElem = this.columns[event.previousIndex];
			const secondElem = this.columns[event.currentIndex];
			const allColumns = this.columnsValue$.value;
			const indexOfFirst = allColumns.findIndex(elem => firstElem.name === elem.name);
			const indexOfSecond = allColumns.findIndex(elem => secondElem.name === elem.name);
			const cols = [...allColumns];
			moveItemInArray(cols, indexOfFirst, indexOfSecond);
			this.updateColumns(cols);
		}
	}

	/**
	 * Update column width in columns width and emit values to parent.
	 * @param width Updated width.
	 * @param column Column to update.
	 */
	public updateColumnWidth(width: number, column: MatrixColumnInfo): void {
		const updatedColumn = { ...column, width };

		const updatedColumns = this.columnsValue$.value?.map(col => {
			if (col.name === column.name) {
				return updatedColumn;
			}
			return col;
		}) ?? null;

		this.updateColumns(updatedColumns);
	}

	private updateColumns(columns: MatrixColumnInfo[]): void {
		this.columns = columns;
		this.columnsUpdate.emit(columns);
	}
}
