@if (field$ | async; as field) {
	<h3 class="field-name">
		{{ field.name }}
	</h3>
	<div class="selection-list custom-select-list">
		@if (isSingleSelect) {
			<mat-radio-group
				[value]="selectFormControl.value[0]"
				(change)="selectFormControl.setValue([$event.value])"
			>
				@for (option of field.choices; track option.id) {
					<div>
						<mat-radio-button
							class="example-radio-button"
							[value]="option.id"
						>
							<mat-option
								class="hidden-option"
								[value]="option.id"
							/>
							<div class="selection-list-option">
								<div class="selection-list-option__text">
									<quill-view
										class="small-quill"
										[content]="option.name"
									/>
									@if (getRelatedRevisionTitles(field, option); as titles) {
										<div class="selection-list-option__revisions">Used in: {{ titles }}</div>
									}
								</div>
								@if (checkIsNewChoice(option)) {
									<span class="selection-list-option__new-indicator">new</span>
								}
							</div>
						</mat-radio-button>
					</div>
				}
			</mat-radio-group>
		} @else {
			@for (option of field.choices; track option.id) {
				<div>
					<mat-checkbox
						[checked]="selectFormControl.value.includes(option.id)"
						(change)="handleMultiSelect($event, option)"
					>
						<mat-option
							class="hidden-option"
							[value]="option.id"
						/>
						<div class="selection-list-option">
							<div class="selection-list-option__text">
								<quill-view
									class="small-quill"
									[content]="option.name"
								/>
								@if (getRelatedRevisionTitles(field, option); as titles) {
									<div class="selection-list-option__revisions">Used in: {{ titles }}</div>
								}
							</div>
							@if (checkIsNewChoice(option)) {
								<span class="selection-list-option__new-indicator">new</span>
							}
						</div>
					</mat-checkbox>
				</div>
			}
		}
	</div>
}

<p class="enter-value-message">Enter new value:</p>
<div class="progress-container">
	@if (loading$ | async) {
		<mat-progress-bar mode="indeterminate" />
	}
</div>
<div class="editor-wrapper">
	<quill-editor
		#editor
		class="text-editor"
		[modules]="editorOptions"
		[formControl]="newValueFormControl"
		(click)="editor.quillEditor.focus()"
	/>
	<button
		class="auto-height"
		mat-button
		type="button"
		[disabled]="loading$ | async"
		(click)="saveNewValue()"
	>
		Add
	</button>
</div>
<scriptac-form-control-validation-message [control]="newValueFormControl" />

<div class="action-buttons">
	<button
		mat-button
		type="button"
		[disabled]="loading$ | async"
		(click)="closeDialog()"
	>
		Close
	</button>
</div>
