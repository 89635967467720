import { Injectable } from '@angular/core';

import { Naupa2CodesWithDescriptionFilters } from '../../models/naupa2-code-filters';
import { stringifyListByProp } from '../../utils/stringifyListByProp';

import { Naupa2CodesWithDescriptionFiltersDto } from './dto/naupa2-code-with-description-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for Naupa 2 Codes filters. */
@Injectable({ providedIn: 'root' })
export class Naupa2CodesWithDescriptionFiltersMapper implements
  IMapperToDto<Naupa2CodesWithDescriptionFiltersDto, Naupa2CodesWithDescriptionFilters> {

	/** @inheritdoc */
	public toDto(data: Naupa2CodesWithDescriptionFilters): Naupa2CodesWithDescriptionFiltersDto {
		const params: Naupa2CodesWithDescriptionFiltersDto = {};

		if (data.naupa2Codes && data.naupa2Codes.length > 0) {
			params.naupa2_code__in = stringifyListByProp(data.naupa2Codes, 'id');
		}

		if (data.categories && data.categories.length > 0) {
			params.naupa2_code__category__in = stringifyListByProp(data.categories, 'id');
		}

		return params;
	}
}
