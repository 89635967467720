import { State } from './state';

/**
 * Reporting template.
 */
export class ReportingTemplate {
	/** Template id. */
	public readonly id: number;

	/** Jurisdiction id. */
	public readonly state: State;

	/**
	 * Reporting template URL.
	 * 'null' is possible if user cannot access template due to tier access restrictions.
	 */
	public readonly templateUrl: string | null;

	public constructor(data: ReportingTemplateInitArgs) {
		this.id = data.id;
		this.state = data.state;
		this.templateUrl = data.templateUrl;
	}
}

type ReportingTemplateInitArgs = ReportingTemplate;
