<div class="progress-bar-container">
	@if (loading) {
		<mat-progress-bar mode="indeterminate" />
	}
</div>

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
	<table
		mat-table
		matSort
		class="full-width"
		[trackBy]="trackBy"
		[dataSource]="dataSource"
		[matSortDirection]="sort?.direction ?? ''"
		[matSortActive]="sort?.field ?? ''"
		(matSortChange)="onSortChange($event)"
	>
		@for (col of columns; track col.name) {
			<ng-container
				[matColumnDef]="col.name"
				[stickyEnd]="col.isSticky"
			>
				@let column = getColumnByName(col.name);

				<!-- HEADER FOR THE CELL -->
				<th
					*matHeaderCellDef
					mat-header-cell
					class="fixed-header-cell"
					[disabled]="!col.sort"
					[mat-sort-header]="col.sort || ''"
					[ngStyle]="getCellStyles(col)"
				>
					@if (column) {
						<ng-container
							[ngTemplateOutletContext]="{ $implicit: col, headerText: column.headerText }"
							[ngTemplateOutlet]="column.header?.template || defaultHeaderTemplate"
						/>
					} @else {
						<ng-container
							[ngTemplateOutletContext]="{ $implicit: col }"
							[ngTemplateOutlet]="defaultHeaderTemplate"
						/>
					}
					<!-- If not found column template -->
				</th>

				<!-- BODY FOR THE CELL -->
				<td
					*matCellDef="let element"
					mat-cell
					[ngClass]="column ? getTableCellClass(column, element, col.name) : ''"
					[ngStyle]="getCellStyles(col)"
				>
					<!-- If found column template -->
					@if (column) {
						<ng-container
							[ngTemplateOutletContext]="{ $implicit: element, col: col.name }"
							[ngTemplateOutlet]="column.cell?.template || defaultCellTemplate"
						/>
					} @else {
						<ng-container
							[ngTemplateOutletContext]="{ $implicit: element, col: col.name }"
							[ngTemplateOutlet]="defaultCellTemplate"
						/>
					}
					<!-- If not found column template -->
				</td>
			</ng-container>
		}

		<!-- Header cells -->
		<tr
			*matHeaderRowDef="columnNames; sticky: true"
			mat-header-row
		></tr>

		<tr
			*matRowDef="let row; columns: columnNames"
			mat-row
			[ngClass]="customRowClass(row)"
		></tr>

		<!-- Empty Table message -->
		<ng-container matColumnDef="noRecords">
			<td
				*matFooterCellDef
				mat-cell
				class="empty-table-message"
				[attr.colspan]="columns.length"
			>
				{{ loading ? "Loading..." : emptyMessage }}
			</td>
		</ng-container>
		<tr
			*matFooterRowDef="['noRecords']"
			mat-footer-row
			[class.hidden]="dataSource.data.length > 0"
		></tr>
	</table>

	<!-- If not found cell template -->
	<ng-template
		#defaultCellTemplate
		let-row
		let-col="col"
	>
		<div [title]="row[col] || ''">
			{{ row[col] || "" }}
		</div>
	</ng-template>

	<!-- If not found header template -->
	<ng-template
		#defaultHeaderTemplate
		let-col
		let-headerText="headerText"
	>
		{{ getTableHeaderText(col, headerText) }}
	</ng-template>
</div>

@if (pagination) {
	<mat-paginator
		color="primary"
		[pageSize]="pagination.pageSize"
		[pageIndex]="pagination.page"
		[length]="pagination.totalCount"
		[hidePageSize]="true"
		(page)="paginationChanged($event)"
	/>
}
