/**
 * Check two sets for equality.
 * @param setA First Set.
 * @param setB Second Set.
 */
export function equalSet<T>(setA: Set<T>, setB: Set<T>): boolean {
	if (setA.size !== setB.size) {
		return false;
	}
	for (const elem of setA) {
		if (!setB.has(elem)) {
			return false;
		}
	}
	return true;
}
