import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatrixSearchType } from '../../enums/matrix-search-type';
import { FetchListOptions } from '../../models/fetch-list-options';
import { Matrix, MatrixEdit } from '../../models/matrix';
import { MatrixField, MatrixFieldEdit } from '../../models/matrix-field';
import { Naupa2Code } from '../../models/naupa-2-code';
import { PagedList } from '../../models/paged-list';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { MatrixDto } from '../mappers/dto/matrix-dto';
import { MatrixFieldDto } from '../mappers/dto/matrix-fields-dto';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { MatrixFieldMapper } from '../mappers/matrix-field-mapper';
import { MatrixFiltersMapper } from '../mappers/matrix-filters.mapper';
import { MatrixMapper } from '../mappers/matrix.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';

/** Matrix filtering options. */
export type MatrixFilters = {

	/** Search data. */
	readonly search?: string;

	/** Search type. */
	readonly type?: MatrixSearchType;

	/** Naupa 2 codes. */
	readonly naupa2Codes?: Naupa2Code[];

	/** Is archived. */
	readonly isArchived?: boolean;
};

/** API service for matrix. */
@Injectable({ providedIn: 'root' })
export class MatrixApiService {
	private readonly config = inject(AppConfigService);

	private readonly http = inject(HttpClient);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly matrixMapper = inject(MatrixMapper);

	private readonly matrixFieldMapper = inject(MatrixFieldMapper);

	private readonly matrixFiltersMapper = inject(MatrixFiltersMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly listMapper = inject(PagedListMapper);

	private readonly matrixUrl = new URL('laws/matrix/', this.config.apiUrl).toString();

	/**
	 * Create matrix.
	 *
	 * @param data Creation data.
	 */
	public createMatrix(data: MatrixEdit): Observable<Matrix> {
		const dto = this.matrixMapper.toDto(data);

		return this.http.post<MatrixDto>(this.matrixUrl, dto).pipe(
			map(matrixDto => this.matrixMapper.fromDto(matrixDto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.matrixMapper),
		);
	}

	/**
	 * Update matrix data by id.
	 *
	 * @param id Matrix ID.
	 * @param matrix Matrix edit data.
	 */
	public updateMatrixById(id: number, matrix: MatrixEdit): Observable<Matrix> {
		const url = new URL(`${id}/`, this.matrixUrl).toString();
		const matrixDto = this.matrixMapper.toDto(matrix);

		return this.http.put<MatrixDto>(url, matrixDto).pipe(
			map(dto => this.matrixMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.matrixMapper),
		);
	}

	/**
	 * Update matrix field by id.
	 * @param id Matrix ID.
	 * @param field Field edit data.
	 */
	public updateMatrixField(id: number, field: MatrixFieldEdit): Observable<MatrixField> {
		const url = new URL(`laws/field/${id}/`, this.config.apiUrl).toString();
		const fieldDto = this.matrixFieldMapper.toDto(field);

		return this.http.put<MatrixFieldDto>(url, fieldDto).pipe(
			map(dto => this.matrixFieldMapper.fromDto(dto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.matrixFieldMapper),
		);
	}

	/**
	 * Get paged matrix list.
	 *
	 * @param options List options.
	 */
	public getMatrixPagedList(options: FetchListOptions): Observable<PagedList<Matrix>> {
		const params = this.paramsMapper.toDto(options, this.matrixFiltersMapper);

		return this.http
			.get<PagedListDto<MatrixDto>>(this.matrixUrl, { params })
			.pipe(
				map(response => this.listMapper.fromDto(response, this.matrixMapper, options.pagination)),
			);
	}

	/**
	 * Get matrix by id.
	 *
	 * @param id Matrix id.
	 */
	public getMatrixById(id: number): Observable<Matrix> {
		const url = new URL(`${id}/`, this.matrixUrl).toString();

		return this.http.get<MatrixDto>(url).pipe(
			map(dto => this.matrixMapper.fromDto(dto)),
		);
	}

	/**
	 * Archive matrix by id.
	 * @param id Matrix id.
	 */
	public archiveMatrix(id: number): Observable<void> {
		const url = new URL(`${id}/archive/`, this.matrixUrl).toString();

		return this.http.post<MatrixDto>(url, null).pipe(
			map(() => undefined),
		);
	}
}
