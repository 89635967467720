import { enumToArray } from '../utils/enum-to-array';

/** Type of reason for change revision. */
export enum ReasonForChange {

	/** Statutory reason for change. */
	Statutory = 'STATUTORY',

	/** Regulatory reason for change. */
	Regulatory = 'REGULATORY',

	/** Guidance reason for change. */
	Guidance = 'GUIDANCE',

	/** Interpretation reason for change. */
	Interpretation = 'INTERPRETATION',

	/** Not included search type (used for matrixes with special type). */
	NotIncluded = 'NOT_INCLUDED',
}

export namespace ReasonForChange {

	const TITLE_MAP: Record<ReasonForChange, string> = {
		[ReasonForChange.Statutory]: 'Statutory',
		[ReasonForChange.Regulatory]: 'Regulatory',
		[ReasonForChange.Guidance]: 'Guidance',
		[ReasonForChange.Interpretation]: 'Interpretation',
		[ReasonForChange.NotIncluded]: '',
	};

	/**
	 * Convert a certain enum value to readable title.
	 * @param value Value of enum.
	 */
	export function toReadable(value: ReasonForChange): string {
		const readable = TITLE_MAP[value];
		if (readable == null) {
			console.warn(`Unknown type: ${value}`);
		}
		return readable;
	}

	/**
	 * Get list of all enum items.
	 */
	export function toArray(): ReasonForChange[] {
		return enumToArray(ReasonForChange).filter(type => type !== ReasonForChange.NotIncluded);
	}
}
