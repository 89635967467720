import { Component, ChangeDetectionStrategy, ViewChild, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { first, skip, tap } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';

import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';
import { RevisionStatus } from '@scriptac/common/core/enums/revision-status';
import { Law } from '@scriptac/common/core/models/law';
import { Revision, RevisionEdit } from '@scriptac/common/core/models/revision';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import {
	MatrixRevisionFormUtilsService,
	MatrixValueEditFormGroup,
	RevisionParamsFormGroup,
} from '@scriptac/common/core/services/forms/matrix-revision-form-utils.service';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { RevisionApiService } from '@scriptac/common/core/services/api/revision-api.service';
import { fillFormWithError } from '@scriptac/common/core/rxjs/catch-validation-data';
import { catchValidationError } from '@scriptac/common/core/rxjs/catch-validation-error';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { getLawMatrixRevisionTitle } from '@scriptac/common/core/utils/get-law-matrix-revision-title';
import { filterNull, onMessageOrFailed } from '@scriptac/common/core/rxjs/public_api';
import { MatrixField } from '@scriptac/common/core/models/matrix-field';
import { ReasonForChange } from '@scriptac/common/core/enums/reason-for-change';
import { MatrixFieldChoice } from '@scriptac/common/core/models/matrix-field-choice';

import { MatrixChoiceFieldValueSelectDialogComponent, MatrixChoiceFieldValueSelectDialogData, MatrixChoiceFieldValueSelectDialogResult } from './components/matrix-choice-field-value-select-dialog/matrix-choice-field-value-select-dialog.component';

/** Dialog data. */
export type AddRevisionDialogData = {

	/** Current law. */
	readonly law: Law;

	/** Revision data. */
	readonly revision: Revision | null;

	/** Matrix data. */
	readonly matrixFields?: MatrixField[];

	/** Other revisions which might be used as template for new revision. */
	readonly templateRevisions?: Revision[];
};

/** Dialog result. */
export type MatrixRevisionDialogResult = {

	/** New/updated revision. */
	readonly revision?: Revision;

	/** Should reload matrix (should be 'true' if new fields were added). */
	readonly shouldReloadMatrix: boolean;
};

/** Dialog for edit matrix revision. */
@Component({
	selector: 'scriptaw-matrix-revision-dialog',
	templateUrl: './matrix-revision-dialog.component.html',
	styleUrls: ['./matrix-revision-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class MatrixRevisionDialogComponent implements OnInit {

	private readonly dialogRef = inject<MatDialogRef<MatrixRevisionDialogComponent, MatrixRevisionDialogResult>>(MatDialogRef);

	private readonly formUtilsService = inject(MatrixRevisionFormUtilsService);

	private readonly revisionApiService = inject(RevisionApiService);

	private readonly notificationService = inject(NotificationService);

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly dialogService = inject(MatDialog);

	/** Dialog data. */
	protected readonly data = inject<AddRevisionDialogData>(MAT_DIALOG_DATA);

	/** Revision params form. */
	public readonly revisionEditForm = this.formUtilsService.createRevisionEditForm({
		law: this.data.law,
		revision: this.data.revision,
		matrixFields: this.data.matrixFields,
	});

	/** Loading flag. */
	public readonly isLoading$ = new BehaviorSubject(false);

	/** Matrix field type. */
	public readonly matrixFieldType = MatrixFieldType;

	/** Revision statuses. */
	public readonly revisionStatus = RevisionStatus;

	/** Reason for change. */
	public readonly reasonForChange = ReasonForChange;

	/** Revision templates. */
	public readonly revisionTemplates = this.data.templateRevisions;

	/** Revision template control. */
	public readonly revisionTemplateControl = this.fb.control<Revision | null>(null);

	/** Min value for till date according to since value. */
	public get minTillDate(): Date | null {
		const { effectiveSince } = this.revisionParamsForm.value;
		return effectiveSince ?? null;
	}

	/** Max value for since date according to till value. */
	public get maxSinceDate(): Date | null {
		const { effectiveTill } = this.revisionParamsForm.value;
		return effectiveTill ?? null;
	}

	/** Revision values array. */
	public get revisionValuesFormControls(): MatrixValueEditFormGroup[] {
		return this.revisionEditForm.controls.values.controls;
	}

	/** Revision params form. */
	public get revisionParamsForm(): RevisionParamsFormGroup {
		return this.revisionEditForm.controls.params;
	}

	/**
	 * After we've added new values to matrix fields using 'openFieldEditDialog' we should add updated field here.
	 * When dialog will be closed we should create 'fake' revision update to trigger data reload.
	 */
	private readonly updatedFieldsMap = new Map<number, MatrixField>();

	/** Stepper. */
	@ViewChild(MatStepper)
	public stepper?: MatStepper;

	public constructor() {
		this.dialogRef.disableClose = true;
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		listenControlChanges<Revision | null>(this.revisionTemplateControl).pipe(
			skip(1),
			takeUntilDestroy(this),
		)
			.subscribe(revision => {
				const newFormValues = this.formUtilsService.createRevisionEditForm({
					law: this.data.law,
					matrixFields: this.data.matrixFields,
					revision,
				});
				this.revisionEditForm.patchValue({
					values: newFormValues.value.values,
				});
			});
	}

	/**
	 * Handle confirm click.
	 */
	public saveRevision(): void {
		this.revisionEditForm.controls.values.markAllAsTouched();
		if (this.revisionEditForm.invalid) {
			return;
		}

		const updatedData = this.formUtilsService.getRevisionEditValue(
			this.revisionEditForm.getRawValue(),
			this.data.revision,
		);

		const request$ = this.data.revision?.id ?
			this.updateRevision(this.data.revision.id, updatedData) :
			this.createRevision(updatedData);

		this.isLoading$.next(true);
		request$.pipe(
			first(),
			onMessageOrFailed(() => this.isLoading$.next(false)),
			catchValidationError(errors => {
				fillFormWithError(this.revisionEditForm, errors.validationData);
				if (this.revisionParamsForm.invalid) {
					this.stepper?.previous();
				}
				return EMPTY;
			}),
		).subscribe(revision => this.onClose(revision));
	}

	/**
	 * Create new revision.
	 * @param data Revision edit data.
	 */
	public createRevision(data: RevisionEdit): Observable<Revision> {
		return this.revisionApiService.createRevision(data).pipe(
			tap(() => this.notificationService.showSuccess(`Revision successfully created`)),
		);
	}

	/**
	 * Edit revision.
	 * @param id Revision id.
	 * @param data Revision edit data.
	 */
	public updateRevision(id: number, data: RevisionEdit): Observable<Revision> {
		return this.revisionApiService
			.updateRevisionById(id, data)
			.pipe(
				tap(() => this.notificationService.showSuccess(`Revision successfully updated`)),
			);
	}

	/**
	 * Open edit field dialog.
	 * @param field Field.
	 */
	public openFieldEditDialog(field: MatrixField): void {
		// Find form control for the field.
		const control = this.revisionValuesFormControls.find(v => v.getRawValue().field.id === field.id);

		if (control === undefined) {
			return;
		}

		const controlValue = control.value;
		let selectedValues: MatrixFieldChoice[] = [];

		if (field.fieldType === MatrixFieldType.SingleChoice) {
			selectedValues = controlValue.singleChoiceValue ? [controlValue.singleChoiceValue] : [];
		} else if (field.fieldType === MatrixFieldType.MultiChoice) {
			selectedValues = controlValue.multipleChoicesValues ?? [];
		} else {
			return;
		}

		const dialogRef = this.dialogService.open<MatrixChoiceFieldValueSelectDialogComponent,
		MatrixChoiceFieldValueSelectDialogData, MatrixChoiceFieldValueSelectDialogResult>(
			MatrixChoiceFieldValueSelectDialogComponent, {
				width: '100%',
				autoFocus: false,
				disableClose: true,
				data: {
					law: this.data.law,
					revision: this.data.revision,
					field,
					selectedValues,
				},
			},
		);

		dialogRef.afterClosed().pipe(
			filterNull(),
		)
			.subscribe(result => {
				this.updatedFieldsMap.set(result.updatedField.id, result.updatedField);
				if (field.fieldType === MatrixFieldType.SingleChoice) {
					control.patchValue({
						singleChoiceValue: result.selectedValues[0],
						field: result.updatedField,
					});
				} else if (field.fieldType === MatrixFieldType.MultiChoice) {
					control.patchValue({
						multipleChoicesValues: [...result.selectedValues],
						field: result.updatedField,
					});
				}
			});
	}

	/**
	 * Check revision params validity.
	 */
	public checkRevisionParams(): void {
		this.revisionParamsForm.markAllAsTouched();
	}

	/**
	 * Handle dialog close.
	 * @param revision Revision.
	 */
	public onClose(revision?: Revision): void {
		this.dialogRef.close({
			revision,
			shouldReloadMatrix: this.updatedFieldsMap.size > 0,
		});
	}

	/**
	 * Get revision title.
	 * @param revision Revision.
	 * @param list Revisions list.
	 */
	public getRevisionTitle(revision: Revision, list: Revision[]): string {
		return getLawMatrixRevisionTitle(revision, list);
	}
}
