import { NULL_OBJECT_ID } from '../utils/constants';

import { NullObject } from './null-object';

/** Region model. */
export class Region {
	/** Region id. */
	public readonly id: number;

	/** Region name. */
	public readonly name: string;

	/** Region geoname code. */
	public readonly geonameCode?: string;

	public constructor(data: RegionConstructorData) {
		this.id = data.id;
		this.name = data.name;
		this.geonameCode = data.geonameCode;
	}
}

/** Nullable region data. */
export class NullRegion extends Region implements NullObject {
	/** Is null check. */
	public readonly isNull: boolean = true;

	public constructor(
		data: RegionConstructorData = {
			id: NULL_OBJECT_ID,
			name: '',
		},
	) {
		super(data);
	}
}

type RegionConstructorData = Region;
