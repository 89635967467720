import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Company, CompanyEditData } from '../../models/company';

import { AccessTierMapper } from './access-tier.mapper';
import { AppUserMapper } from './app-user.mapper';
import { CityMapper } from './city.mapper';
import { CompanyDto, CompanyEditDto } from './dto/company-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for company. */
@Injectable({ providedIn: 'root' })
export class CompanyMapper
implements
    IMapperFromDto<CompanyDto, Company>,
    IMapperToDto<CompanyEditDto, CompanyEditData>,
    IValidationErrorMapper<CompanyEditDto, CompanyEditData> {
	private readonly appUserMapper = inject(AppUserMapper);

	private readonly cityMapper = inject(CityMapper);

	private readonly accessTierMapper = inject(AccessTierMapper);

	/** @inheritdoc */
	public toDto(data: CompanyEditData): CompanyEditDto {
		return {
			name: data.name,
			owner: data.owner ?? null,
			owner_creation_data: data.ownerData && data.owner == null ? {
				first_name: data.ownerData.firstName,
				last_name: data.ownerData.lastName,
				email: data.ownerData.email,
				phone: data.ownerData.phone,
			} : undefined,
			city: data.cityData.id,
			access_tier: this.accessTierMapper.toDto(data.accessTier),
			address: data.address,
			zipcode: data.zipcode,
			company_users: data.usersData.map(u => u.id),
			is_active: data.isActive,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: CompanyDto): Company {
		return new Company({
			id: dto.id,
			name: dto.name,
			owner: dto.owner,
			isActive: dto.is_active,
			ownerData: dto.owner_data ? this.appUserMapper.fromDto(dto.owner_data) : undefined,
			city: dto.city,
			cityData: this.cityMapper.fromDto(dto.city_data),
			accessTier: this.accessTierMapper.fromDto(dto.access_tier),
			address: dto.address,
			zipcode: dto.zipcode,
			users: dto.company_users,
			usersData: dto.company_users_data.map(user => ({
				id: user.id,
				firstName: user.first_name,
				lastName: user.last_name,
				email: user.email,
				phone: user.phone,
			})),
		});
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<CompanyEditDto>,
	): EntityValidationErrors<CompanyEditData> {
		return {
			name: extractErrorMessageByErrorKey(errorDto, 'name'),
			address: extractErrorMessageByErrorKey(errorDto, 'address'),
			accessTier: extractErrorMessageByErrorKey(errorDto, 'access_tier'),
			cityData: extractErrorMessageByErrorKey(errorDto, 'city'),
			zipcode: extractErrorMessageByErrorKey(errorDto, 'zipcode'),
			usersData: extractErrorMessageByErrorKey(errorDto, 'company_users'),
			newOwner: {
				firstName: extractErrorMessageByErrorKey(errorDto, 'owner_creation_data.first_name'),
				lastName: extractErrorMessageByErrorKey(errorDto, 'owner_creation_data.last_name'),
				email: extractErrorMessageByErrorKey(errorDto, 'owner_creation_data.email'),
				phone: extractErrorMessageByErrorKey(errorDto, 'owner_creation_data.phone'),
			},
		};
	}
}
