<div class="control-container">
	<mat-chip-grid
		multiple
		#chipList
	>
		@for (category of selectedItems | keyvalue; track category.key) {
			<mat-chip-row
				(removed)="remove(category.value)"
				class="selected-option"
			>
				{{ category.value.code }}
				<mat-icon
					matChipRemove
					class="cancel-icon"
				>
					cancel
				</mat-icon>
			</mat-chip-row>
		}
		<input
			matInput
			class="autocomplete-input"
			#trigger="matAutocompleteTrigger"
			[formControl]="searchControl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[placeholder]="placeholder"
			(click)="trigger.openPanel()"
		/>
		<mat-icon
			matSuffix
			class="dropdown-arrow"
		>
			arrow_drop_down
		</mat-icon>
	</mat-chip-grid>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="selected($event)"
	>
		<div
			infiniteScroll
			class="infinite-scroll"
			[scrollWindow]="false"
			(scrolled)="listManager.nextPage()"
		>
			@let codes = codes$ | async;
			@let isLoading = listManager.listLoading$ | async;

			@if (isLoading) {
				<mat-progress-bar mode="indeterminate" />
			}

			@if (!codes?.length && !isLoading) {
				<mat-option>Not found</mat-option>
			}

			@for (category of codes; track category.id) {
				<mat-option [value]="category">{{ category.code }} ({{ category.name }})</mat-option>
			}
		</div>
	</mat-autocomplete>
</div>
