import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IraDistribution } from '../models/ira-distribution';

import { AppConfigService } from './app-config.service';
import { IraDistributionDto } from './mappers/dto/ira-distribution-dto';
import { IraDistributionMapper } from './mappers/ira-distribution.mapper';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { PaginationApiService } from './api/pagination-api.service';

/**
 * IRA Distribution rules service.
 */
@Injectable({
	providedIn: 'root',
})
export class IraDistributionRulesService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly iraDistributionMapper = inject(IraDistributionMapper);

	private readonly paginationService = inject(PaginationApiService);

	private readonly iraDistributionUrl = new URL('laws/ira-distribution/', this.config.apiUrl).toString();

	/**
	 * Get list of IRA distributions.
	 */
	public getIraDistributions(): Observable<IraDistribution[]> {
		return this.paginationService.getAllItemsFromPagedList(
			this.http.get<PagedListDto<IraDistributionDto>>(this.iraDistributionUrl),
		).pipe(
			map(response => response.map(distribution => this.iraDistributionMapper.fromDto(distribution))),
		);
	}

	/**
	 * Get IRA distribution by id.
	 * @param id Id.
	 */
	public getIraDistributionById(id: number): Observable<IraDistribution> {
		const url = new URL(`${id}/`, this.iraDistributionUrl).toString();
		return this.http
			.get<IraDistributionDto>(url)
			.pipe(
				map(dto => this.iraDistributionMapper.fromDto(dto)),
			);
	}

	/**
	 * Save IRA distribution.
	 * @param ira IRA Distribution.
	 */
	public saveIraDistribution(ira: IraDistribution): Observable<IraDistribution> {
		const url = new URL(`${ira.id}/`, this.iraDistributionUrl).toString();
		const dto = this.iraDistributionMapper.toDto(ira);

		return this.http
			.put<IraDistributionDto>(url, dto)
			.pipe(
				map(response => this.iraDistributionMapper.fromDto(response)),
			);
	}
}
