import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { VerticalMatrixElement } from '@scriptac/common/core/models/vertical-matrix-element';
import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';

/**
 * Type for transposed row (same field types from different columns).
 * For example, { columnName1: cellValeFromColumn1, columnName2: cellValeFromColumn2, ...}.
 */
export type TransposeRowType = Record<string, MatrixValue | null | string>;

/** Row info. */
export type RowInfo = {

	/** Field name for current row. */
	readonly fieldName: string;

	/** Field type for current row. */
	readonly fieldType: MatrixFieldType;
};

/** Column data. */
export type ColumnInfo<T> = {

	/** Column name for material. */
	readonly name: string;

	/** Column header text. */
	readonly headerText: string;

	/** Column data. */
	readonly data: T | null;
};

/** Table with header as a first column. */
@Component({
	selector: 'scriptaw-vertical-matrix',
	templateUrl: './vertical-matrix.component.html',
	styleUrls: ['./vertical-matrix.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalMatrixComponent<T extends VerticalMatrixElement> {
	private readonly userService = inject(CurrentUserService);

	private matrixDataValue: T[] = [];

	/** Column name with titles. */
	public titleColumnName = 'title';

	/** Internal representation of data source. */
	public readonly dataSource: MatTableDataSource<TransposeRowType> = new MatTableDataSource<TransposeRowType>([]);

	/**
	 * Header cell template.
	 */
	@ContentChild('headerCellTemplate')
	public headerCellTemplate: TemplateRef<unknown> | null = null;

	/** Loading indicator. */
	@Input()
	public loading: boolean | null = false;

	/** Message that displays when items not found. */
	@Input()
	public emptyMessage = 'No items found.';

	/** Matrix API data. */
	@Input()
	public set matrixData(value: T[] | null) {
		if (value) {
			this.matrixDataValue = value ?? [];
			this.dataSource.data = this.transposeMatrixData(value);
		}
	}

	/** Current user data. */
	public currentUser$ = this.userService.currentUser$;

	/** Return columns names. */
	public get columnNames(): string[] {
		return this.columns.map(c => c.name);
	}

	/** Return rows names. */
	public get rowsNames(): RowInfo[] {
		return this.matrixDataValue.length ?
			this.matrixDataValue[0].values.map(value => ({ fieldName: value.field.name, fieldType: value.field.fieldType })) : [];
	}

	/** Columns information. */
	public get columns(): ColumnInfo<T>[] {
		const columnsFromData = this.matrixDataValue.map((elem: T) => ({
			name: elem.id.toString(),
			headerText: elem.id.toString(),
			data: elem,
		}));
		const columnWithTitles: ColumnInfo<T> = {
			name: 'title',
			headerText: '',
			data: null,
		};

		return [columnWithTitles, ...columnsFromData];
	}

	/**
	 * Get cell tier from matrix data.
	 * @param col Column info.
	 * @param row Row data.
	 */
	public getCellTier(col: ColumnInfo<T>, row: TransposeRowType): number {
		const foundCellValue = col.data?.values.find(value => value.field.name === row.title);

		// Set the most general tier in this case
		return foundCellValue?.field.tier ?? AccessTierLevel.Tier1;
	}

	private transposeMatrixData(matrixData: T[]): TransposeRowType[] {
		const newMatrixData: TransposeRowType[] = [];
		let index = 0;

		for (const row of this.rowsNames) {
			newMatrixData[index] = {};
			newMatrixData[index][this.titleColumnName] = row.fieldName;
			for (const columnElement of matrixData) {
				const { values } = columnElement;

				const foundValue = values
					.find(value => value.field.fieldType === row.fieldType &&
            value.field.name === row.fieldName);
				newMatrixData[index][columnElement.id] = foundValue ?? null;
			}
			index += 1;
		}
		return newMatrixData;
	}

	/**
	 * Whether to use a header cell template.
	 * @param column Column info.
	 */
	protected shouldUseHeaderCellTemplate(column: ColumnInfo<T>): boolean {
		return this.headerCellTemplate !== null && column.name !== 'title';
	}
}
