import { NullObject } from '@scriptac/common/core/models/null-object';

import { NULL_OBJECT_ID } from '../utils/constants';

/** StateData. */
export class State {
	/** Id. */
	public id: number;

	/** Name. */
	public name: string;

	/** Geo name code. */
	public geonameCode: string;

	public constructor(data: StateConstructorData) {
		this.id = data.id;
		this.name = data.name;
		this.geonameCode = data.geonameCode;
	}
}

/** Nullable state data. */
export class NullState extends State implements NullObject {
	/** Is null check. */
	public readonly isNull: boolean = true;

	public constructor(
		data: StateConstructorData = {
			id: NULL_OBJECT_ID,
			name: '',
			geonameCode: '',
		},
	) {
		super(data);
	}
}

type StateConstructorData = State;
