import { Injectable } from '@angular/core';

import { PropertyTypeFilters } from '../property-type.service';

import { PropertyTypeFiltersDto } from './dto/property-type-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for Property Type filters. */
@Injectable({
	providedIn: 'root',
})
export class PropertyTypeFiltersMapper implements IMapperToDto<PropertyTypeFiltersDto, PropertyTypeFilters> {

	/** @inheritdoc */
	public toDto(data: PropertyTypeFilters): PropertyTypeFiltersDto {
		const params: PropertyTypeFiltersDto = {};

		if (data.search) {
			params.search = data.search;
		}

		if (data.jurisdictions && data.jurisdictions.length > 0) {
			params.rules__jurisdictions = data.jurisdictions.map(state => state.id);
		}

		return params;
	}
}
