import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { FetchListOptions } from '../../models/fetch-list-options';

import { DefaultFiltersMapper } from './default-filters.mapper';
import { IMapperToDto } from './mappers';
import { PaginationOptionsMapper } from './pagination-options.mapper';
import { SortMapper } from './sort.mapper';

/**
 * Mapper for HttpParams.
 */
@Injectable({ providedIn: 'root' })
export class HttpParamsMapper<T extends object = {}> implements IMapperToDto<HttpParams, FetchListOptions<T>> {
	private readonly paginationMapper = inject(PaginationOptionsMapper);

	private readonly sortMapper = inject(SortMapper);

	private readonly defaultFiltersMapper = inject(DefaultFiltersMapper);

	/** @inheritDoc */
	public toDto<TDto = {}>(
		options: FetchListOptions<T>,
		customFiltersMapper?: IMapperToDto<TDto, T>,
	): HttpParams {
		const filtersMapper = customFiltersMapper ?? this.defaultFiltersMapper;

		return new HttpParams({
			fromObject: {
				...(options.pagination ? this.paginationMapper.toDto(options.pagination) : {}),
				...(options.sort ? this.sortMapper.toDto(options.sort) : {}),
				...(options.filters ? filtersMapper.toDto(options.filters) : {}),
			},
		});
	}
}
