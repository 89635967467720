import { MatrixColumnInfo } from '../models/matrix-column-info';

import { equalSet } from './equal-set';

/**
 * Get initial matrix columns list from api response and storage saving.
 * @param columnsFromStorage Columns list from storage.
 * @param columnsFromApi Columns list from api.
 */
export function getInitialMatrixColumns(
	columnsFromStorage: MatrixColumnInfo[] | null,
	columnsFromApi: MatrixColumnInfo[] | null,
): MatrixColumnInfo[] {
	if (columnsFromApi) {
		const savedColumns = columnsFromStorage ?? [];
		const columnsFromApiSet = new Set(columnsFromApi.map(col => col.name));
		const columnsFromStorageSet = new Set(savedColumns.map(col => col.name));
		const isSetsEqual = equalSet(columnsFromApiSet, columnsFromStorageSet);
		return isSetsEqual ? savedColumns : columnsFromApi;
	}
	return [];
}
