<h2>Confirm action</h2>

<p>{{ data.message }}</p>

<div class="button-wrapper">
	<button
		(click)="close(false)"
		type="button"
		color="accent"
		mat-flat-button
	>
		Cancel
	</button>
	<button
		(click)="close(true)"
		type="button"
		color="accent"
		mat-stroked-button
	>
		Confirm
	</button>
</div>
