import { State } from './state';

/**
 * Property code category.
 */
export class PropertyCodeCategory {
	/** Id of category. */
	public readonly id: number;

	/** Name. */
	public readonly name: string;

	/** Code. */
	public readonly code: string;

	/** States. */
	public readonly states: State[];

	public constructor(data: PropertyCodeCategory) {
		this.id = data.id;
		this.name = data.name;
		this.code = data.code;
		this.states = data.states;
	}
}
