import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/** Dialog data. */
export type ConfirmDialogData = {

	/** Dialog msg. */
	readonly message: string;
};

/** Base app confirm dialog. */
@Component({
	selector: 'scriptaw-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

	private readonly dialogRef = inject<MatDialogRef<ConfirmDialogComponent>>(MatDialogRef);

	/** Dialog data. */
	protected readonly data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);

	/**
	 * Close dialog.
	 *
	 * @param confirm Dialog result.
	 */
	public close(confirm: boolean): void {
		this.dialogRef.close(confirm);
	}
}
