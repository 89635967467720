import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { City } from '../models/city';
import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { State } from '../models/state';

import { AppConfigService } from './app-config.service';
import { CityMapper } from './mappers/city.mapper';
import { CityDto } from './mappers/dto/city-dto';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { StateDto } from './mappers/dto/state-dto';
import { StateMapper } from './mappers/state.mapper';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';
import { PaginationApiService } from './api/pagination-api.service';

/** Filter options. */
export type LocationFilterOptions = {

	/** Search. */
	readonly search?: string;
};

/** Service to get locations data. */
@Injectable({ providedIn: 'root' })
export class LocationService {
	private readonly config = inject(AppConfigService);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly listMapper = inject(PagedListMapper);

	private readonly cityMapper = inject(CityMapper);

	private readonly stateMapper = inject(StateMapper);

	private readonly http = inject(HttpClient);

	private readonly paginationService = inject(PaginationApiService);

	/** States list. */
	public readonly states$: Observable<State[]>;

	private readonly citiesUrl = new URL('locations/cities/', this.config.apiUrl).toString();

	private readonly stateUrl = new URL('locations/states/', this.config.apiUrl).toString();

	public constructor() {
		this.states$ = this.getAllStates().pipe(
			shareReplay({ refCount: false, bufferSize: 1 }),
		);
	}

	/**
	 * Get cities paged list.
	 * @param options Fetch options.
	 */
	public getCitiesPagedList(options: FetchListOptions): Observable<PagedList<City>> {
		const params = this.paramsMapper.toDto(options);

		return this.http
			.get<PagedListDto<CityDto>>(this.citiesUrl, { params })
			.pipe(
				map(response => this.listMapper.fromDto(response, this.cityMapper, options.pagination)),
			);
	}

	/**
	 * Get states paged list.
	 * @param options Fetch options.
	 */
	public getStatesPagedList(options: FetchListOptions): Observable<PagedList<State>> {
		const params = this.paramsMapper.toDto(options);

		return this.http.get<PagedListDto<StateDto>>(this.stateUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(
				response,
				this.stateMapper,
				options.pagination,
			)),
		);
	}

	private getAllStates(): Observable<State[]> {
		return this.paginationService.getAllItemsFromPagedList(
			this.http.get<PagedListDto<StateDto>>(this.stateUrl),
		).pipe(
			map(response => response.map(dto => this.stateMapper.fromDto(dto))),
		);
	}
}
