import { Component, ChangeDetectionStrategy, forwardRef, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';
import { SortDirection } from '@scriptac/common/core/enums/sort-direction';
import { PropertyType } from '@scriptac/common/core/models/property-type';
import { PropertyTypeFilters, PropertyTypeService } from '@scriptac/common/core/services/property-type.service';

/** Multi select for property types. */
@Component({
	selector: 'scriptaw-property-type-autocomplete',
	templateUrl: './property-type-autocomplete.component.html',
	styleUrls: ['./property-type-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PropertyTypeAutocompleteComponent),
			multi: true,
		},
	],
})
@DestroyableComponent()
export class PropertyTypeAutocompleteComponent extends SimpleValueAccessor<PropertyType[]> {
	private readonly fb = inject(NonNullableFormBuilder);

	private readonly typesService = inject(PropertyTypeService);

	/** Placeholder. */
	@Input()
	public placeholder = 'Select';

	/** Jurisdiction ids. */
	@Input()
	public set jurisdictionIds(list: number[]) {
		this.jurisdictionIds$.next(list);
	}

	/** List of selected types. */
	public readonly selectedTypes = new Map<number, PropertyType>();

	/** Control for input. */
	public readonly codeControl = this.fb.control('');

	/** Control for input. */
	private readonly jurisdictionIds$ = new BehaviorSubject<number[]>([]);

	private readonly filter$: Observable<PropertyTypeFilters> = listenControlChanges<string>(this.codeControl).pipe(
		map(search => ({
			search,
		})),
	);

	/** List manager. */
	public readonly listManager = new ListManager<PropertyType, PropertyTypeFilters>({
		strategy: new InfiniteScrollListStrategy(),
		filter$: this.filter$,
		pageSize: 30,
	});

	/** Types list. */
	private readonly typesList$ = this.listManager
		.getPaginatedItems(option => this.typesService.getPropertyTypesList(option))
		.pipe(
			shareReplay({
				bufferSize: 1,
				refCount: true,
			}),
		);

	private readonly reload$ = new Subject();

	/** Filtered types list. */
	public readonly types$ = combineLatest([
		this.typesList$,
		this.reload$.pipe(startWith(null)),
	]).pipe(
		map(([types]) => types.filter(code => !this.selectedTypes.has(code.id))),
	);

	public constructor() {
		super();

		this.listManager.setSort({
			direction: SortDirection.ASC,
			field: 'name',
		});
	}

	/**
	 * Remove code from selected by id.
	 *
	 * @param code Naupa code.
	 */
	public remove(code: PropertyType): void {
		this.selectedTypes.delete(code.id);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedTypes.values()];
	}

	/**
	 * Add selected value to selected types.
	 *
	 * @param event Autocomplete select event.
	 */
	public selected(event: MatAutocompleteSelectedEvent): void {
		const propertyType: PropertyType = event.option.value;

		if (!propertyType?.id) {
			return;
		}

		this.selectedTypes.set(propertyType.id, propertyType);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedTypes.values()];

		this.codeControl.setValue('');
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.controlValue.forEach(val => this.selectedTypes.set(val.id, val));
		}
	}
}
