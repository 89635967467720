import { inject, Injectable } from '@angular/core';

import { ReportingTemplate } from '../../models/reporting-template';

import { JurisdictionReportingTemplateDto } from './dto/jurisdiction-reporting-template-dto';
import { IMapperFromDto } from './mappers';
import { StateMapper } from './state.mapper';

/** Jurisdiction reporting template mapper. */
@Injectable({ providedIn: 'root' })
export class JurisdictionReportingTemplateMapper implements
IMapperFromDto<JurisdictionReportingTemplateDto, ReportingTemplate> {

	private readonly stateMapper = inject(StateMapper);

	/** @inheritdoc */
	public fromDto(data: JurisdictionReportingTemplateDto): ReportingTemplate {
		const state = this.stateMapper.fromDto(data);
		return new ReportingTemplate({
			state,
			templateUrl: data.reporting_template_data?.reporting_template ?? null,
			id: data.reporting_template_data?.id ?? 0,
		});
	}
}
