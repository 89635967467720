import { Injectable } from '@angular/core';
import { diffWords } from 'diff';

/** Diff change info. */
export type Change = {

	/** Count of items in change (e.x for diffWords() - sum count of words and spaces). */
	count?: number | undefined;

	/**
	 * Text content.
	 */
	value: string;

	/**
	 * `true` if the value was inserted into the new string.
	 */
	added?: boolean | undefined;

	/**
	 * `true` if the value was removed from the old string.
	 */
	removed?: boolean | undefined;
};

/** Service for working with diff lib. */
@Injectable({
	providedIn: 'root',
})
export class DiffService {
	/**
	 * Get diff of two text splitting by words.
	 * @param text1 Text 1.
	 * @param text2 Text 2.
	 */
	public getDiffWords(text1: string, text2: string): Change[] {
		return diffWords(text1, text2);
	}
}
