import { Injectable, inject } from '@angular/core';

import { MatrixFieldType } from '../../enums/matrix-field-type';
import { MatrixField, MatrixFieldEdit } from '../../models/matrix-field';
import { EntityValidationErrors } from '../../models/app-error';

import { MatrixFieldDto, MatrixFieldEditDto } from './dto/matrix-fields-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { MatrixFieldChoiceMapper } from './matrix-field-choice.mapper';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Mapper for matrix fields. */
@Injectable({ providedIn: 'root' })
export class MatrixFieldMapper
implements
    IMapperFromDto<MatrixFieldDto, MatrixField>,
    IMapperToDto<MatrixFieldEditDto, MatrixFieldEdit> {
	private readonly matrixFieldChoiceMapper = inject(MatrixFieldChoiceMapper);

	/** @inheritdoc */
	public toDto(data: MatrixFieldEdit): MatrixFieldEditDto {
		/**
		 * We need to map choices to empty array.
		 * Because choices can exist only for MultiChoice and SingleChoice field type.
		 * If we send filled choices array with other types server will return error.
		 */
		const choices =
			data.fieldType === MatrixFieldType.MultiChoice ||
			data.fieldType === MatrixFieldType.SingleChoice ?
				data.choices.map(c => this.matrixFieldChoiceMapper.toDto(c)) :
				[];

		return {
			id: data.id ?? null,
			name: data.name,
			field_type: data.fieldType,
			tier: data.tier,
			choices,
			to_delete: data.toDelete ?? false,
		};
	}

	/** @inheritdoc */
	public fromDto(dto: MatrixFieldDto): MatrixField {
		return new MatrixField({
			id: dto.id,
			name: dto.name,
			fieldType: dto.field_type,
			tier: dto.tier,
			choices: dto.choices.map(c => this.matrixFieldChoiceMapper.fromDto(c)),
		});
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<MatrixFieldDto>,
	): EntityValidationErrors<MatrixField> {
		return {
			id: extractErrorMessageByErrorKey(errorDto, 'id'),
			name: extractErrorMessageByErrorKey(errorDto, 'name'),
			fieldType: extractErrorMessageByErrorKey(errorDto, 'field_type'),
			tier: extractErrorMessageByErrorKey(errorDto, 'tier'),
			choices: extractErrorMessageByErrorKey(errorDto, 'choices'),
		};
	}
}
