<button
	mat-flat-button
	class="custom-icon-button"
	type="button"
	title="Export"
	[matMenuTriggerFor]="menu"
>
	<span class="button-content">
		<mat-icon svgIcon="export" />
		<span>Export</span>
	</span>
</button>

<mat-menu #menu>
	@for (fileFormat of exportFileFormat.toArray(); track fileFormat) {
		<button
			mat-menu-item
			type="button"
			(click)="selectExportFileFormat.emit(fileFormat)"
		>
			{{ exportFileFormat.toReadable(fileFormat) }}
		</button>
	}
</mat-menu>
