/** Field choice. */
export class MatrixFieldChoice {
	/** Choice ID. */
	public readonly id: number;

	/** Choice name. */
	public readonly name: string;

	public constructor(data: MatrixFieldChoiceConstructorData) {
		this.id = data.id;
		this.name = data.name;
	}
}

/** Constructor data.  */
export type MatrixFieldChoiceConstructorData = MatrixFieldChoice;

/** Matrix field edit data. */
export type MatrixFieldChoiceEdit = {

	/** Choice ID. */
	readonly id?: number;

	/** Uniq choice name. */
	readonly name: string;

	/** Is field should be removed. */
	readonly toDelete: boolean;
};

export namespace MatrixFieldChoiceEdit {

	/**
	 * Comparator function for choice list.
	 * Name of item should uniq.
	 *
	 * @param item1 Item to compare 1.
	 * @param item2 Item to compare 2.
	 */
	export const compare =
  (item1: MatrixFieldChoiceEdit, item2: MatrixFieldChoiceEdit): boolean => item2.name === item1.name;
}
