import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { toggleExecutionState } from '@scriptac/common/core/utils/toggle-execution-state';
import { SearchResultsApiService } from '@scriptac/common/core/services/api/search-results-api.service';
import { MatrixSearchResult } from '@scriptac/common/core/models/matrix-search-result';
import { routePaths } from 'projects/web/src/app/route-paths';

import { MatrixSearchFilters } from '../models/matrix-search-filter';
import { FetchListOptions } from '../models/fetch-list-options';
import { MatrixSearchName } from '../models/matrix-search-name';
import { PagedList } from '../models/paged-list';
import { SearchResultsLaw } from '../models/law';
import { SearchMatrixLawsFilters } from '../models/search-matrix-laws-filters';
import { Dormancy } from '../models/dormancy';
import { DormancyFilters } from '../models/dormancy-filters';

import { SearchResultFiltersMapper } from './mappers/search-result-filters.mapper';

/** Service for providing data in search pages. */
@Injectable()
export class SearchPageService {
	private readonly searchApiService = inject(SearchResultsApiService);

	private readonly route = inject(ActivatedRoute);

	private readonly router = inject(Router);

	private readonly filterMapper = inject(SearchResultFiltersMapper);

	/** Loading state. */
	public readonly isLoading$ = new BehaviorSubject(false);

	/** Shared filters value as observable. */
	public readonly sharedFilters$ = this.route.queryParams.pipe(
		map(params => {
			if (Object.keys(params).length === 0) {
				return null;
			}
			return this.filterMapper.fromQueryParams(params);
		}),
	);

	/**
	 * Start matrix search.
	 * @param filters Filters.
	 */
	public startSearch(filters: MatrixSearchFilters): void {
		this.router.navigate(routePaths.searchResults, {
			queryParams: this.filterMapper.toQueryParams(filters),
		});
	}

	/**
	 * Get search results from API according to filters.
	 * @param options Filtering options.
	 */
	public getSearchResultsList(options: MatrixSearchFilters): Observable<MatrixSearchResult[]> {
		return this.searchApiService.getSearchResults(options).pipe(
			toggleExecutionState(this.isLoading$),
		);
	}

	/**
	 * Get matrixes search names list.
	 * @param options Filtering options.
	 */
	public getMatrixSearchNamesList(options: FetchListOptions): Observable<PagedList<MatrixSearchName>> {
		return this.searchApiService.getMatrixSearchNames(options).pipe(
			toggleExecutionState(this.isLoading$),
		);
	}

	/**
	 * Get list of IRA Distributions with rules.
	 * @param options Fetch options.
	 */
	public getDormancyList(options: FetchListOptions<DormancyFilters>): Observable<PagedList<Dormancy>> {
		return this.searchApiService.getDormancyList(options).pipe(
			toggleExecutionState(this.isLoading$),
		);
	}

	/**
	 * Get matrixes search names list.
	 * @param options Filtering options.
	 */
	public getMatrixSearchLaws(options: SearchMatrixLawsFilters): Observable<SearchResultsLaw[]> {
		return this.searchApiService.getSearchMatrixLaws(options).pipe(
			toggleExecutionState(this.isLoading$),
		);
	}
}
