import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Navigation tab. */
export type NavigationTab = {

	/** Tab label. */
	readonly label: string;

	/** Route path. */
	readonly path: string | string[];
};

/**
 * Navigation tabs component.
 */
@Component({
	selector: 'scriptaw-navigation-tabs',
	templateUrl: './navigation-tabs.component.html',
	styleUrls: ['./navigation-tabs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {

	/** Tabs. */
	@Input()
	public tabs: NavigationTab[] = [];
}
