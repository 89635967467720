import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { NavigationLink } from '@scriptac/common/core/models/navigation-link';
import { routePaths } from 'projects/web/src/app/route-paths';

/**
 * Welcome page header.
 */
@Component({
	selector: 'scriptaw-welcome-page-header',
	templateUrl: './welcome-page-header.component.html',
	styleUrls: ['./welcome-page-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageHeaderComponent {
	/** Background image. */
	@Input()
	public backgroundImg = '/assets/images/homepage-header.png';

	/** Navigation links for home header. */
	public readonly navLinks: NavigationLink[] = [
		{
			route: routePaths.aboutUs,
			icon: 'about-us',
			title: 'About Us',
		},
		{
			route: routePaths.ourServices,
			icon: 'service',
			title: 'Our Services',
		},
		{
			route: routePaths.news,
			icon: 'news',
			title: 'News',
		},
		{
			route: routePaths.login,
			icon: 'login',
			title: 'Log In',
		},
	];

	/** Route paths. */
	public readonly routePaths = routePaths;
}
