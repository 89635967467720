import { Directive, TemplateRef, inject } from '@angular/core';

/**
 * Directive to mark ng-container as table-cell.
 *
 * <ng-container *scriptawTableCell="let row">
 *  {{ row|scriptawTableCell }}
 * </ng-container>.
 */
@Directive({
	selector: '[scriptawTableCell]',
})
export class TableCellDirective {

	/** Template. */
	public readonly template = inject(TemplateRef);
}
