import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { PropertyCodeCategory } from '../models/property-code-category';

import { AppConfigService } from './app-config.service';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { PropertyCodeCategoryDto } from './mappers/dto/property-code-category';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';
import { PropertyCodeCategoryFiltersMapper } from './mappers/property-code-category-filters.mapper';
import { PropertyCodeCategoryMapper } from './mappers/property-code-category.mapper';

/** Filter options. */
export type PropertyCodeCategoryFilters = {

	/** Search. */
	readonly search?: string;

	/** Jurisdiction ids. */
	readonly jurisdictionIds?: number[];

	/** Hide state specific category. */
	readonly hideStateSpecific?: boolean;
};

/** Service for PropertyCode Category API access. */
@Injectable({ providedIn: 'root' })
export class PropertyCodeCategoryService {
	private readonly http = inject(HttpClient);

	private readonly config = inject(AppConfigService);

	private readonly listMapper = inject(PagedListMapper);

	private readonly categoryMapper = inject(PropertyCodeCategoryMapper);

	private readonly categoryFilterMapper = inject(PropertyCodeCategoryFiltersMapper);

	private readonly paramsMapper = inject(HttpParamsMapper);

	private readonly propertyUrl = new URL('property-codes/categories/', this.config.apiUrl).toString();

	/**
	 * Get Property code categories list.
	 * @param options Options.
	 */
	public getCategoriesList(options: FetchListOptions): Observable<PagedList<PropertyCodeCategory>> {
		const params = this.paramsMapper.toDto(options, this.categoryFilterMapper);

		return this.http.get<PagedListDto<PropertyCodeCategoryDto>>(this.propertyUrl, { params }).pipe(
			map(response => this.listMapper.fromDto(
				response,
				this.categoryMapper,
				options.pagination,
			)),
		);
	}
}
