import { Injectable, inject } from '@angular/core';

import { Dormancy } from '../../models/dormancy';

import { DormancyDto } from './dto/dormancy-dto';
import { IMapperFromDto } from './mappers';
import { MatrixValueMapper } from './matrix-value.mapper';
import { Naupa2CodeMapper } from './naupa-2-code.mapper';
import { StateMapper } from './state.mapper';

/** Mapper for Dormancy. */
@Injectable({ providedIn: 'root' })
export class DormancyMapper implements IMapperFromDto<DormancyDto, Dormancy> {
	private readonly stateMapper = inject(StateMapper);

	private readonly naupaCodeMapper = inject(Naupa2CodeMapper);

	private readonly matrixValueMapper = inject(MatrixValueMapper);

	/** @inheritdoc */
	public fromDto(data: DormancyDto): Dormancy {
		return new Dormancy({
			id: data.id,
			law: {
				id: data.law.id,
				state: this.stateMapper.fromDto(data.law.jurisdiction),
				matrix: {
					id: data.law.matrix.id,
					name: data.law.matrix.name,
					searchType: data.law.matrix.search_type,
					specialType: data.law.matrix.special_type,
				},
			},
			value: data.dormancy_value,
			alternativeValue: data.alternative_dormancy_value,
			naupa2Codes: data.naupa2_code_values.map(code => this.naupaCodeMapper.fromDto(code)),
			extraValues: data.extra_dormancy_values.map(value => this.matrixValueMapper.fromDto(value)),
		});
	}
}
