<div class="progress-bar-container">
	@if (loading) {
		<mat-progress-bar mode="indeterminate" />
	}
</div>

<div
	class="table-wrapper"
	#resizableTable
>
	<table
		mat-table
		cdkDropList
		cdkDropListLockAxis="x"
		cdkDropListOrientation="horizontal"
		class="full-width"
		[dataSource]="dataSource"
		[class.with-fixed-column]="showFixedColumn"
		(cdkDropListDropped)="dropListDropped($event)"
	>
		<!-- STATE COLUMN -->
		<ng-container
			[matColumnDef]="stateColumnName"
			[sticky]="true"
		>
			<!-- HEADER FOR THE CELL -->
			<th
				*matHeaderCellDef
				mat-header-cell
				class="fixed-header-cell"
			>
				<span>Jurisdiction</span>
			</th>

			<!-- BODY FOR THE CELL -->
			@if (customStateCellTemplate) {
				<ng-container
					*matCellDef="let element"
					mat-cell
					[ngTemplateOutlet]="customStateCellTemplate"
					[ngTemplateOutletContext]="{ $implicit: element }"
				/>
			} @else {
				<td
					*matCellDef="let element"
					mat-cell
					class="fixed-cell"
				>
					@if (stateCellTemplate) {
						<ng-container
							[ngTemplateOutlet]="stateCellTemplate"
							[ngTemplateOutletContext]="{ $implicit: element }"
						/>
					} @else {}
				</td>
			}
		</ng-container>

		@for (col of columns; track col.name; let i = $index) {
			<ng-container [matColumnDef]="col.name">
				<th
					*matHeaderCellDef
					mat-header-cell
					cdkDrag
					[cdkDragDisabled]="disableColumnsReorder"
					[attr.data-colName]="col.name"
				>
					<scriptac-resizable
						[columnWidth]="col.width"
						(updatedWidth)="updateColumnWidth($event, col)"
					>
						@if (!disableColumnsReorder) {
							<mat-icon
								cdkDragHandle
								class="header-menu-icon"
							>
								reorder
							</mat-icon>
						}
						<span>{{ col.headerText }}</span>
					</scriptac-resizable>
				</th>
				<td
					*matCellDef="let element"
					mat-cell
					scriptacAutoResizeColumn
					[tableElement]="resizableTable"
					[colName]="col.name"
					[columnWidth]="col.width || 100"
				>
					<scriptaw-user-tier-lock [tier]="getCellTier(element, col.name)">
						@let customTemplate = getColumnTemplateByName(col.name);

						@if (customTemplate) {
							<ng-container
								[ngTemplateOutletContext]="{ $implicit: element }"
								[ngTemplateOutlet]="customTemplate"
							/>
						} @else {
							<scriptaw-matrix-dynamic-cell
								[matrixValue]="getCellValueByColumnName(element, col.name)"
								[dateFormat]="dateFormat"
							/>
						}
					</scriptaw-user-tier-lock>
				</td>
			</ng-container>
		}

		<!-- FIXED COLUMN FOR ACTION BUTTONS -->
		<ng-container
			[matColumnDef]="fixedColumnName"
			[stickyEnd]="true"
		>
			<!-- HEADER FOR THE CELL -->
			<th
				*matHeaderCellDef
				mat-header-cell
				class="fixed-header-cell"
			></th>

			<!-- BODY FOR THE CELL -->
			<td
				*matCellDef="let element"
				mat-cell
				class="fixed-cell"
			>
				@if (fixedCellTemplate) {
					<ng-container
						[ngTemplateOutlet]="fixedCellTemplate"
						[ngTemplateOutletContext]="{ $implicit: element }"
					/>
				} @else {}
			</td>
		</ng-container>

		<!-- Header cells -->
		<tr
			*matHeaderRowDef="columnNames; sticky: true"
			mat-header-row
			[class.hidden]="dataSource.data.length === 0"
		></tr>

		<tr
			*matRowDef="let row; columns: columnNames; let index = index"
			[ngClass]="getRowCssClass ? getRowCssClass(row, index) : ''"
			mat-row
		></tr>

		<!-- Empty Table message -->
		<ng-container matColumnDef="noRecords">
			<td
				*matFooterCellDef
				mat-cell
				class="empty-table-message"
				[attr.colspan]="getEmptyTableColspan()"
			>
				@if (!loading) {
					<span>{{ emptyMessage }}</span>
				} @else {
					Loading data...
				}
			</td>
		</ng-container>
		<tr
			*matFooterRowDef="['noRecords']"
			mat-footer-row
			[class.hidden]="dataSource.data.length > 0"
		></tr>
	</table>
</div>

@if (pagination) {
	<mat-paginator
		color="primary"
		[pageSize]="pagination.pageSize"
		[pageIndex]="pagination.page"
		[length]="pagination.totalCount"
		[hidePageSize]="true"
		(page)="paginationChanged($event)"
	/>
}
