import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Validators, NonNullableFormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { LawEdit } from '@scriptac/common/core/models/law';
import { NullState, State } from '@scriptac/common/core/models/state';
import { OnlyValidForm } from '@scriptac/common/core/utils/only-valid-form.decorator';
import { ScriptaValidators } from '@scriptac/common/shared/validators/scripta-validators';
import { LocationService, LocationFilterOptions } from '@scriptac/common/core/services/location.service';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { ControlsOf } from '@scriptac/common/core/utils/types/controls-of';
import { trackById } from '@scriptac/common/core/utils/track-by-id';

/** Data for dialog. */
export type AddLawDialogData = {

	/** State for law. */
	readonly matrixId: number;
};

type AddLawFormControls = ControlsOf<{

	/** State data. */
	readonly state: State;
}>;

type AddLawFormGroup = FormGroup<AddLawFormControls>;

/** Dialog for creating new law in matrix. */
@Component({
	selector: 'scriptaw-matrix-add-law-dialog',
	templateUrl: './matrix-add-law-dialog.component.html',
	styleUrls: ['./matrix-add-law-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixAddLawDialogComponent {

	private readonly dialogRef = inject<MatDialogRef<MatrixAddLawDialogComponent>>(MatDialogRef);

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly locationService = inject(LocationService);

	/** Dialog data. */
	protected readonly data = inject<AddLawDialogData>(MAT_DIALOG_DATA);

	/** Control for states. */
	public readonly form = this.fb.group<AddLawFormControls>({
		state: this.fb.control(new NullState(), [Validators.required, ScriptaValidators.nullObjectRequiredValidator]),
	});

	/** Filter with text for state control. */
	public readonly stateFilter$ = new BehaviorSubject<LocationFilterOptions>({ search: '' });

	/**
	 * Format state object to display view.
	 * @param state State data.
	 */
	public readonly stateReadable: (arg: State) => string = (state: State) => state.name;

	/** Empty state object. */
	public readonly emptyState = new NullState();

	/** States list manager. */
	public readonly statesListManager = new ListManager<State, LocationFilterOptions>({
		strategy: new InfiniteScrollListStrategy(),
		filter$: this.stateFilter$,
		pageSize: 100,
	});

	/** Track by for states. */
	public readonly trackById = trackById;

	/** States list. */
	public readonly states$ = this.statesListManager
		.getPaginatedItems(options => this.locationService.getStatesPagedList(options)).pipe(
			shareReplay({ refCount: true, bufferSize: 1 }),
		);

	/**
	 * Handle confirm click.
	 *
	 * @param form Current form..
	 */
	@OnlyValidForm()
	public onConfirm(form: AddLawFormGroup): void {
		const law: LawEdit = {
			state: form.getRawValue().state.id,
			matrix: this.data.matrixId,
		};

		this.dialogRef.close(law);
	}

	/** Close dialog. */
	public onClose(): void {
		this.dialogRef.close();
	}
}
