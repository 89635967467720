import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { TableColumnInfo } from '@scriptac/common/core/models/column-info';
import { PriorityRule } from '@scriptac/common/core/models/priority-rule';
import { PriorityRuleType } from '@scriptac/common/core/enums/priority-rule-type';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { ListManager, TableListStrategy } from '@scriptac/common/core/utils/list-manager';
import { DialogUtilsService } from '@scriptac/common/core/services/dialog-utils.service';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { PriorityRulesApiService } from '@scriptac/common/core/services/api/priority-rules-api.service';
import { trackById } from '@scriptac/common/core/utils/track-by-id';

import { routePaths } from '../../../../route-paths';

const ACTIONS_COLUMNS_NAME = 'actions';

/** Priority rules matrix. */
@Component({
	selector: 'scriptaw-priority-rules-matrix',
	templateUrl: './priority-rules-matrix.component.html',
	styleUrls: ['./priority-rules-matrix.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class PriorityRulesMatrixComponent {
	private readonly dialogUtilsService = inject(DialogUtilsService);

	private readonly notificationService = inject(NotificationService);

	private readonly priorityRulesService = inject(PriorityRulesApiService);

	/** Show Admin actions. */
	@Input()
	public showAdminActions = false;

	/** Route paths. */
	public readonly routePaths = routePaths;

	/** Priority rule types enum. */
	public readonly priorityRules = PriorityRuleType;

	/** Table Columns. */
	public readonly allTableColumns: TableColumnInfo[] = [
		{ name: 'rule', sort: null, headerText: 'Rule', width: '10%' },
		{ name: 'firstPriority', sort: null, headerText: 'First Priority', width: '20%' },
		{ name: 'secondPriority', sort: null, headerText: 'Second Priority', width: '20%' },
		{ name: 'source', sort: null, headerText: 'Source', width: '20%' },
		{ name: 'applicability', sort: null, headerText: 'Applicability', width: '20%' },
		{ name: 'actions', sort: null, headerText: '', width: '10%' },
	];

	/** Table manager. */
	public readonly tableManager = new ListManager<PriorityRule>({
		strategy: new TableListStrategy(),
	});

	/** Priority rules. */
	public readonly priorityRules$ = this.tableManager.getPaginatedItems(
		options => this.priorityRulesService.getPriorityRules(options),
	).pipe(
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Track by function. */
	public readonly trackById = trackById;

	/** Get table columns to display. */
	public get displayedTableColumns(): TableColumnInfo[] {
		if (this.showAdminActions) {
			return this.allTableColumns;
		}
		return this.allTableColumns.filter(columns => columns.name !== ACTIONS_COLUMNS_NAME);
	}

	/**
	 * Delete rule.
	 * @param id Rule id.
	 */
	public deleteRule(id: number): void {
		this.dialogUtilsService.openConfirmationModal('Are you sure you want to delete this rule?')
			.pipe(
				filter(Boolean),
				switchMap(() => this.priorityRulesService.deleteRule(id)),
				takeUntilDestroy(this),
			)
			.subscribe(() => {
				this.notificationService.showSuccess('Rule was deleted successfully');
				this.tableManager.reloadList();
			});
	}
}
