import { MatrixFieldType } from '../enums/matrix-field-type';
import { MatrixValue, MatrixValueEditControlType } from '../models/matrix-value';

/**
 * Util to calculate cell value according to field type to use in ts code without formatting.
 * @param matrixValue Value for current cell with type, name and data.
 */
export function calcDynamicValue(matrixValue: MatrixValue): MatrixValueEditControlType | null {
	if (!matrixValue) {
		return '';
	}
	switch (matrixValue.field.fieldType) {
		case MatrixFieldType.Text:
			return matrixValue.textValue;
		case MatrixFieldType.Bool:
			return matrixValue.boolValue;
		case (MatrixFieldType.Date):
			return matrixValue.dateValue;
		case (MatrixFieldType.MultiChoice):
			return matrixValue.choicesValues;
		case (MatrixFieldType.SingleChoice):
			return matrixValue.choicesValues ? matrixValue.choicesValues[0] : matrixValue.choicesValues;
		case (MatrixFieldType.Naupa2Code):
			return matrixValue.naupa2CodeValues;
		case (MatrixFieldType.HolderType):
			return matrixValue.holderTypeValues;
		default:
			return matrixValue.textValue;
	}

}
