import { MatrixField } from './matrix-field';
import { MatrixFieldChoice } from './matrix-field-choice';
import { Naupa2Code } from './naupa-2-code';
import { HolderType } from './holder-type';

/** Matrix value edit control type. */
export type MatrixValueEditControlType =
  | string
  | Date
  | boolean
  | MatrixFieldChoice
  | MatrixFieldChoice[]
  | Naupa2Code[]
  | HolderType[]
  | null;

/** Matrix value edit control name. */
export type MatrixValueEditControlName =
  | 'textValue'
  | 'dateValue'
  | 'boolValue'
  | 'singleChoiceValue'
  | 'multipleChoicesValues'
  | 'naupa2CodeValues'
  | 'value'
  | 'alternativeValue'
  | 'holderTypeValues';

/** Type for post/put requests.  */
export type MatrixValueEdit = {

	/** Revision id. */
	readonly id?: number;

	/** Revision field data. */
	readonly field: MatrixField;

	/** Text value. */
	readonly textValue?: string;

	/** Date value. */
	readonly dateValue?: Date;

	/** Bool value. */
	readonly boolValue?: boolean;

	/** Choices values data. */
	readonly singleChoiceValue?: MatrixFieldChoice;

	/** Choices values data. */
	readonly multipleChoicesValues?: MatrixFieldChoice[];

	/** Holder types values data. */
	readonly holderTypeValues?: HolderType[];

	/** List of code values. */
	readonly naupa2CodeValues?: Naupa2Code[];

	/** Dormancy value. */
	readonly value?: string;

	/** Alternative Dormancy value. */
	readonly alternativeValue?: string;

	/** Note. */
	readonly note: string;
};

/** Matrix cell value. */
export class MatrixValue {
	/** Value id. */
	public readonly id: number;

	/** Field id. */
	public readonly fieldId: number;

	/** Field data. */
	public readonly field: MatrixField;

	/** Text value if this string field. */
	public readonly textValue: string;

	/** Date value if this date field. */
	public readonly dateValue: Date | null;

	/** Bool value if this boolean field. */
	public readonly boolValue: boolean | null;

	/** Choices selected values if this field with choice. */
	public readonly choicesValues: MatrixFieldChoice[];

	/** NAUPA 2 Codes selected values if this field with NAUPA codes selection. */
	public readonly naupa2CodeValues: Naupa2Code[];

	/** Holder types selected values if this field with holder type. */
	public readonly holderTypeValues: HolderType[];

	/** Value note. */
	public readonly note: string;

	public constructor(data: MatrixValueConstructorData) {
		this.id = data.id;
		this.fieldId = data.fieldId;
		this.field = data.field;
		this.textValue = data.textValue;
		this.dateValue = data.dateValue;
		this.boolValue = data.boolValue;
		this.choicesValues = data.choicesValues;
		this.naupa2CodeValues = data.naupa2CodeValues;
		this.holderTypeValues = data.holderTypeValues;
		this.note = data.note;
	}
}

type MatrixValueConstructorData = MatrixValue;
