import { Injectable } from '@angular/core';

import { HolderType } from '../../models/holder-type';

import { IMapperFromDto } from './mappers';
import { HolderTypeDto } from './dto/holder-type-dto';

/** Mapper for holder type. */
@Injectable({ providedIn: 'root' })
export class HolderTypeMapper implements IMapperFromDto<HolderTypeDto, HolderType> {
	/** @inheritdoc */
	public fromDto(data: HolderTypeDto): HolderType {
		return new HolderType({
			id: data.id,
			name: data.name,
		});
	}
}
