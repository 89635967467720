<div class="control-container">
	<mat-chip-grid
		multiple
		#chipList
	>
		@for (item of selectedTypes | keyvalue; track $index) {
			<mat-chip-row
				(removed)="remove(item.value)"
				class="selected-option"
			>
				{{ item.value.name }}
				<mat-icon
					matChipRemove
					class="cancel-icon"
				>
					cancel
				</mat-icon>
			</mat-chip-row>
		}
		<input
			matInput
			class="autocomplete-input"
			#trigger="matAutocompleteTrigger"
			[formControl]="codeControl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[placeholder]="placeholder"
			(click)="trigger.openPanel()"
		/>
		<mat-icon
			matSuffix
			class="dropdown-arrow"
		>
			arrow_drop_down
		</mat-icon>
	</mat-chip-grid>
	<mat-autocomplete
		#auto="matAutocomplete"
		(optionSelected)="selected($event)"
	>
		<div
			infiniteScroll
			class="infinite-scroll"
			[scrollWindow]="false"
			(scrolled)="listManager.nextPage()"
		>
			@let codes = types$ | async;
			@let isLoading = listManager.listLoading$;

			@if (isLoading) {
				<mat-progress-bar mode="indeterminate" />
			}

			@if (!codes?.length && !isLoading) {
				<mat-option>Not found</mat-option>
			}

			@for (code of codes; track code.id) {
				<mat-option [value]="code">
					{{ code.name }}
				</mat-option>
			}
		</div>
	</mat-autocomplete>
</div>
