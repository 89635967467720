import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Naupa2Code, Naupa2CodeEdit } from '../../models/naupa-2-code';

import { Naupa2CodeDto, Naupa2CodeEditDto } from './dto/naupa-2-code-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { PropertyCodeCategoryMapper } from './property-code-category.mapper';
import { RuleMapper } from './rule.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Naupa code mapper. */
@Injectable({ providedIn: 'root' })
export class Naupa2CodeMapper implements IMapperFromDto<Naupa2CodeDto, Naupa2Code>,
IMapperToDto<Naupa2CodeEditDto, Naupa2Code>,
IValidationErrorMapper<Naupa2CodeEditDto, Naupa2Code> {
	private readonly ruleMapper = inject(RuleMapper);

	private readonly categoryMapper = inject(PropertyCodeCategoryMapper);

	/** @inheritdoc */
	public fromDto(dto: Naupa2CodeDto): Naupa2Code {
		return new Naupa2Code({
			id: dto.id,
			code: dto.code,
			rules: dto.rules_data ? dto.rules_data.map(t => this.ruleMapper.fromDto(t)) : [],
			categoryId: dto.category,
			category: dto.category_data ? this.categoryMapper.fromDto(dto.category_data) : undefined,
			isRestricted: dto.is_restricted,
		});
	}

	/** @inheritdoc */
	public toDto(data: Naupa2Code): Naupa2CodeEditDto {
		return {
			id: data.id,
			code: data.code,
			category: data.categoryId,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<Naupa2CodeEditDto>,
	): EntityValidationErrors<Naupa2CodeEdit> {
		return {
			id: extractErrorMessageByErrorKey(errorDto, 'id'),
			code: extractErrorMessageByErrorKey(errorDto, 'code'),
			categoryId: extractErrorMessageByErrorKey(errorDto, 'category'),
		};
	}
}
