import { Injectable } from '@angular/core';

import { DormancyFilters } from '../../models/dormancy-filters';
import { stringifyListByProp } from '../../utils/stringifyListByProp';
import { ALL_OPTION_ID } from '../../utils/constants';

import { DormancyFiltersDto } from './dto/dormancy-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for dormancy filters. */
@Injectable({ providedIn: 'root' })
export class DormancyFiltersMapper implements IMapperToDto<DormancyFiltersDto, DormancyFilters> {

	/** @inheritdoc */
	public toDto(data: DormancyFilters): DormancyFiltersDto {
		const params: DormancyFiltersDto = {};

		if (data.jurisdictions?.length) {
			const filteredList = data.jurisdictions.filter(jurisdiction => jurisdiction.id !== ALL_OPTION_ID);
			params.law__jurisdiction__in = stringifyListByProp(filteredList, 'id');
		}

		if (data.naupaCodes?.length) {
			params.naupa2_codes = stringifyListByProp(data.naupaCodes, 'id');
		}

		return params;
	}
}
