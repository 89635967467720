import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { map, shareReplay, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { IraDistribution } from '@scriptac/common/core/models/ira-distribution';
import { DestroyableComponent } from '@scriptac/common/core/utils/public_api';
import { IraDistributionRule } from '@scriptac/common/core/models/ira-distribution-rule';
import { DATE_DISPLAY_FORMAT } from '@scriptac/common/core/utils/datetime-util';
import { Pure } from '@scriptac/common/core/utils/pure.decorator';
import { IraDistributionRulesService } from '@scriptac/common/core/services/ira-distribution-rules.service';
import { toggleExecutionState } from '@scriptac/common/core/utils/toggle-execution-state';
import { routePaths } from 'projects/web/src/app/route-paths';

/**
 * Type for transposed row (same field types from different columns).
 */
export type TransposeRowType = Record<string, string | number | null>;

/** Column data. */
export type ColumnInfo<T> = {

	/** Column name for material. */
	readonly name: string;

	/** Column header text. */
	readonly headerText: string;

	/** Column data. */
	readonly data: T | null;
};

/** Row title data. */
export type RowTitle = {

	/** Name of title to table structure. */
	readonly name: string;

	/** Title text to display. */
	readonly titleText: string;
};

/** List of IRA Distribution Rule properties. */
type IraDistributionRuleProperty = keyof IraDistributionRule;

/** IRA Distribution matrix. */
@Component({
	selector: 'scriptaw-ira-distribution-matrix',
	templateUrl: './ira-distribution-matrix.component.html',
	styleUrls: ['./ira-distribution-matrix.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class IraDistributionMatrixComponent {
	private readonly iraDistributionService = inject(IraDistributionRulesService);

	private readonly router = inject(Router);

	/** Show Admin actions. */
	@Input()
	public showAdminActions = false;

	private readonly rowsNamesMapper = {
		id: 'Id',
		survivingSpouse: 'Surviving Spouse',
		eligibleDesignatedBene: 'Eligible Designated Bene',
		otherRelationship: 'Other Relationship',
		nonIndividual: 'Non-individual (not trust)',
		nonIndividualTrust: 'Non-individual Trust',
	};

	/** Date format to display on page. */
	public readonly dateDisplayFormat = DATE_DISPLAY_FORMAT;

	/** Route paths. */
	public readonly routePaths = routePaths;

	/** Loading flag. */
	public readonly loading$ = new BehaviorSubject(false);

	private matrixDataValue: IraDistribution[] = [];

	/** Column name with titles. */
	public readonly titleColumnName = 'title';

	/** Internal representation of data source. */
	public readonly dataSource = new MatTableDataSource<TransposeRowType>([]);

	/** IRA Distribution rules. */
	public readonly iraDistributionRules$ = this.iraDistributionService.getIraDistributions().pipe(
		toggleExecutionState(this.loading$),
		map(list => list.sort((a, b) => a.id - b.id)),
		tap(res => {
			this.matrixDataValue = res ?? [];
			this.dataSource.data = this.transposeMatrixData(res);
		}),
		shareReplay({ bufferSize: 1, refCount: true }),
	);

	/** Header columns. */
	public readonly headerColumns$ = this.iraDistributionRules$.pipe(
		map(list => list.map(item => `ira-col-${item.id}`)),
		map(list => ['header-title', ...list]),
	);

	/** Return columns names. */
	public get columnNames(): string[] {
		return this.columns.map(c => c.name);
	}

	/** Return rows names. */
	public get rowsNames(): RowTitle[] {
		if (this.matrixDataValue.length) {
			const titles = Object.keys(this.matrixDataValue[0].rmdRuleBefore)
				.filter(rowName => rowName !== 'id') as IraDistributionRuleProperty[];
			return titles.map(title => ({
				name: title,
				titleText: this.rowsNamesMapper[title],
			}));
		}
		return [];
	}

	/** Columns information. */
	public get columns(): ColumnInfo<IraDistribution>[] {
		return this.createColumns(this.matrixDataValue);
	}

	/**
	 * Edit IRA rule.
	 * @param ira IRA rule.
	 */
	public editIraRule(ira: IraDistribution): void {
		this.router.navigate(routePaths.adminIraDistributionEdit(ira.id));
	}

	/**
	 * Get table header text.
	 * @param ira IRA rule.
	 */
	public getTableHeaderText(ira: IraDistribution): string {
		if (ira.effectiveTill && !ira.effectiveSince) {
			const tillDate = new Date(ira.effectiveTill);
			const nextDay = tillDate.getDate() + 1;
			tillDate.setDate(nextDay);
			return `Death before ${tillDate.toLocaleDateString('en-US')}`;
		} else if (!ira.effectiveTill && ira.effectiveSince) {
			return `Death on or after ${ira.effectiveSince.toLocaleDateString('en-US')}`;
		}
		return '';
	}

	private transposeMatrixData(matrixData: IraDistribution[]): TransposeRowType[] {
		return this.rowsNames.map(row => {
			const transposedRow: TransposeRowType = {};

			transposedRow[this.titleColumnName] = row.titleText;
			for (const columnElement of matrixData) {
				const rowName = row.name as IraDistributionRuleProperty;
				transposedRow[columnElement.rmdRuleBefore.id] = columnElement.rmdRuleBefore[rowName];
				transposedRow[columnElement.rmdRuleAfter.id] = columnElement.rmdRuleAfter[rowName];
			}

			return transposedRow;
		});
	}

	@Pure
	private createColumns(matrixDataValue: IraDistribution[]): ColumnInfo<IraDistribution>[] {
		const columnsFromData: ColumnInfo<IraDistribution>[] = [];
		matrixDataValue.forEach(elem => {
			columnsFromData.push({
				name: elem.rmdRuleBefore.id.toString(),
				headerText: 'Death before RMD',
				data: elem,
			});
			columnsFromData.push({
				name: elem.rmdRuleAfter.id.toString(),
				headerText: 'Death after RMD',
				data: elem,
			});
		});

		const columnWithTitles: ColumnInfo<IraDistribution> = {
			name: 'title',
			headerText: '',
			data: null,
		};

		const columns = [columnWithTitles, ...columnsFromData];

		return columns;
	}
}
