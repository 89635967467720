import { Component, ChangeDetectionStrategy, forwardRef, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import {
	LocationService,
} from '@scriptac/common/core/services/location.service';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { State } from '@scriptac/common/core/models/state';
import { ALL_OPTION_ID } from '@scriptac/common/core/utils/constants';

/** State autocomplete component. */
@Component({
	selector: 'scriptaw-states-autocomplete',
	templateUrl: './states-autocomplete.component.html',
	styleUrls: [
		'./states-autocomplete.component.scss',
		'../naupa-code-autocomplete/naupa-code-autocomplete.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StatesAutocompleteComponent),
			multi: true,
		},
	],
})
@DestroyableComponent()
export class StatesAutocompleteComponent extends SimpleValueAccessor<State[]> {
	private readonly fb = inject(NonNullableFormBuilder);

	private readonly locationService = inject(LocationService);

	/** Whether need All Option. */
	@Input()
	public needAllOption = false;

	/** Placeholder. */
	@Input()
	public placeholder = 'Select';

	/** List of selected states. */
	public readonly selectedStates = new Map<number, State>();

	/** Control for input. */
	public readonly stateControl = this.fb.control('');

	private readonly reload$ = new Subject();

	private readonly states$ = combineLatest([
		this.locationService.states$.pipe(
			map(list => {
				if (this.needAllOption) {
					return [new State({ id: ALL_OPTION_ID, name: 'All', geonameCode: 'All' })].concat(list);
				}
				return list;
			}),
		),
		listenControlChanges<string>(this.stateControl),
	]).pipe(
		map(([states, search]) => this.filterBySearch(states, search)),
	);

	/** Filtered states list to display without selected states. */
	public filteredStates$ = combineLatest([
		this.states$,
		this.reload$.pipe(startWith(null)),
	]).pipe(
		map(([states]) => states.filter(state => !this.selectedStates.has(state.id))),
	);

	/**
	 * Remove state from selected by id.
	 *
	 * @param state State.
	 */
	public remove(state: State): void {
		this.selectedStates.delete(state.id);
		if (this.selectedStates.size === 0) {
			this.addAllStatesOption();
		}
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedStates.values()];
	}

	/**
	 * Add selected value to selected states.
	 *
	 * @param event Autocomplete select event.
	 */
	public selected(event: MatAutocompleteSelectedEvent): void {
		const state: State = event.option.value;

		if (!state?.id) {
			return;
		}

		// If new option is All then clear others
		if (state.id === ALL_OPTION_ID) {
			this.selectedStates.clear();
			this.addAllStatesOption();
		} else if (this.selectedStates.get(ALL_OPTION_ID)) {
			// If selected option not All, but we already select All then we should remove All option
			this.selectedStates.delete(ALL_OPTION_ID);
		}

		this.selectedStates.set(state.id, state);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedStates.values()];

		this.stateControl.setValue('');
	}

	/** Get sorted list of selected states. */
	public getSortedSelectedStates(): State[] {
		return [...this.selectedStates.values()].sort(
			(a, b) => a.geonameCode.localeCompare(b.geonameCode),
		);
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.controlValue.forEach(val => this.selectedStates.set(val.id, val));
		}

		if ((this.controlValue === null || this.controlValue.length === 0) && this.needAllOption) {
			this.addAllStatesOption();
			setTimeout(() => {
				this.controlValue = [...this.selectedStates.values()];
			}, 0);
		}
	}

	/**
	 * Whether the remove button should be shown.
	 * @param state State.
	 */
	protected shouldShowRemoveButton(state: State): boolean {
		return state.id !== ALL_OPTION_ID;
	}

	private addAllStatesOption(): void {
		if (this.needAllOption) {
			this.selectedStates.set(ALL_OPTION_ID, new State({ id: ALL_OPTION_ID, name: 'All', geonameCode: 'All' }));
		}
	}

	private filterBySearch(states: State[], search: string): State[] {
		return states.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
	}
}
