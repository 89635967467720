import { MatrixFieldType } from '../enums/matrix-field-type';

import { MatrixField } from './matrix-field';

/** Matrix search result. */
export class MatrixSearchResult {
	/** Result matrix id. */
	public readonly id: number;

	/** Result matrix name. */
	public readonly name: string;

	/** Result matrix search type. */
	public readonly searchType: MatrixFieldType;

	/** Matrix fields. */
	public readonly fields: MatrixField[];

	public constructor(data: MatrixSearchResultConstructorData) {
		this.id = data.id;
		this.name = data.name;
		this.searchType = data.searchType;
		this.fields = data.fields;
	}
}

type MatrixSearchResultConstructorData = MatrixSearchResult;
