<form
	[formGroup]="form"
	(ngSubmit)="onConfirm(form)"
>
	<h2>Create New Law</h2>

	<scriptac-label
		labelText="Select State"
		class="full-width"
	>
		<scriptac-autocomplete
			[options]="states$ | async"
			formControlName="state"
			[readable]="stateReadable"
			[trackBy]="trackById"
			[emptyValue]="emptyState"
			[loading]="statesListManager.listLoading$ | async"
			(filterChanged)="stateFilter$.next($event)"
		/>
	</scriptac-label>

	<div class="button-wrapper">
		<button
			mat-flat-button
			color="warn"
			type="button"
			(click)="onClose()"
		>
			Cancel
		</button>
		<button
			color="accent"
			type="submit"
			mat-flat-button
		>
			Save
		</button>
	</div>
</form>
