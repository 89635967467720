@let isAdmin = isAdmin$ | async;

<mat-divider />
<header class="header">
	<div>
		<h3>Reporting Data</h3>
		@if (statesNames$ | async; as states) {
			<h4>{{ states }}</h4>
		}
	</div>
	<div class="buttons">
		@if (showExportButton$ | async) {
			<scriptaw-export-menu-buttons (selectExportFileFormat)="exportResults($event)" />
		}
		@if (isAdmin) {
			@if (matrix$ | async; as matrix) {
				<a
					mat-flat-button
					class="custom-icon-button"
					type="button"
					title="Configure Matrix"
					[routerLink]="routePaths.matrixConfigure(matrix.id)"
				>
					<span class="button-content">
						<mat-icon svgIcon="edit" />
						<span>Configure Matrix</span>
					</span>
				</a>
			}
			<button
				mat-flat-button
				class="custom-icon-button"
				type="button"
				title="Add Law"
				(click)="addLaw()"
			>
				<span class="button-content">
					<mat-icon svgIcon="add" />
					<span>Add Law</span>
				</span>
			</button>
		}
	</div>
</header>
<mat-divider />

@let matrixLaws = matrixLaws$ | async;

@if (isAdmin) {
	<form
		class="filters-form"
		[formGroup]="filtersForm"
	>
		<scriptac-label
			labelText="Jurisdiction"
			class="jurisdiction-select"
		>
			<scriptaw-states-autocomplete
				formControlName="states"
				[needAllOption]="true"
			/>
		</scriptac-label>
	</form>
}

@let matrix = matrix$ | async;

<scriptaw-horizontal-matrix
	[matrixData]="matrixLaws"
	[matrixId]="matrix?.id || null"
	[showStateColumn]="true"
	[dateFormat]="dateFormat"
	[getElementValuesList]="getValuesListFromLaw"
	[columns]="columns$ | async"
	[loading]="tableManager.listLoading$ | async"
	[showFixedColumn]="isAdmin || shouldDisplayViewHistoryColumn(matrixLaws)"
	(columnsUpdate)="saveUpdatedColumns($event)"
>
	<ng-template
		#stateCellTemplate
		let-element
	>
		{{ element.state.name }}
	</ng-template>

	<ng-template
		#fixedCellTemplate
		let-element
	>
		@if (isAdmin) {
			<div class="admin-buttons">
				<button
					mat-flat-button
					class="custom-icon-button"
					type="button"
					title="Add Revision"
					[disabled]="tableManager.listLoading$ | async"
					(click)="openRevisionDialog(element)"
				>
					<span class="button-content">
						<mat-icon svgIcon="add" />
						Add Revision
					</span>
				</button>
				<a
					mat-flat-button
					title="View History"
					class="custom-icon-button"
					[routerLink]="routePaths.matrixRevision(element.matrixId, element.id)"
				>
					<span class="button-content">
						<mat-icon svgIcon="history" />
						<span>View History</span>
					</span>
				</a>
				<button
					mat-flat-button
					title="Delete"
					class="custom-icon-button"
					type="button"
					(click)="deleteLaw(element)"
				>
					<span class="button-content delete">
						<mat-icon svgIcon="trash" />
						Delete
					</span>
				</button>
			</div>
		} @else {
			@if (shouldDisplayViewHistory(element)) {
				<a
					mat-flat-button
					title="View History"
					class="custom-icon-button"
					[routerLink]="routePaths.resultDetails(element.id)"
				>
					<span class="button-content">
						<mat-icon svgIcon="history" />
						<span>View History</span>
					</span>
				</a>
			}
		}
	</ng-template>
</scriptaw-horizontal-matrix>

@if (tableManager.pagePagination$ | async; as pagination) {
	<mat-paginator
		color="primary"
		[pageSize]="pagination.pageSize"
		[pageIndex]="pagination.page"
		[length]="pagination.totalCount"
		[hidePageSize]="true"
		(page)="paginationChanged($event)"
	/>
}

@if (matrixLaws === null) {
	<scriptac-page-loader />
}
