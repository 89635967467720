import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';
import { MatrixField } from '@scriptac/common/core/models/matrix-field';
import { MatrixFieldChoice } from '@scriptac/common/core/models/matrix-field-choice';
import { MatrixValueEditFormGroup } from '@scriptac/common/core/services/forms/matrix-revision-form-utils.service';
import { EDITOR_CONFIG } from '@scriptac/common/core/utils/constants';

/**
 * Matrix revision values edit form.
 */
@Component({
	selector: 'scriptaw-matrix-revision-values-form',
	templateUrl: './matrix-revision-values-form.component.html',
	styleUrls: ['./matrix-revision-values-form.component.scss'],

	// We need default change detection to display server validation errors in nested form.
	changeDetection: ChangeDetectionStrategy.Default,
})
export class MatrixRevisionValuesFormComponent {

	/** Custom toolbar modules. */
	public readonly editorOptions = EDITOR_CONFIG;

	/** Form controls. */
	@Input()
	public formControls: MatrixValueEditFormGroup[] = [];

	/** State ids. */
	@Input()
	public stateIds: number[] = [];

	/** Open field edit event. */
	@Output()
	public readonly openFieldEdit = new EventEmitter<MatrixField>();

	/** Matrix field type. */
	public readonly matrixFieldType = MatrixFieldType;

	/**
	 * Compare choice objects by id to select controls.
	 * @param option1 Option1 to compare.
	 * @param option2 Option2 to compare.
	 */
	public choiceComparisonFunction(option1: MatrixFieldChoice, option2: MatrixFieldChoice): boolean {
		return option1?.id === option2?.id;
	}

	/**
	 * Open edit field.
	 * @param field Field.
	 */
	public openEditField(field: MatrixField): void {
		if (field.fieldType === MatrixFieldType.SingleChoice || field.fieldType === MatrixFieldType.MultiChoice) {
			this.openFieldEdit.emit(field);
		}
	}
}
