import { PaginationOptions } from './pagination-options';

/**
 * Pagination list.
 */
export class PagedList<T> {
	/** Pagination. */
	public pagination: PaginationOptions;

	/** Items. */
	public items: T[];

	public constructor(data: PagedListConstructorData<T>) {
		this.pagination = data.pagination;
		this.items = data.items;
	}
}

type PagedListConstructorData<T> = PagedList<T>;
