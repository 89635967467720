import { Injectable } from '@angular/core';

import { RevisionsFilters } from '../api/revision-api.service';

import { RevisionsFiltersDto } from './dto/revisions-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for user filters. */
@Injectable({ providedIn: 'root' })
export class RevisionsFiltersMapper implements IMapperToDto<RevisionsFiltersDto, RevisionsFilters> {

	/** @inheritdoc */
	public toDto(data: RevisionsFilters): RevisionsFiltersDto {
		const params: RevisionsFiltersDto = {};

		if (data.search) {
			params.search = data.search;
		}

		if (data.laws) {
			params.law__in = data.laws;
		}

		return params;
	}
}
