import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { PropertyType } from '../../models/property-type';
import { PropertyTypeForm } from '../forms/property-type-form-utils.service';

import { PropertyTypeDto, PropertyTypeEditDto } from './dto/property-type-dto';
import { ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { RuleMapper } from './rule.mapper';
import { extractErrorMessageByErrorKey } from './extract-error-message';

/** Property type mapper. */
@Injectable({ providedIn: 'root' })
export class PropertyTypeMapper implements IMapperFromDto<PropertyTypeDto, PropertyType>,
IMapperToDto<PropertyTypeEditDto, PropertyType>,
IValidationErrorMapper<PropertyTypeEditDto, PropertyTypeForm> {
	private readonly ruleMapper = inject(RuleMapper);

	/** @inheritdoc */
	public fromDto(dto: PropertyTypeDto): PropertyType {
		return new PropertyType({
			id: dto.id,
			name: dto.name,
			rules: dto.rules_data ? dto.rules_data.map(rule => this.ruleMapper.fromDto(rule)) : [],
		});
	}

	/** @inheritdoc */
	public toDto(data: PropertyType): PropertyTypeEditDto {
		return {
			id: data.id,
			name: data.name,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<PropertyTypeEditDto>): EntityValidationErrors<PropertyTypeForm> {
		return {
			name: extractErrorMessageByErrorKey(errorDto, 'name'),
		};
	}
}
