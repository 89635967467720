import { Component, ChangeDetectionStrategy, forwardRef, Input, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, NonNullableFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import { Naupa2Code } from '@scriptac/common/core/models/naupa-2-code';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { PropertyCodeService } from '@scriptac/common/core/services/property-code.service';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { SimpleValueAccessor } from '@scriptac/common/core/utils/value-accessor';
import { SortDirection } from '@scriptac/common/core/enums/sort-direction';
import { Naupa2CodesFilters } from '@scriptac/common/core/models/naupa2-code-filters';

/** Multi select for naupa codes. */
@Component({
	selector: 'scriptaw-naupa-code-autocomplete',
	templateUrl: './naupa-code-autocomplete.component.html',
	styleUrls: ['./naupa-code-autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NaupaCodeAutocompleteComponent),
			multi: true,
		},
	],
})
@DestroyableComponent()
export class NaupaCodeAutocompleteComponent extends SimpleValueAccessor<Naupa2Code[]> {
	private readonly fb = inject(NonNullableFormBuilder);

	private readonly codesService = inject(PropertyCodeService);

	/** Placeholder. */
	@Input()
	public placeholder = 'Select';

	/** Hide restricted. */
	@Input()
	public hideRestricted = true;

	/** Jurisdiction ids. */
	@Input()
	public set jurisdictionIds(list: number[]) {
		if (JSON.stringify(list) !== JSON.stringify(this.jurisdictionIds$.value)) {
			this.jurisdictionIds$.next(list);
		}
	}

	/** Category ids. */
	@Input()
	public set categoryIds(list: number[]) {
		if (JSON.stringify(list) === JSON.stringify(this.categoryIds$.value)) {
			return;
		}

		// To display only codes according to selected categories
		const selectedCodes = [...this.selectedCodes.values()];
		this.selectedCodes.clear();
		for (const categoryId of list) {
			for (const selectedValue of selectedCodes) {
				if (categoryId === selectedValue.categoryId) {
					this.selectedCodes.set(selectedValue.id, selectedValue);
				}
			}
		}
		this.controlValue = [...this.selectedCodes.values()];
		this.emitChange([...this.selectedCodes.values()]);

		this.categoryIds$.next(list);
	}

	/** List of selected codes. */
	public readonly selectedCodes = new Map<number, Naupa2Code>();

	/** Control for input. */
	public readonly codeControl = this.fb.control('');

	/** Jurisdiction ids. */
	private readonly jurisdictionIds$ = new BehaviorSubject<number[]>([]);

	/** Category ids. */
	private readonly categoryIds$ = new BehaviorSubject<number[]>([]);

	private readonly filter$: Observable<Naupa2CodesFilters> = combineLatest([
		listenControlChanges<string>(this.codeControl),
		this.jurisdictionIds$,
		this.categoryIds$,
	]).pipe(
		map(([search, jurisdictionIds, categoryIds]) => ({
			jurisdictionIds,
			categoryIds,
			search,
			hideRestricted: this.hideRestricted,
		})),
	);

	/** List manager. */
	public readonly listManager = new ListManager<Naupa2Code, Naupa2CodesFilters>({
		strategy: new InfiniteScrollListStrategy(),
		filter$: this.filter$,
		pageSize: 30,
	});

	/** Codes list. */
	private readonly codesList$ = this.listManager
		.getPaginatedItems(option => this.codesService.getNaupa2CodesPagedList(option))
		.pipe(
			shareReplay({
				bufferSize: 1,
				refCount: true,
			}),
		);

	private readonly reload$ = new Subject();

	/** Filtered codes list. */
	public readonly codes$ = combineLatest([
		this.codesList$,
		this.reload$.pipe(startWith(null)),
	]).pipe(
		map(([codes]) => codes.filter(code => !this.selectedCodes.has(code.id))),
	);

	public constructor() {
		super();

		this.listManager.setSort({
			direction: SortDirection.ASC,
			field: 'code',
		});
	}

	/**
	 * Remove code from selected by id.
	 *
	 * @param code Naupa code.
	 */
	public remove(code: Naupa2Code): void {
		this.selectedCodes.delete(code.id);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedCodes.values()];
	}

	/**
	 * Add selected value to selected codes.
	 *
	 * @param event Autocomplete select event.
	 */
	public selected(event: MatAutocompleteSelectedEvent): void {
		const naupaCode: Naupa2Code = event.option.value;

		if (!naupaCode?.id) {
			return;
		}

		this.selectedCodes.set(naupaCode.id, naupaCode);
		this.reload$.next(undefined);
		this.controlValue = [...this.selectedCodes.values()];

		this.codeControl.setValue('');
	}

	/** @inheritDoc */
	public afterWriteValue(): void {
		if (this.controlValue !== null) {
			this.selectedCodes.clear();
			this.controlValue.forEach(val => this.selectedCodes.set(val.id, val));
		}

		if (this.controlValue === null || this.controlValue.length === 0) {
			this.selectedCodes.clear();
		}
	}

}
