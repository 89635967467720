<nav class="links">
	@for (link of tabs; track $index) {
		<a
			class="link-item"
			routerLinkActive="active"
			[routerLink]="link.path"
		>
			{{ link.label }}
		</a>
	}
</nav>
