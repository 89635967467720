import { SortDirection } from '../enums/sort-direction';

/**
 * Sort setting for table.
 */
export class Sort {
	/** Fiend name. */
	public field: string;

	/** Direction. */
	public direction: SortDirection;

	public constructor(data: SortConstructorData) {
		this.field = data.field;
		this.direction = data.direction;
	}
}

/** Data for select option constructor. */
export type SortConstructorData = Sort;
