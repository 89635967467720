import { PriorityRuleType } from '../enums/priority-rule-type';

/** Priority rule for edit form. */
export type PriorityRuleEdit = Pick<PriorityRule, 'rule' | 'firstPriority' | 'secondPriority' | 'source' | 'applicability'>;

/** Priority rule. */
export class PriorityRule {
	/** Rule id. */
	public readonly id: number;

	/** Rule type. */
	public readonly rule: PriorityRuleType;

	/** First priority in rule. */
	public readonly firstPriority: string;

	/** Second priority in rule. */
	public readonly secondPriority: string;

	/** Rule source. */
	public readonly source: string;

	/** Applicability to other laws, properties etc. */
	public readonly applicability: string;

	public constructor(data: PriorityRuleConstructorData) {
		this.id = data.id;
		this.rule = data.rule;
		this.firstPriority = data.firstPriority;
		this.secondPriority = data.secondPriority;
		this.source = data.source;
		this.applicability = data.applicability;
	}
}

type PriorityRuleConstructorData = PriorityRule;
