<div class="control-container">
	<button
		mat-flat-button
		class="custom-icon-button"
		type="button"
		title="Add Filter"
		(click)="filter.open()"
	>
		<span class="button-content">
			<mat-icon svgIcon="add" />
			<span>Add Filter</span>
		</span>
	</button>
	<mat-form-field class="hidden">
		<mat-select
			multiple
			#filter
			panelClass="custom-select-dropdown"
			[disableOptionCentering]="true"
			[compareWith]="columnComparisonFunction"
			[formControl]="selectFormControl"
		>
			<mat-option>
				<ngx-mat-select-search
					placeholderLabel="Search"
					noEntriesFoundLabel="No data found"
					[formControl]="searchControl"
				/>
			</mat-option>
			@for (option of filteredOptions$ | async; track option.name) {
				<mat-option [value]="option">
					{{ option.headerText }}
				</mat-option>
			}
		</mat-select>
	</mat-form-field>
</div>
