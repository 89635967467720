<form [formGroup]="filtersForm">
	<scriptac-label
		class="full-width"
		labelText="Search"
	>
		<input
			formControlName="search"
			placeholder="Search"
		/>
	</scriptac-label>
</form>

<div class="user-list">
	@if (listManager.listLoading$ | async) {
		<mat-progress-bar mode="indeterminate" />
	}

	<mat-selection-list
		(scroll)="onScroll($event)"
		[formControl]="listControl"
		[multiple]="data.multiple"
	>
		@if (users$ | async; as users) {
			@if (users?.length) {
				@for (user of users; track user.id) {
					<mat-list-option
						role="listitem"
						[value]="user"
					>
						{{ user.firstName }} {{ user.lastName }}
					</mat-list-option>
				}
			} @else {
				<mat-list-item>No users found</mat-list-item>
			}
		} @else {
			<mat-list-item>Loading...</mat-list-item>
		}
	</mat-selection-list>
</div>

@if (data.multiple) {
	<p>Users selected: {{ listControl.value.length }}</p>
} @else {
	<p>Option selected: {{ selectedUserFullName }}</p>
}

<div class="controls full-width">
	<button
		(click)="onClose()"
		type="button"
		mat-flat-button
		color="warn"
	>
		Cancel
	</button>
	<button
		(click)="onConfirm()"
		type="button"
		mat-flat-button
		color="accent"
	>
		Confirm
	</button>
</div>
