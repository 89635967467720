import { Injectable } from '@angular/core';

import { SearchMatrixLawsFilters } from '../../models/search-matrix-laws-filters';
import { stringifyListByProp } from '../../utils/stringifyListByProp';
import { ALL_OPTION_ID } from '../../utils/constants';

import { IMapperToDto } from './mappers';
import { SearchMatrixLawsFiltersDto } from './dto/search-matrix-laws-filters-dto';

/** Mapper for laws list filters. */
@Injectable({ providedIn: 'root' })
export class SearchMatrixLawsFiltersMapper implements IMapperToDto<SearchMatrixLawsFiltersDto, SearchMatrixLawsFilters> {

	/** @inheritdoc */
	public toDto(data: SearchMatrixLawsFilters): SearchMatrixLawsFiltersDto {
		const params: SearchMatrixLawsFiltersDto = {
			ordering: 'jurisdiction__name',
		};

		if (data.states?.length) {
			const states = data.states.filter(state => state.id !== ALL_OPTION_ID);
			params.jurisdiction__in = stringifyListByProp(states, 'id');
		}

		if (data.naupa2Codes?.length) {
			params.naupa2_codes = stringifyListByProp(data.naupa2Codes, 'id');
		}

		if (data.matrixId != null) {
			params.matrix = data.matrixId;
		}

		return params;
	}
}
