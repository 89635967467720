/** Matrix search name. */
export class MatrixSearchName {
	/** Matrix name id. */
	public readonly id: number;

	/** Matrix name. */
	public readonly name: string;

	public constructor(data: MatrixSearchNameConstructorData) {
		this.id = data.id;
		this.name = data.name;
	}
}

type MatrixSearchNameConstructorData = MatrixSearchName;
