import { createTrackByPropertyFunction } from './track-by-property';

type ObjectWithId = {

	/** Identifier. */
	readonly id: string | number;
};

/** Track by ID function. */
export const trackById = createTrackByPropertyFunction<ObjectWithId>('id');
